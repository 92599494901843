.settings_modal .modal-header {
    font-size: 24px;
    font-family: 'openSansBold';
    color: var(--app_default);
    padding: 32px 19px;
}

.settings_modal .modal-body {
    padding: 10px 15px;
}


.settings_modal {
    background-color: #323232;
    opacity: 0.9;
}

.settings_tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-size: 16px;
    font-family: 'openSansBold';
    color: var(--app_default);
    border: none;
}


.settings_tabs {
    border: none;
    margin-bottom: 35px;
}

.settings_tabs .nav-link {
    padding: 5px 13px;

}

.settings_tabs .nav-link:hover {
    border: none;
}

.settings_upload {
    margin-bottom: 20px;
}

.upload__image-wrapper p {
    color: var(--app_default);
    font-size: 13px;
    font-family: 'openSansBold';
    margin-left: 4px;
}

.upload__image-wrapper button {
    border: none;
    background: none;
    color: var(--app_default);
    font-family: 'openSansSemiBold';
    font-size: 14px;
}

.change_profile_info {
    padding-right: 0;
}

.user_add_box p {
    margin-top: 14px !important;
}

.error_box{
    color: #ea5154;
    font-size: 14px;
    text-transform: capitalize;
}

.change_profile_info p {
    font-size: 13px;
    color: var(--app_default);
    font-family: 'openSansBold';
    margin-top: 23px;
}



.change_profile_inputs input {
    outline: 0;
    border: 0;
    border-bottom: 1px solid #99B7CF;
    width: 100%;
    color: #00559A;
    font-size: 13px;
    padding: 2px;
    margin-top: 18px;
    background: none;
}

.settings_modal .modal-header i {
    position: absolute;
    right: 25px;
    cursor: pointer;
    font-size: 30px;
}

.settings_modal .modal-header .close {
    display: none;
}


.change_pass_title h3 {
    font-size: 13px;
    color: var(--app_default);
    font-family: 'openSansBold';
}


.change_pass_content p {
    font-size: 13px;
    color: var(--app_default);
    font-family: 'openSansBold';
    margin-top: 25px;
}

.pass_validate {
    position: absolute;
}

.pass_validate span {
    display: block;
    font-size: 10px;
    color: #99B7CF;
    font-family: 'openSansRegular';
}


/*----------------*/


.receive_notifications input[type=checkbox] + label {
    display: block;
    cursor: pointer;
    padding: 0.2em;
}

.receive_notifications input[type=checkbox] {
    display: none;
}

.receive_notifications input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #1B75BE;
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
}

.receive_notifications input[type=checkbox] + label:active:before {
    transform: scale(0);
}

.receive_notifications input[type=checkbox]:checked + label:before {
    background-color: #1B75BE;
    border-color: #1B75BE;

}

.receive_notifications input[type=checkbox]:disabled + label:before {
    transform: scale(1);

}

.receive_notifications input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}


.notify_info p {
    margin-top: 10px;
    font-size: 13px;
    font-family: 'openSansBold';
    color: var(--app_default);
    margin-bottom: 18px;
}

.help_select select {
    border: 0;
    border-bottom: 1px solid #99B7CF;
    border-radius: 0;
    color: var(--app_default);
}

.help_select select:focus{
    color: var(--app_default);
}

.help_select .selectdiv:after {
    content: "\e82f";
    font-family: "apa";
    color: #00559A;
    font-size: 7px;
    right: 22px;
    top: 20px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}


.create_user .selectdiv:after {
    bottom: 30px;
    top: initial;
}

.user_update_modal{
    background-color: #323232;
    opacity: 0.9;

}
.setting_modal_button{
    padding: 7px 16px;
    border: 1px solid;
    float: right;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    background-color: #FFC10E;
    font-family: OpenSansSemiBold;
    margin-top: 15px;
}


.profile_select{
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 0;

}

.profile_select select{
    border: 0;
    border-bottom: 1px solid #99B7CF ;
    border-radius: 0;
}

.profile_select select,.create_user select{
    padding: 6px 0;
    color:var(--app_default);
    font-size: 13px;
}


.profile_select select:focus,.create_user select:focus{
    color:var(--app_default);
}


.profile_content .selectdiv:after{
    right: 8px;
    top: 22px;
    transform: translateY(-50%);
}

.change_profile_inputs .error_input{
    border-bottom: 1px solid #FF4040;
}


.create_user_save{
    padding: 7px 42px;
}


.profile_content .selectdiv:after {
    content: "\e82f";
    font-family: "apa";
    color: #00559a;
    font-size:7px;
    right: 8px;
    top: 20px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}











