.search_tabs .nav-link {
    border: 1px solid #FBFBFB;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    font-family: OpenSansSemiBold;
    background-color: #FBFBFB;
    border-radius: 40px;
    padding: 6px;
    color: var(--app_default);
    opacity: 0.5;
    margin: 8px;
    max-height: 90px;
}


.search_tabs .nav-link.active{
    background-color: #ffffff;
    font-weight: bold;
    color: var(--app_default);
    opacity: 1;
    border: 1px solid #FBFBFB;

}


.search_tabs .nav-link:focus{
    border: none;
    outline: none;
}

.lead_id_search{
    padding:6px 15px;
}



.lead_id_search{
    color: var(--app_default);
    font-size: 16px;
    font-family: openSansSemiBold;
}


.lead_id_search{
    float: right;
}
