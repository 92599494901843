/*----------------------------------------New Video*/




.room {
    position: relative;
    overflow: hidden;
}

.room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}

.local-participant {
    text-align: center;
    margin-bottom: 2em;
    position: absolute;
    z-index: 50;
    right: 0;
    bottom: 0;
    width: 20%;
}
.remote-participants {
    width: 100%;
    position: relative;
}
.participant {
    background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
}
.participant:last-child {
    margin-right: 0;
}
.participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}

video {
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
}

.local-participant .participant {
    padding: 3px !important;
}

.remote-participants video {
    height: 100vh;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
}

.remote-participants .participant {
    padding: 0 ;
    border-radius: 0;
}

.buttons_box{
    position: absolute;
    z-index: 1000;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 27px;
}

.option_btn{
    background: #d6cdcd;
    opacity: 0.6;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.option_btn > i {
    font-size: 24px;
    margin-left: 11px;
    margin-top: 13px;
}

 .call_stop  {
    background: red !important;
    opacity: 1 !important;
    color: white !important;
}


 .you_dis {
     height: 100vh;
     width: 100%;
     background: #2a2525;
 }

 .you_dis .center_box {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     font-size: 60px;
     color: white;
     text-align: center;
 }

 .room_name {
     font-size: 22px;
     position: absolute;
     left: 23px;
     top: 13px;
     color: white;
     font-family: openSansRegular;
 }

.option_btn .apa-icon-notes:before {
    margin-top: 13px;
    margin-left: 4px;
}

.noteBox{
    bottom: 100px;
    position: absolute;
    left: 30px;
    background: #eaebed;
    padding: 15px;
    border-radius: 10px;
    z-index: 5000;
}

.save_btn {
    margin-top: 8px;
    background-color: #FFC10E;
    border-radius: 30px;
    padding: 7px 35px;
    border: none;
    font-size: 16px;
    font-family: OpenSansSemiBold;
    color: #ffffff;
    float: right;
}

.video_title {
    font-size: 24px;
    font-family: openSansBold;
    color: #00345D;
    margin-bottom: 40px;
}

.noteBox .react-datepicker-wrapper {
    display: inherit;
    margin-bottom: 14px;
}

.noteBox .input_date {
    border: 0;
    color: var(--app_default);
    background: none;
    width: 100%;
    font-size: 13px;
    font-family: openSansRegular;
    border-bottom: 1px solid;
}
.noteBox .input_date:focus{
    outline: none !important;
}
.noteBox .react-datepicker-wrapper:after {
    top: inherit !important;
}

.noteBox label {
    font-size: 13px;
    font-family: openSansBold;
    color: var(--app_default);
}

.noteBox .selectdiv:after {
    content: "\e82f";
    font-family: "apa";
    color: #00559A;
    font-size: 8px;
    right: 21px;
    top: inherit;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}

.noteBox select {
    outline: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #99B7CF;
    width: 100%;
    border-radius: 0;
    height: 25px;
    z-index: 2;
    color: #00559A !important;
    margin-bottom: 20px;
}

.noteBox select:focus {
background: #eaebed;
}

.noteBox textarea {
    min-height: 100px !important;
}

.noteBox .react-datepicker__close-icon::after {
    position: relative;
    top: -1px;
    right: 20px;
}

@media (max-width: 420px) {
    .local-participant{
        bottom: 75px;
        width: 40%;
    }

}
@media (max-width: 768px) {
    .local-participant{
        bottom: 60px;
        width: 30%;
    }


}