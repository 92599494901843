.messages_tabs{
    margin-top: 15px;
    margin-right: 13px;
}

.messages_tabs .nav-link {
    background-color: #fff;
    padding: 6px 20px ;
    opacity: 0.4;
    border-radius: 30px;
    display: flex;
    color: var(--app_default);
    justify-content: center;
}

.messages_tabs .nav-link.active{
    background-color: #fff;
    color: var(--app_default);
    opacity: 1;
}

.messages_content{
    box-shadow: 0px 0px 10px 0px #cdcdcd;
    background-color: #fff;
    padding: 15px 22px;
    margin-bottom: 20px;
    border-radius: 6px;
}
.messages_content:hover,.messages_content:hover > * {
    background: #2491E4;
    cursor: pointer;
    color:#fff;
}
.messages_tabs:hover a {
    background: #2491E4;
    cursor: pointer;
}

.messages_content h4{
    font-size: 15px;
    font-family: OpenSansBold;
    color: var(--app_default);
    margin: 0;
}

.messages_content p {
    font-size: 13px;
    font-family: openSansRegular;
    color: var(--app_default);
    display: inline-block;
}

.messages_content span{
    font-size: 13px;
    font-family: openSansRegular;
    color: #99B7CF;
    padding-left: 15px;
}


.messages_select select{
    border: none;
    background-color: transparent;
    color: #1B75BE;
    font-size: 13px;
    font-family: openSansRegular;
}

 .messages_select:after{
    content: "\e82f";
    font-family: "apa";
    color: #00559A;
    font-size: 8px;
    right: 21px;
    top: 5px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}

.messages_select select:focus{
    border: none !important;
}

.chat_title{
    box-shadow: 0 4px 0 8px #F5F5F5 ;
    background-color: #fff;
    padding: 18px;
    position: relative;
    margin-bottom: 20px;
}


.chat_title h4{
    font-size: 18px;
    font-family: OpenSansBold;
    color: #00345D;
}


.message_title,.message_icons{
    display: inline-block;
}

.message_icons ul li{
    list-style: none;
}

.message_icons  {
   position: absolute;
    right: 18px;
    margin-left: 5px;
}

.message_icons ul li i {
    font-size: 14px;
    color: var(--app_default);
    padding: 5px;
}


.icon-view{
  padding-left: 5px;
}


/*---------chat-----------*/


.inbox_msg {
    background-color: #fff;
    height: 750px;
}

.chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}


.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
    margin-top: 30px;
}
.message_text {
    background:#F1F5F9;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
    display: inline-block;
}
.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}

.received_withd_msg span,.sent_msg span{
   display: inline-block;
   padding-left: 5px;
}

.outgoing_msg{
    overflow:hidden;
    margin:0 0 26px;
}
.sent_msg {
    float: right;
    width: 52%;
}

.input_msg_write{
    text-align: center;
}
.input_msg_write input {
    border:1px solid var(--app_default);
    border-radius: 50px;
    padding: 22px 82px;
    width: 80%;
    color: #00345D;
    font-size: 15px;
    min-height: 48px;
}


.input_msg_write input::placeholder{
    color: #00345D;
}

.input_msg_write input:focus{
    outline: none;
}

.type_msg {
    border: 1px solid #1B75BE;
    border-radius: 50px;
    margin: 30px 10px;
    position: relative;
    padding: 3px;
}
.msg_send_btn {
    border: none;
    background: none;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right:30px;
    top: 13px;
    width: 33px;
}

.msg_send_btn i {
    color: var(--app_default);
    font-size: 22px;
}
.message_add{
    position: absolute;
    color: var(--app_default);
    font-size: 22px;
    top: 13px;
    left: 27px;
}
.messaging {
    padding: 0 0 50px 0;
}

/*.msg_history {*/
/*    height: 516px;*/
/*    overflow-y: auto;*/
/*}*/



.message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #F1F5F9;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid transparent;
    border-radius: 22px;
}


.message-content {
    padding: 0;
    margin: 0;
}



.message-blue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #F1F5F9;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.message-blue:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
}

.mesgs{
    height: 600px;
    overflow: auto;
}

.box {
    text-align: center;
    background: #2491E4;
    position: relative;
    padding: 10px;
    border-radius: 30px;
    margin-right: 20px;
    border-top-right-radius: 4px;
}

.sb1:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #2491E4;
    border-bottom: 15px solid transparent;
    right: -12px;
    top: 0;
}

.sent {
    color: #FFFFFF;
    font-size: 13px;
    font-family: openSansRegular;
    margin: 0;
}


.received_message_time{
    font-size: 13px;
    font-family: OpenSansBold;
    color: var(--app_default);

}


.received_message_time span{
    font-family: openSansRegular;
    color: #99B7CF;
}


.messages_info{
    padding: 28px;
}


.messages_info p {
    color: #1B75BE;
    font-size: 13px;
    font-family: OpenSansBold;
}


.messages_info span{
    color: var(--app_default);
    font-size: 13px;
    font-family: openSansRegular;
}


.messages_chat_history .tab-content{
    height: 700px;
    overflow: auto;
}


.messages_hot_lead input[type=checkbox] + label {
    display: block;
    cursor: pointer;
    padding: 0.2em;
}

.messages_hot_lead input[type=checkbox] {
    display: none;
}

.messages_hot_lead input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #1B75BE;
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
}

.messages_hot_lead input[type=checkbox] + label:active:before {
    transform: scale(0);
}

.messages_hot_lead input[type=checkbox]:checked + label:before {
    background-color: #1B75BE;

}

.add_data{
    position: absolute;
    top: -75px;
    left: 27px;;
}

.messenger_upload_img{
    margin-top: 13px;
}

.add_data span{
    border: 1px solid var(--app_default);
    border-radius: 30px;
    padding: 5px;
    color: #fff;
    background-color: var(--app_default);
    margin-bottom: 20px;
}


.messenger_upload_img > .upload__image-wrapper button{
    padding: 0 ;
}

.add_data span:hover{
    background-color: #FFC10E;
    border: none;
}

.mess_nav {
    flex-wrap: inherit;
    overflow: auto;
    margin-left: 22px;
}

.last{
    margin-right: 0;
}
.non_read {
    background:#2491E4;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    top: 31px;
}

.write_msg{
    width: 72%;
    border: none;
    outline: none;
    resize: none;
}

.bg_default_blue{
    background: #2491E4;
}











