.leads_details_title h3 {
    display: inline-block;
    font-size: 24px;
    font-family: OpenSansBold;
    color: #00345D;
}

.leads_details_title {
    padding: 10px 20px;
    margin-bottom: 20px;
}


.leads_details_title span {
    font-size: 16px;
    font-family: openSansSemiBold;
    color: #FFC10E;
    cursor: pointer;

}

.leads_details_properties ul li:first-child span {
    color: #FFC10E;
}

.social [class*="apa-icon-"] {
    background-color: #FFC10E;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    height: 31px;
    width: 31px;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
    margin: 0 10px 0 30px;
}

.leads_details_properties span {
    color: var(--app_default);
    font-size: 16px;
    font-family: openSansSemiBold;


}

.leads_details_properties ul {
    text-align: center;
    padding: 0;
}

.leads_details_properties ul li {
    color: var(--app_default);
    font-size: 16px;
    font-family: openSansSemiBold;
    list-style: none;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}


/*.textarea_border:after {*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 2px;*/
/*    height: 20px;*/
/*    background-color: var(--app_default);*/
/*    right: 15px;*/
/*    margin-top: 0px;*/
/*}*/


.leads_details_properties ul li i {
    margin-right: 5px;
}

.leads_details_properties ul li span:hover {
    color: #FFC10E;
}

.leads_details_tabs .nav-link {
    align-items: center;
    display: flex;
    border-radius: initial;
    justify-content: center;
}


.leads_details_edit {
    position: relative;
}

.leads_details_edit i {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--app_default);
    font-size: 20px;
    cursor: pointer;
}


.leads_details_edit i:hover {
    color: #FFC10E;
}


.leads_details_edit span {
    position: absolute;
    right: 25px;
    top: 0;
    color: var(--app_default);
    font-size: 20px;
    cursor: pointer;
}


.leads_details_related i {
    color: var(--app_default);

}

.details_view_icon {
    text-align: center;
}


.lead_details_basic_info input, .lead_details_basic_info input[type = 'text'], .lead_details_basic_info input[type = 'number'] {
    border: 0;
    color: var(--app_default);
    background: none;
    width: 100%;
    font-size: 13px;
    font-family: openSansRegular;
}

.leads_details_related span, .lead_details_basic_info p {
    font-size: 13px;
    font-family: openSansBold;
    color: var(--app_default);
}

.leads_details_related p {
    font-size: 13px;
    padding:0;
    font-family: openSansRegular;
}

.lead_details_basic_info input:focus {
    outline: none;
}


.lead_details_basic_info .edit_inputs {
    border-bottom: 1px solid var(--app_default) !important;
}

.leads_details_lossInfo i {
    color: var(--app_default);
}


.assigned_history_table {
    min-height: initial;
}


.inspection_info i {
    color: var(--app_default);
}

.inspection_info .insp_data {
    color: red;
}


.documents_folder {
    background-color: #fff;
    padding: 24px 5px;
    box-shadow: 1px 1px 11px 4px #f5f5f5;
    margin-bottom: 40px;
}
.icon_name
{
    text-transform: capitalize;
}
.documents_folder h3 {
    font-size: 24px;
    font-family: openSansBold;
    padding: 0 15px;
    color: #00345D;
}


.folders_content {
    background-color: #fff;
    padding: 30px 4px;
    margin: 10px 0;
    cursor: pointer;
}

.file_type_img
{
    object-fit: contain;
    position: absolute;
    width: 92px !important;
    left: 105px;
    top: 23px;
    cursor: pointer;
}

.folders_content p {
    padding: 0 15px;
    color: #00345D;
    font-size: 18px;
    font-family: openSansBold;
    margin: 0;
}


.folders_content span {
    color: #00559A;
    font-size: 13px;
    font-family: openSansRegular;
    padding: 0 15px;
}


.log_entry_modal .modal-header {
    position: relative;
}

.log_entry_modal .modal-header .microphone_icon {
    position: absolute;
    right: 70px;
    bottom: 30px;
    color: var(--app_default);
    font-family: openSansSemiBold;
    cursor: pointer;
}


.select_lead, .select_date {
    margin-bottom: 25px;
}

.sign_claim_modal .modal-body p {
    margin-bottom: 22px;
}

.log_entry_modal .modal-body p {
    color: var(--app_default);
    font-family: openSansBold;
    font-size: 13px;
}


.send_message_icons {
    float: right;
    color: var(--app_default);

}


.send_message_icons i {
    cursor: pointer;
}


.details_modal_titles {
    color: var(--app_default);
    font-size: 18px;
    font-family: openSansBold;
    margin-bottom: 25px;
}

.assign_modal .modal-body {
    padding: 16px 32px;
}


.assign_modal .modal-header .close {
    padding: 5px 14px !important;
}

.send_message_modal .modal-body {
    padding: 36px 41px;
}


.schedule_add_icon {
    float: right;
    color: var(--app_default);
    font-family: openSansBold;
    font-size: 13px;
    cursor: pointer;
}

.schedule_add_icon i {
    margin-left: 8px;
    font-size: 18px;
    margin-top: 2px;
}


.inactive_modal textarea {
    height: 100px;
}


#rotate_icon {
    transform: rotate(180deg);
}


.log_entry_table .table-bordered td, .log_entry_table .table-bordered th {
    text-align: initial !important;
    padding: 15px 30px;
    overflow: hidden;
}


.table_listen_icon {
    margin-left: 5px;
}

.log_entry_content {
    background-color: #fff;
}

.basic_progress {
    display: initial;
}

.log_entry_content h3 {
    color: #00345D;
    font-size: 24px;
    font-family: OpenSansBold;
    padding: 20px;

}
.logs_table.logs_table table tbody tr:nth-of-type(2n+2) {
background-color: #F1F5F9!important;
}
.inactive_box {
    background: #ececec;
    opacity: 0.5;
}
.log_entry_table {
    background: none;
}

.video_call {
    background-color: #fff;
    color: #FFC10E;
    border: 1px solid #FFC10E;
    margin-left: 20px;
}


.video_call:hover {
    border-color: transparent;
}

.video_call:focus{
    border-color: #FFC10E !important;
}

.video_call:active{
    background-color: #FFC10E !important;

}


.call_modal .modal-body {
    padding: 20px 39px;
}

.call_buttons {
    padding: 7px 34px;
}

.call_buttons:hover{
    border-color: #FFC10E !important;
}

.call_buttons:focus{
    border-color: #FFC10E !important;
}

.call_buttons:active{
    background-color: #FFC10E !important;

}


.log_info ul li {
    display: inline;
    list-style-type: none;
    padding-right: 20px;
    float: left;

}


.log_info ul li span {
    color: #1B75BE;
    font-family: OpenSansBold;
}


.log_info_modal .modal-body {
    padding: 13px 0;
}

.log_info ul li {
    margin-bottom: 28px;
}

.log_info ul li h4 {
    font-size: 13px;
    color: #00345D;
    font-family: openSansRegular;

}


.log_text p {
    font-size: 13px;
    font-family: openSansRegular;
    color: #00345D;
    padding: 52px 32px;
}


.leads_details_selects select {
    border-bottom: 1px solid var(--app_default);
    padding: 0 2px;
}


.lead_details_infos input {
    margin-top: 10px;
}

.details_date_inputs {
    border-bottom: 1px solid var(--app_default) !important;
    cursor: pointer !important;
}

.details_date_input_content .react-datepicker-wrapper:after {
    top: 6px;
}


.loss_info_dates .react-datepicker-wrapper:after {
    top: 2px !important;
}

.req_error{
    color: red;
    float: right;
    margin-top: 10px;
}

/*.disabled_textarea {*/
/*    border-bottom: 1px solid transparent;*/
/*}*/

/*.disabled_textarea:focus {*/
/*    border-bottom: 1px solid transparent;*/
/*}*/

.damage_radios {
    margin-top: 10px;
}


.loss_addresses input {
    margin-top: 10px;
}

.mortgage_title h3 {
    margin-left: -15px;


}

.disabled_selects {
    opacity: 0.4 !important;
}

.disabled {
    opacity: 0.3;
}
.related_remove_icon {
    position: absolute;
    right: 40px;
    top: 28px;
    color: var(--app_default);
    font-family: openSansSemiBold;
    cursor: pointer;
    padding: 0 8px;

}

.related_edit_icon {
    padding: 0 10px;
}

.discover_modal .related_update input {
    border-bottom: 1px solid transparent;
}


.discover_modal .related_update input[type=checkbox]:checked + label:before {
    background-color: #1B75BE;
    border-color: #1B75BE;

}

.related_update_modal .modal-header .close:hover {
    color: #FFC10E;
}

.message_image_uploader {
    display: inline-block;
}


.upload_data_info {
    position: absolute;
    left: 5px;
    max-width: 300px;
}

._1Yplu button {
    display: none;
}

.audio_content{
    margin-bottom: 30px;
}

.audio_content {
    border-radius: 30px;
    background-color: #F1F3F4;
    padding:11px  15px;
    position: relative;
}

.record_time {
    position: absolute;
    width: 70%;
    height: 0;
    bottom: 0;
    left: 62px;
    border-bottom: 3px solid #4D6A8B;
    top:22px
}




.audio_content i {
    color: #00559A;
}

.audio_content span {
    float: right;
    color: var(--app_default);
}

.audio_content button{
    outline: none;
    border: none;
}

.record_button {
    border: none;
}


.suggested_content h5 {
    color: #FF4040;
    font-size: 13px;
    font-family: openSansRegular;
    margin-bottom: 15px;
}

.pending_approval {
    display: block;
    position: relative;
}

.suggested_modal .modal-header{
    color: #00345D;
    font-size: 24px;
    font-family: OpenSansBold;

}

.pending_approval h4 {
    font-size: 13px;
    font-family: OpenSansBold;
    color: #1B75BE;
    display: inline-block;

}

.suggested_modal .pending_approval p, .pending_approval span {
    color: #00345D;
    font-size: 13px;
    font-family: openSansRegular;
    display: inline-block;
    padding-right: 15px;
    margin-left: 10px;
}

.suggested_modal .pending_approval p:before {
    content: '';
    width: 1px;
    height: 14px;
    position: absolute;
    left: 145px;
    top: 7px;
    background: #00345D;

}

.record{
    display: none;
}

.audio_player {
    border-radius: 30px;
    background-color: #F1F3F4 !important;
    line-height: 0 !important;
    padding:4px 15px !important;
}


.audio_player .rhap_time{
    color: var(--app_default);
}

.audio_player .rhap_progress-bar-show-download{
    background-color: #4D6A8B;
}

.audio_player .rhap_progress-indicator{
    width: 12px;
    height: 12px;
    background: #4D6A8B;
    top: -4px;
}

.audio_player .rhap_progress-filled{
    background: #E6EDF3 !important;
}

.audio_player .rhap_play-pause-button i {
    font-size: 15px;
    color: var(--app_default);
}

.audio_player .rhap_button-clear{
    color: var(--app_default);
}

.audio_player .rhap_volume-indicator{
    background: var(--app_default);
}

.audio_player .rhap_additional-controls{
    display: none;
}

 .modal-body .remove_record{
    background-color: red !important;
    padding: 6px 38px;
    margin-top: 10px;
}

.schedule_modale .modal-body  .react-datepicker__close-icon{
    left: 114px;
    top: 2px;
}

.schedule_modale .modal-body  .react-datepicker__close-icon::after{
    cursor: pointer;
    color: var(--app_default);
    background: none;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00d"
 }




/*-----------responsive----------*/
.table_inactive_background{
    background: #fff ;
}

.mobile_table_content{
    padding: 20px 0;
}
.table_active_background{
    background-color: #F1F5F9 ;
}

.mobile_table_content h3{
    font-size: 13px;
    color: #99B7CF;
    font-family: OpenSansBold;
    margin-top: 15px;
}


.mobile_table_content .mobile_table_icons div:nth-child(1){
   margin-left: -4px;

}

.mobile_table_content i {
    color: #99B7CF;
}

.mobile_table_content .mobile_table_icons div{
    margin-bottom: 10px;
}

.mobile_table_content p {
    font-size: 12px;
    color: #001E36;
    font-family: openSansRegular;
    margin: 13px 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input{
    width: initial;
}


.document_cin{
    margin: 5px 15px;
}

.document_cin img{
    width: 100%;
    margin-bottom: 10px;
}


.document_cin p{
    font-size: 13px;
    font-family: openSansRegular;
    color: var(--app_default);
}



.schedule_modale .modal-body p {
    color: var(--app_default);
    font-family: openSansBold;
    font-size: 13px;

}

.schedule_date_content input{
    margin: 0;
}


.mobile_begin_form{
    margin-bottom: 20px;
}


.mobile_begin_form textarea{
 width: 100%;
}

.mobile_begin_form input,.mobile_begin_form textarea{
    border: 1px solid #99B7CF !important;
    border-radius: 5px;
}


.mobile_begin_inspection_content{
    display: none;
}


.mobile_begin_inspection .modal-title{
    color: #001E36;
    font-size: 18px;
    font-family: OpenSansBold;
}


.mobile_begin_inspection .modal-body p,.mobile_begin_inspection .modal-body h5 {
    color: #A5C0D5;
    font-size: 13px;
}

.mobile_begin_inspection .discover_modal .modal-title {
    padding-left: 15px;
}
.mobile_begin_inspection .profile_select select {
    margin-top: 6px;
}
.mobile_begin_inspection .profile_content .selectdiv::after {
    top: 21px;
}
.mobile_begin_form input, .mobile_begin_form textarea {
    outline: none;
    color: var(--app_default);
    font-size: 13px;
}
.mobile_begin_inspection .modal-body p, .mobile_begin_inspection .modal-body h5 {
    font-family: openSansBold;
}
.mobile_begin_inspection .opt-12 {
font-size: 13px;
vertical-align: super;
}

.general_date{
    float: right;
    margin-right: 15px;
}
.page_name{
    color: #00345D;
    font-size: 24px;
    font-family: OpenSansSemiBold;
}
.general_date .territory_date {
    background: #e1e5e9;
    margin-top: 0;
}
.react-daterange-picker__calendar{
    z-index: 100 !important;
}