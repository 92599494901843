
#wrapper {
    overflow-x: hidden;
    position: relative;

}

.list-group-item {
    background: none;

}


#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    transition: margin .25s ease-out;
    width: 300px;
    display: flex;
    position: relative;
    flex-direction: column;
}

#sidebar-wrapper .sidebar-heading {
    padding: 38px 40px;
    font-size: 1.2rem;
    margin-bottom: 9px;
}

#sidebar-wrapper .list-group {
    width: 300px;
    height: 100%;
}


.bottom_button {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.list-group-flush > .list-group-item:last-child {
    flex: 1
}

.list-group-flush > .list-group-item:last-child i {
    font-size: 20px
}

#sidebar-wrapper .list-group a .active_link {
    margin-left: 15px;

}

#sidebar-wrapper .list-group a, .list-group button {
    border: none;
    font-size: 15px;
    font-family: openSansRegular;
    padding: 24px 40px;
    color: #00559A;
}

#sidebar-wrapper .list-group a:hover {
    color: #FFC10E;
}

#sidebar-wrapper .list-group a:hover:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffc10e;
}


#sidebar-wrapper .list-group a i {
    font-size: 20px;
}

.list-group button:focus, .list-group button:hover {
    outline: none;
    background: none;
    color: #FFC10E;
}

#sidebar-wrapper .list-group span {
    margin-left: 16px;
}

.active_link i {
    color: #FFC10E !important;
}


.nav-side-menu .menu-list svg {
    width: 50px;

}

#Layer_1 {
    width: 30px;
}

.active_link {
    color: #FFC10E !important;
}


.active_link span {
    color: #FFC10E;
}


#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#wrapper {
    background-color: #F5F5F5;
}


.add_lead {
    padding: 0 15px 0 15px;
}

.add_lead button {
    padding: 11px 33px;
    font-weight: bold;
    line-height: 1;
    position: relative;
    border: 1px solid #FFC10E;
    border-radius: 25px;
    background: #FFC10E;
    background-clip: padding-box;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-family: OpenSansSemiBold;
}

.cls-1, .cls-2 {
    fill: none;
}

.cls-2 {
    stroke: #00559a;
    stroke-miterlimit: 10;
}

.cls-3 {
    fill: #00559a;
}


.dashboard_nav ul li:nth-last-child(2), .dashboard_nav ul li:nth-last-child(3) {
    padding: 16px 0 30px;
    margin-right: 0;
    margin-top: 15px;

}

.dashboard_nav i {
    color: #00559A;
    font-size: 20px;
}

a.list-group-item.list-group-item-action.active_link:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffc10e;
}

.dashboard_nav img {
    margin-left: 30px;
    margin-right: 18px;
    margin-top: 10px;
}


.create_claim button {
    padding: 10px 18px;
    font-weight: bold;
    line-height: 1;
    position: relative;
    border: 1px solid #00559A;
    border-radius: 25px;
    background: transparent;
    background-clip: padding-box;
    color: #00559A;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-family: OpenSansSemiBold;
}


.user_avatar img {
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.search-box {
    transform: translate(0, 14%);
    background: none;
    height: 40px;
    border-radius: 40px;
    padding: 2px 17px;
    right: 0;
    cursor: pointer;
}
.search-box .close_icon {
    margin: 9px 0 0 6px;
    color: #FFC10E;

}
.search input {
    display: block;
    width: 260px;
    padding: 2px 13px;
    border-bottom: 1px solid #FFC10E;

}

.search-text {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: #00559A;
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 2;
    width: 0;
}

.notify_drobdown button{
    background: none;
    border: none;
}


.notify_drobdown button:hover,.notify_drobdown button:focus,.notify_drobdown button:active{
    background: none !important;
}


.notify_drobdown button:after{
    display: none;
}

.navbar{
    padding: 0 8px;
    margin-top: 10px;
}

.notify_dd{
    padding: 9px 0 0;
    background: #fff;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    border-radius: 10px;
    position: absolute;
    width: 320px;
    right: 8px;
    top: 45px;
    z-index: 5000;
    box-shadow: 1px 1px 11px 4px #f5f5f5;
}


.notify_content{
    padding: 0 20px;
}

.notify_dd:before{
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 14px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -9px;
    right: 10px;
}


.notify_content :before{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #dee2e6;
    top: 40px;
}

.notify_title{
    display: inline-block;
    color: #00345D;
}


.notify_view_all{
    display: inline-block;
    float: right;
}


.header_notify p {
    font-size: 10px !important;
}

.header_notify:hover:before{
    display: none;
}

.header_notify:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #dee2e6;

}

.header_notify .notifications_date  span{
    padding-left: 24px;
    font-size: 14px;
    font-family: 'openSansSemiBold';
    color: var(--app_default);
}


/*-------------*/


@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }


    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

.mobile_logo {
    display: none;
}


