
.leads_details_mobile_slider,.mobile_properties{
    display: none;
}


@media (min-width:900px) and (max-width: 1600px) {
    .search_tabs .nav-link {
        font-size: 13px;
    }
    .add_lead_basic h3 {
        font-size: 19px;
    }
    .leads_details_properties span {
        font-size: 13px;
    }
    .leads_details_properties ul {
        margin: 0;
        padding: 0;
    }
    .leads_details_properties ul li {
        padding: 10px;
    }
}

@media (min-width: 1600px) {
    .steps {
        width: 90%;
        float: right;
    }
}

@media (min-width: 1200px) and (max-width: 1550px) {
    .add_lead_col {
        flex: 0 0 75.333333%;
        max-width: 75.333333%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .add_lead_col {
        flex: 0 0 85.333333%;
        max-width: 85.333333%;
    }
}

@media (max-width: 991px) {
    .showOnDesktop{
        display: none;
    }
    .create_user_tab {
        display: none;
    }


    .mobile_begin_inspection_content{
        display: block;
    }

    .log_entry_modal .modal-header .microphone_icon{
        bottom: 25px;
    }

    .log_entry_modal .modal-title{
       margin-top: 0;
    }

    .help_select{
        margin-top: 15px;
    }

    .help_select .selectdiv:after{
        top: 12px;
    }

   .help_select select{
       padding: 2px !important;

       height: initial;
   }


    .styles_scroll-to-top__2A70v{
        display: none;
    }

    .leads_details_properties ul li:first-child span{
        color: var(--app_default);
    }

    .upload__image-wrapper button{
        font-size: 12px;
        padding: 0;
    }

    .profile_content .selectdiv:after{
        top: 30px;
    }


    .desktop_tabs{
        display: none;
    }

    .mobile_notifications{
        margin: 0 16px;
    }

    .mobile_properties{
        display: block;
    }


    .mobile_properties{
        text-align: initial;
    }

 .mobile_properties span {
        font-size: 13px;
    }

    .leads_details_properties ul li i{
        margin-right: 2px;
    }

    .leads_details_properties ul{
        margin-top: 13px;
        padding: 0 20px;
    }

 .leads_details_properties .mobile_properties li {
     padding: 1px 0 ;
 }

 .leads_details_title h3{
  display: block;
 }

 .page_title{
     padding: 24px 8px;
 }


 .leads_details_content{
     margin-bottom: 20px;
 }

    .leads_details_mobile_slider{
        display: block;
    }

    .call_modal .modal-body{
        padding: 0 12px;
    }

    .call_modal .modal-body p {
        padding: 0 10px;
    }
    .mobile_space {
        margin-top: 15px;
    }


    .mobile-search-box{
        padding: 9px 0 ;
    }

    .send_request button{
        margin-right: initial !important;
    }

    .vhc {
        display: initial !important;
    }

    .add_lead_title h1 {
        margin-bottom: 30px;
        text-align: center;
    }

    .steps > .step {
        font-size: 12px;
        line-height: 13px;
    }

    .steps > .step.is-active {
        color: #00559A;
    }

    .steps > .step:before {
        margin-bottom: -50px;
    }

    .steps {
        margin-top: 54px;
        height: 50px;
    }

    .dashboard_nav {
        display: block !important;
    }

    .add_lead_basic h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .add_lead_basic {
        padding: 15px;
        margin-bottom: initial;
    }

    .settings_tabs .nav-link {
        padding: 5px 10px;
    }


    #sidebar-wrapper .list-group {
        width: 15rem;
    }

    .react-datepicker-popper {
        z-index: 2000 !important;
        right: 0 !important;
        left: initial !important;
    }

    #sidebar-wrapper .list-group a, .list-group button {
        padding: 24px 30px;
    }


    .add_lead_basic p {
        font-size: 10px;
        color: #001E36;
    }

    .leads_details_related span, .lead_details_basic_info p{
        color: #99B7CF;
    }


    .lead_details_basic_info input, .lead_details_basic_info input[type = 'text'], .lead_details_basic_info input[type = 'number']{
        color: #001E36;
    }

    .mobile_strong{
        font-family: OpenSansBold !important;
        color: #001E36 !important;
    }


    .navbar-light .navbar-toggler {
        border: none;
        color: #99B7CF;
    }

    .mobile_logo {
        display: block;
    }


    .create_claim, .add_lead {
        display: none;
    }


    .nav-pills .leads_tabs a {
        padding: 10px;
        border-radius: 20px;
    }

    .nav-pills .leads_tabs {
        padding: 8px;
    }

    .nav-pills .leads_tabs .nav-link {
        font-size: 13px;
    }

    .leads_content {
        display: none;
    }

    .mobile_logo {
        position: absolute;
        top: 4px;
        left: 75px;
    }


    .dashboard_nav {

        position: absolute;
        right: 0;
    }


    .user_avatar img {
        vertical-align: middle;
        width: 40px;
        height: 40px;
        margin-top: 15px;
        border-radius: 50%;
        margin-left: 8px;

    }

    .dashboard_nav ul li:nth-last-child(2), .dashboard_nav ul li:nth-last-child(3){
        padding: 10px 0 30px;
    }

    .dashboard_nav .navbar-nav {
        flex-direction: row;
    }


    .change_profile_inputs input,.change_profile_inputs select{
        border: 1px solid #99B7CF !important;
        border-radius: 5px;
    }


    .schedule_modale .modal-title{
        font-size: 18px;
    }

    .change_profile_inputs .schedule_date{
        margin: 0;
        padding: 2px 0;
    }

    .schedule_date_content{
        margin-bottom: 20px;
    }

    .select_content:after{
        right: 25px;
        top: 10px;
    }

    .schedule_date_content p{
     margin: initial;
    }

    .react-datepicker-wrapper:after{
        right: 22px;
        top: 0;
    }

    .profile_select select{
        border: 1px solid #99B7CF;
        border-radius: 5px;
        height: 28px;
        margin-top: 14px;
        padding: 0;
    }

    .change_pass_content p{
        font-size: 11px;
    }

    .pass_validate span{
        margin-left: -19px;
        display: block;
        font-size: 9px;
        color: #99B7CF;
        font-family: 'openSansRegular';
    }

    .insured_progress{
     display: initial;
    }

    .quick_overview{
        width: 100%;
        margin-bottom: 10px;
    }


    .leads_details_tabs .nav-link{
        font-size: 13px;
        padding: 10px;
        height: 50px;
        border-radius: 30px;
        opacity: 0.5;

    }


    .d_none{
        display: none !important;
    }

    .leads_details_title span{
     font-size: 14px;
    }

    .social [class*="apa-icon-"]{
        background-color: #FFC10E;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        height: 31px;
        width: 31px;
        line-height: 28px;
        font-size: 22px;
        text-align: center;
        margin: 0 8px 0 -8px;
    }


    .active_slider .nav-link{
        background-color: #fff;
        color: #001E36;
        opacity: 0.9;
    }


    .search_tabs .nav-link{
        font-size: 12px;
    }

}

@media (max-width:768px ) {
    .leads_details_title h3 {
        font-size: 14px;
    }
    .leads_details_title span {
        font-size: 11px;
        margin-left: 8px;
    }
    .social [class*="apa-icon-"] {
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 11px;
    }
    .leads_details_properties ul {
        margin-top: 13px;
        padding: 0 15px 0 0;
        text-align: right;
        margin-bottom: 5px;
    }
    .leads_details_title {
        padding: 15px 0 0 15px;
        margin-bottom: 5px;
    }
    [id^="table_container"] {
        padding: 10px;
    }
    .add_lead_basic {
        padding: 15px;
        margin-bottom: initial;
        border-radius: 6px;
    }
    .leads_details_tabs .nav-link {
        padding: 8px 10px;
        height: auto;
        width: 100%;
        white-space: nowrap;
    }
    .send_message_modal .modal-body,.discover_modal .modal-body {
        padding: 25px 15px;
    }
    .schedule_modale .modal-header {
        padding: 6px 0;
    }
    .discover_modal .modal-footer button {
        font-size: 12px;
    }

    .discover_modal .modal-title {
        margin-left: 0;
        font-size: 15px;
        font-family: openSansBold;
        color: #00345D;
        padding: 0;
        margin-top: 5px;
    }

}

@media (min-width: 1200px) and (max-width: 1600px) {
    .add_lead_col {
        flex: 0 0 95.333333%;
        max-width: 95.333333%;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .add_lead_col {
        flex: 0 0 75.333333%;
        max-width: 75.333333%;
    }
}