


.notifications_content{
    background-color: #fff;
    min-height: 800px;
    margin: 34px;
    border: 1px solid #dee2e6;
}


.notification_body p{
    font-size: 13px;
    font-family: 'openSansRegular';
    color: #00345D;
    margin: 0;
    cursor: pointer;
}

.notification_body p span{
    color: var(--app_default);
    font-weight: bold;

}

.notification_body{
    background-color: #fff;
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
}

.notifications_content .c_not{
    background-color: #FCFCFC ;
}

.notification_body:hover:before{
    content: '';
    width: 2px;
    height: 29%;
    position: absolute;
    right: 18px;
    top: 40px;
    background: var(--app_default);
}

.notifications_date{
 display: block;

}

.notifications_date p{
    display: inline-block;
    padding-right: 24px;
    font-size: 14px;
    font-family: 'openSansSemiBold';
    color: var(--app_default);
    margin: 0;
    position: relative;
}


.notifications_date p:before{
    content: '';
    width: 2px;
    height: 14px;
    position: absolute;
    right: 0;
    top: 0;
    background: var(--app_default);
}

.notifications_date span{
    padding-left: 24px;
    font-size: 14px;
    font-family: 'openSansSemiBold';
    color: var(--app_default);
}

.notifications_time span{
    font-size: 11px;
    font-family: 'openSansItalic';
    color: #9CB9D1 ;
}

.notify_read {
    background: #f7f9f9;
}
.notify_count{
    width: 23px;
    height: 23px;
    background-color: #FFC10E;
    position: absolute;
    border-radius: 50%;
    color: white;
    right: -2px;
    top:2px;
    line-height: 21px;
    text-align: center;
}



