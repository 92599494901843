 .new_leads .nav-link{
   border-radius: initial;
   padding: 28px;
}


 input[type=checkbox] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
 }

 input[type=checkbox] {
    display: none;
 }

 input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid var(--app_default);
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
 }

 input[type=checkbox] + label:active:before {
    transform: scale(0);
 }

 input[type=checkbox]:checked + label:before {
    background-color: #99B7CF;
    border-color: #ccc;
 }

 input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
 }


 .new_lead_table  table  tr:hover{
position: relative !important;
 }


 .new_lead_table  table tbody  tr:hover::after{
     /* position: absolute;
     content: '';
     width: 2px;
     height: 5%;
     background-color: var(--app_default);
     right: 50px;
     margin-top: 14px; */
 }


 .lead_table .table-bordered  .icons{
     text-align: center;
 }





