.page_title {
    padding: 24px 30px;
}


.page_title h2 {
    color: #00345D;
    font-size: 24px;
    font-family: OpenSansSemiBold;
}

.leads_search {
    padding: 18px 27px;
    text-align: end;
}
.leads_search i {
    font-size: 20px;
    padding-right: 6px;

}



.leads_search button{
    color: #00559A;
    font-size: 16px;
    font-family: OpenSansSemiBold;
    border: none;
    background: none;
}

.leads_search a{
    color: #00559A;
    font-size: 16px;
    font-family: OpenSansSemiBold;
}

.leads_search svg {
    margin-right: 12px;
}


.leads_tabs {
    padding: 0 30px;
    border: none;
}


[id^="table_container"] {
    padding: 28px;

}
.leads_tabs a {
    padding: 30px;
}


.leads_tabs .nav-link {
    border: 1px solid #FBFBFB;
    text-align: center;
    color: #94A9BA;
    font-weight: 400;
    font-size: 18px;
    font-family: OpenSansSemiBold;
    background-color: #FBFBFB;

}

.leads_tabs .nav-link.active {
    background-color: #ffffff;
    font-weight: bold;
    color: #00345D;
}

.leads_tabs .nav-link:focus {
    outline: none;
}

.lead_table {
    background-color: #fff;
    min-height: 800px;

}

.lead_table .table-bordered thead td, .table-bordered thead th ,.rdt_TableCol{
    font-size: 13px;
    font-family: OpenSansSemiBold;
    color: #1B75BE;
    text-align: center;
}



.lead_table table tbody tr  {
    background: #ffffff !important ;
}

.lead_table .table_hovered tr{
    background-color: #F1F5F9 !important;
}

.lead_table td i{
 font-size: 15px;
 color: #00559A;
 cursor: pointer;
}

.lead_table .user_delete_icon{
    margin-left: 15px;
}


.lead_table table {
    border-bottom: none;
}

.lead_table .table-bordered td, .table-bordered th{
    font-size: 13px;
    font-family: openSansRegular;
    color: #00345D;
    border: none;
    white-space:nowrap;
    text-align: center;
    padding: 20px 5px;
}


.insured_progress{
   margin: 0;
    padding: 0;

}
.insured_progress li  {
    height: 4px;
    width: 4px;
    background-color: #5AF1C1;
    border-radius: 50%;
    display: inline-block;
    margin: 2px 2px;
}


.insured_progress .green_dot{
    background-color: #5AF1C1;
}

.insured_progress .green_dot ~ li{
    background-color: #FF4040;
}


.disabled_input{
    opacity: 0.4;
}

#button-tooltip-2 div {
    background-color: #fff;
    z-index: 5000;
    box-shadow: 1px 1px 11px 4px #f5f5f5;
    position: absolute;
    right: -14px;
    margin-top: 25px;

}

#button-tooltip-2 h3{
    display: block;
    font-size: 10px;
    font-family: OpenSansBold;
    color: #00559A;
    padding: 15px 0 0 0;
}


#button-tooltip-2 p {
    font-size: 10px;
    font-family: openSansRegular;
    color: #00559A;
    padding: 15px 0 0 0;
}


#button-tooltip-2 :before{
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 14px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -10px;
    right: 0;

}

 .inactive_category{
    color: red !important;
}
.table_pagination{
    margin-top: 15px;
    overflow: hidden;
}

.table_pagination .pagination{
    padding-left: 0;
    position: relative;
    bottom: 0;
 }
 .page-link {
    color: #00559a;
}
.page-item.active .page-link {
    background-color: #00559a;
    border-color: #00559a;
}
 .search_no_result{
     text-align: center;
     color: var(--app_default);
 }

 .discover_modal.options_modal .modal-footer button:nth-child(2){
     background: #FFC10E ;
     border: 1px solid #FFC10E ;
     color: #fff;
 }
.report_url {
    text-decoration: underline!important;
    font-size: 13px!important;
    line-height: 20px!important;
    text-align: left!important;
    color: #1B75BE!important;
    cursor: pointer!important;
}
.report_modal {
    font-size: 13px;
}
.report_modal span {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    color: #1B75BE;
}
.report_modal .head-info {
    margin-right: 15px;
    display: inline-block
}
.large_modal.modal-lg {
    max-width: 970px;
}
.green_text {
    color:#139169
}


.lnndaO {
    overflow:  initial !important;
}

.rdt_TableHeadRow{
    min-height:52px !important;
    border-bottom-width: 2px !important;
}
.XlRpR{
    display: block !important;
}

.rdt_TableCol{
    padding: 20px 3px;
    justify-content: center;
    padding-left: initial !important;
    padding-right: initial !important;
    min-width: 95px !important;
}
.rdt_Table{
    padding: 0 8px;
}

.rdt_TableCol_Sortable{
    overflow: initial !important;
    border-bottom-width: 2px;
}
.hkMDrI{
    overflow: unset !important;
}

.rdt_TableCell{
    font-size: 13px;
    font-family: openSansRegular;
    color: #00345D;
    border: none;
    white-space: nowrap;
    text-align: center;
    padding: 20px 5px;
    justify-content: center;
    padding-left: 0px !important;
    min-width: 95px !important;
}

.rdt_TableRow{
    border: none !important;
    background-color: initial !important;
}
.rdt_Pagination{
    border: none !important;
}

.newLead .rdt_TableCol:nth-last-child(-n+3) {
    min-width: 25px !important;
}
.newLead .rdt_TableCell:nth-last-child(-n+3) {
    min-width:25px !important;
}

.needAssignLead .rdt_TableCol:nth-last-child(-n+4) {
    min-width: 25px !important;
}
.needAssignLead .rdt_TableCell:nth-last-child(-n+4) {
    min-width:25px !important;
}
.needAssignLead .rdt_TableCell:nth-last-child(3) > div {
    overflow: unset !important;
}
.needAssignLead .rdt_TableCell ,.needAssignLead .rdt_TableCol{
    min-width: 90px !important;
}

.rdt_TableCell:nth-last-child(-n+1) >div {
    overflow: unset !important;
}


.initiated .rdt_TableCell:nth-last-child(-n+3) >div {
    overflow: unset !important;
}
.initiated .rdt_TableCell:nth-last-child(2) >div {
    margin-left: 12px;
}

.bsgpsK div:first-child:hover{
    overflow: visible;
    background: #fff;
    padding: 0 20px;
}

.apexcharts-legend-marker{
    top:-3px !important;
}
.apexcharts-legend-text{
    margin-left: 5px !important;
}
.apexcharts-toolbar{
    display: none !important;
}

.export_icon:hover{
    color: #FFC10E;
}

.activeNeedLead .rdt_TableCell:nth-last-child(4) > div{
    overflow: initial !important;
}