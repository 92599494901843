.contact_folder_content{
    padding: 0 35px 0 14px;
}

.contact_folders{
    border: 1px solid #f5f5f5;
}


.add_property_modal .modal-header span:hover{
    color:#FFC10E;
}


.contact_info_properties ul{
    text-align: end;
    margin-right: 40px;
}


.property_content{
    background-color: #fff;
    margin-bottom: 20px;
    padding: 15px 38px;
}

.property_content input , .property_content select{
    outline: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #99B7CF;
    color: #00559A;
    font-size: 13px;
    margin-left: 50px;
}

.property_content h3{
    font-size: 24px;
    color: #00345D;
    font-family: OpenSansBold;
    margin-bottom: 40px;
    margin-top: 30px;
}


.property_content p {
    color: #1B75BE;
    font-size: 13px;
    font-family: OpenSansBold;
}


.property_content input[type=checkbox] + label {
    display: block;
    cursor: pointer;
    padding: 0 50px;
}

.property_content input[type=checkbox] {
    display: none;
}

.property_content input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #1B75BE;
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
}

.property_content input[type=checkbox] + label:active:before {
    transform: scale(0);
}

.property_content input[type=checkbox]:checked + label:before {
    background-color: #1B75BE;
    border-color: #1B75BE;

}

.property_content input[type=checkbox]:disabled + label:before {
    transform: scale(1);

}

.property_content input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
}

.building_info_content{
    min-height: 669px;
}



