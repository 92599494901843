.users_table_container{
    padding: 28px;
}


.users_title{
    padding: 22px 10px;
}

.users_table_container .lead_table  th ,.users_table_container .lead_table  tbody{
    border: none !important;
}



.users_table_container .lead_table thead{
 border-bottom: 1px solid #dee2e6;
}

.assign_role select {
    outline: 0;
    background: none;
    padding-left: 6px;
    border: 0;
    border-bottom: 1px solid #99B7CF;
    width: 100%;
    border-radius: 0;
    height: 25px;
    z-index: 2;
    color: var(--app_default) !important;
}



.select_role  :before{
    content: "\e82f" !important;
    font-family: "apa";
    color: #00559A;
    font-size: 8px;
    right: 23px;
    top: 6px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}


.user_update_modal .modal-body p {
    color: var(--app_default);
    font-size: 13px;
    font-family: openSansBold;
    margin-top: 12px;
}

.user_update_modal .modal-body input{
    border-bottom: 1px solid #99B7CF;
}


.user_update_modal .modal-body input[type=checkbox]:checked + label:before{
   background-color: #1B75BE;
}

.users_table_icons:hover{
    color: #FFC10E;
}


.users_table_container .lead_table .table-bordered td, .table-bordered th {
    font-size: 13px;
    font-family: openSansRegular;
    color: #00345D;
    border: none;
    white-space:nowrap;
    text-align: center;
    padding: 20px 3px;
}

.removed {
    transition: all 0.5s ease-out;
    opacity: 0;
}

.discover_modal .modal-footer button:nth-child(2){
    background-color:transparent;
    border-radius: 30px;
    border: 1px solid var(--app_default);
    padding: 7px 50px;
    color: var(--app_default);
    font-size: 16px;
    font-family: OpenSansSemiBold;
}

.discover_modal .modal-footer button:nth-child(2):active,.discover_modal .modal-footer button:nth-child(2):focus{
    background-color: #fff !important;
    color: var(--app_default);
}

.deleteConfirmModal .modal-body p{
    font-size: 16px;
}

.deleteConfirmModal .modal-footer button:nth-child(1){
   border: 1px solid;
}

.deleteConfirmModal .modal-body p span{
    color: red;
}


.create_error{
    text-align: center;
    font-size: 20px;
    color: red ;
}

