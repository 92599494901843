.reports_content{
    background-color: #ffffff;
    font-weight: bold;
    color: var(--app_default);
    border: 1px solid #FBFBFB;
    border-radius: 30px;
    text-align: center;
    padding: 10px;
    margin-bottom: 46px;

}

.quick_overview {
    background-color: #ffffff;
    font-weight: bold;
    color: var(--app_default);
    opacity: 1;
    border: 1px solid #FBFBFB;
    padding: 15px  0;
    width: 240px;
    height: 188px;
    position: relative;
}


.quick_overview h4{
    font-size: 15px;
    font-family: OpenSansBold;
    color: #00345D;
    padding: 29px;
}

.quick_overview span{
    font-size: 34px;
    font-family: OpenSansBold;

    padding:0 28px;
}


.up_static{
    color: #5AF1C1;
}


.static_down{
    color: #FF4040 !important;
}

.static_normal{
    color: #99B7CF;
}

.report_title h2{
    margin-bottom: 24px;
    font-size: 15px;
    color: #00345D;
    font-family: OpenSansBold;
    padding:  0 25px ;
}


.quick_overview img{
    margin-left: 25px;
}


.quick_overview :nth-child(4){
    font-size: 13px;
    font-family: OpenSansBold;
    position: absolute;
    right: 40px;
}

.quick_overview :nth-child(4) i {
margin-right: 4px !important;
}

#chart {

    margin: 35px auto;
}


.report_content{
    border: 1px solid #FBFBFB;
    background-color: #fff;
    padding: 30px 30px 0;
    box-shadow: 1px 1px 11px 4px #f5f5f5;
   min-height: 500px;
}

.report_content h4{
    font-size: 18px;
    font-family: OpenSansBold;
    color: #00345D;
    margin-bottom: 80px;

}

.reports_calendar_icon{
    color: var(--app_default);
    margin-left: -10px;

}


.territory_reports_calendar{
    position: absolute;
    right: 20px;
    top: 21px;
}

.territory_date input{
    color: #00345D ;
}

.territory_date .react-daterange-picker__wrapper{
    border: none;

}


.territory_date  {
    background-color: #F1F5F9;
    border-radius: 30px;
    padding: 0 5px;
    margin-right: 28px;
    margin-top: 5px;
}


.territory_date .react-daterange-picker__calendar-button{
    top: 0;
    position: absolute;
    right: -33px;
}


.territory_reports_body .apexcharts-legend-series{
    font-size: 13px;
    font-family: openSansRegular;
    padding-top: 15px;
    color:var(--app_default);
}


.territory_reports_body .apexcharts-legend-text{
    margin-left: 10px;
}

.report_tabs{
    margin-bottom: 20px;
}

.report_tabs .nav-item a  {
    padding: 9px 40px ;
}

.result_count{
    font-weight: 600;
    margin-left: 20px;
}

.apexcharts-legend-text {
    vertical-align: super;
}



