@font-face {
    font-family: openSansRegular;
    src: url('../fonts/openSans/OpenSans-Regular.ttf');
}

@font-face {
    font-family: openSansSemiBold;
    src: url('../fonts/openSans/OpenSans-Semibold.ttf');
}

@font-face {
    font-family: openSansBold;
    src: url('../fonts/openSans/OpenSans-Bold.ttf');
}

@font-face {
    font-family: openSansItalic;
    src: url('../fonts/openSans/OpenSans-Italic.ttf');
}

:root {
    --app_default: #00559A;

}

@font-face {
    font-family: 'apa';
    src: url('../../assets/fonts/icons/fontello.eot?25790327');
    src: url('../../assets/fonts/icons/fontello.eot?25790327#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icons/fontello.woff2?25790327') format('woff2'),
    url('../../assets/fonts/icons/fontello.woff?25790327') format('woff'),
    url('../../assets/fonts/icons/fontello.ttf?25790327') format('truetype'),
    url('../../assets/fonts/icons/fontello.svg?25790327#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}


.apa_boxShadow {
    box-shadow: 0 4px 0 8px #F5F5F5;
    background-color: #fff;
}


select[disabled] {
    background: none !important;
}

button:focus {
    box-shadow: none !important;
    outline: none !important;
}


.default_select {
    outline: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #99B7CF;
    width: 100%;
    border-radius: 0;
    height: 25px;
    z-index: 2;
    color: #00559A !important;
}


.c-pointer {
    cursor: pointer;
}


.hovered_icons:hover {
    color: #FFC10E;
}

.select_content:after {
    content: "\e82f";
    font-family: "apa";
    color: #00559A;
    font-size: 7px;
    right: 17px;
    top: 10px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 1000;
}


.mid_col {
    float: none;
    margin: 0 auto;
}

.vhc {
    height: 100vh;
    display: flex;
    align-items: center;
}


.mobile_vhc {
    height: 100vh;
    display: flex;
    align-items: center;
}

select {
    outline: 0 !important;
    box-shadow: none !important;

}

.login_button {
    text-align: center;
    margin-top: 50px;
}


.init_button {
    padding: 9px 0;
    width: 33%;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    background-color: #FFC10E;
    font-family: OpenSansSemiBold;
}


.init_button:hover, .init_button:visited, .init_button:focus {
    background-color: #FFC10E !important;

}


.link_button {
    padding: 9px 50px;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    background-color: #FFC10E;
    font-family: OpenSansSemiBold;
}


.cancel_button {
    padding: 9px 50px;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    background-color: var(--app_default);
    font-family: OpenSansSemiBold;
}

.cancel_button:hover {
    color: #fff;
    text-decoration: none;
}

.send_request button {
    margin-right: 20px;
    padding: 9px 30px;
}

.link_button:hover {
    color: #fff;
    text-decoration: none;
}

.login_button button:focus {
    outline: none;
}

.spacing-80 {
    height: 85px;
    display: block;
    width: 100%;
}

.default_hover {
    cursor: pointer;
}

.default_hover:hover {
    color: #FFC10E !important;
    background-color: transparent !important;


}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}


input[type="checkbox"][readonly] {
    pointer-events: none !important;
}


.error_input {
    border-bottom: 1px solid #FF4040 !important;
}

td {
    vertical-align: middle !important;
}


.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.table_icon:hover {
    color: #FFC10E;
}


table {
    border-collapse: inherit;
    border-spacing: 0;
}

select:focus, input:focus, textarea:focus {
    border-bottom: 3px solid var(--app_default) !important;
}


.styles_scroll-to-top__2A70v svg:hover {
    fill: #FFC10E;
}

.breadcrumb-item a {
    font-size: 24px;
    font-family: openSansBold;
    color: #00345D;
    font-weight: 500;
}

.breadcrumb .active {
    font-size: 24px;
    font-family: openSansBold;
    font-weight: 500;
}

.breadcrumb {
    background: inherit;
    font-size: 24px;
    color: #00345D;
    font-weight: 500;
    margin-bottom: 0px;
    padding-bottom: 0px;

}

.documents_container {
    height: 740px
}

/*!
 * Box UI Element
 *
 * Copyright 2019 Box, Inc. All rights reserved.
 *
 * This product includes software developed by Box, Inc. ("Box")
 * (http://www.box.com)
 *
 * ALL BOX SOFTWARE IS PROVIDED "AS IS" AND ANY EXPRESS OR IMPLIED
 * WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED.
 * IN NO EVENT SHALL BOX BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 *
 * See the Box license for the specific language governing permissions
 * and limitations under the license.
 */
.crawler {
    width: 10px;
    margin: 0 auto;
    font-size: 0;
    white-space: nowrap
}

.crawler div {
    display: inline-block;
    width: 2px;
    height: 10px;
    background-color: #0061d5;
    border-radius: 4px;
    animation: crawler .66s ease-in-out infinite
}

.ex-btn-primary .crawler div {
    background-color: #fff
}

.crawler.is-small {
    transform: scale(.7)
}

.crawler.is-medium {
    transform: scale(1.5)
}

.crawler.is-large {
    transform: scale(2)
}

.is-loading .crawler div {
    transform: translateZ(0);
    will-change: transform, opacity
}

.crawler div:nth-child(2) {
    margin-left: 2px;
    animation-delay: .1s
}

.crawler div:last-child {
    left: 8px;
    margin-left: 2px;
    animation-delay: .2s
}

@keyframes crawler {
    0%, 80%, to {
        transform: scaleY(1.2);
        opacity: .5
    }
    40% {
        transform: scaleY(1.6);
        opacity: 1
    }
}

.loading-indicator-wrapper {
    position: relative
}

.loading-indicator-wrapper .loading-indicator-veil {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex
}

.loading-indicator-wrapper .loading-indicator-veil.is-with-center-crawler {
    align-items: center
}

.loading-indicator-wrapper .loading-indicator-veil.blur-content {
    background-color: hsla(0, 0%, 98.4%, .75)
}

.loading-indicator-wrapper .loading-indicator-veil.hide-content {
    background-color: #fff
}

.radar {
    position: relative
}

.radar .radar-dot:before {
    top: -4px;
    left: -4px;
    z-index: 193;
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 50%
}

.radar .radar-dot:after, .radar .radar-dot:before {
    position: absolute;
    background-color: #0061d5;
    content: " "
}

.radar .radar-dot:after {
    z-index: 192;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    animation-name: ripple;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite
}

.radar .radar-circle:before {
    border: 1px solid #0061d5;
    border-radius: 50%
}

.radar .radar-circle:after, .radar .radar-circle:before {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 192;
    width: 40px;
    height: 40px;
    content: " "
}

.radar .radar-circle:after {
    background-color: #0061d5;
    border-radius: 50%;
    opacity: .3
}

@keyframes ripple {
    0% {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: .75
    }
    to {
        top: -20px;
        left: -20px;
        width: 40px;
        height: 40px;
        opacity: 0
    }
}

.radar-animation-target-attached-bottom.radar-animation-target-attached-center > .radar, .radar-animation-target-attached-bottom.radar-animation-target-attached-left > .radar, .radar-animation-target-attached-bottom.radar-animation-target-attached-right > .radar {
    margin-top: 1px
}

.radar-animation-target-attached-middle.radar-animation-target-attached-right > .radar {
    margin-left: 1px
}

.radar-animation-target-attached-middle.radar-animation-target-attached-center > .radar {
    margin-top: 1px
}

.radar-animation-target-attached-middle.radar-animation-target-attached-left > .radar {
    margin-right: 1px
}

.radar-animation-target-attached-top.radar-animation-target-attached-center > .radar, .radar-animation-target-attached-top.radar-animation-target-attached-left > .radar, .radar-animation-target-attached-top.radar-animation-target-attached-right > .radar {
    margin-bottom: 1px
}

.radar-animation-element {
    z-index: 190
}

.badgeable-container .badges .bottom-left-badge, .badgeable-container .badges .bottom-right-badge, .badgeable-container .badges .top-left-badge, .badgeable-container .badges .top-right-badge {
    position: absolute;
    pointer-events: all
}

.badgeable-container {
    position: relative;
    display: inline-block;
    line-height: 1
}

.badgeable-container .badges {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
}

.badgeable-container .badges .top-left-badge {
    top: -6px;
    left: 8px;
    transform: translateX(-100%)
}

.badgeable-container .badges .top-right-badge {
    top: -6px;
    left: calc(100% - 8px)
}

.badgeable-container .badges .bottom-left-badge {
    bottom: -6px;
    left: 8px;
    transform: translateX(-100%)
}

.badgeable-container .badges .bottom-right-badge {
    bottom: -6px;
    left: calc(100% - 8px)
}

.bcu-item-label {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bcu-item-icon-name {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: default
}

.bcu-item-icon {
    flex: 0 0 50px;
    align-items: center
}

.bcu-item-icon, .bcu-item-name {
    display: flex;
    justify-content: center
}

.bcu-item-name {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    line-height: 15px;
    text-align: left
}

.bcu-icon-badge .badges .bottom-right-badge {
    bottom: -4px;
    left: calc(100% - 16px)
}

.bcu-progress-container {
    z-index: 201;
    width: 100%;
    height: 2px;
    margin-right: 40px;
    background: #e8e8e8;
    transition: opacity .4s
}

.bcu-progress-container .bcu-progress {
    top: 0;
    left: 0;
    max-width: 100%;
    height: 2px;
    background: #0061d5;
    box-shadow: 0 1px 5px 0 #e4f4ff;
    transition: width .1s
}

.bcu-item-progress {
    display: flex;
    align-items: center
}

.bcu-progress-label {
    min-width: 35px
}

.bdl-Tooltip, .tooltip {
    position: relative;
    max-width: 200px;
    padding: 8px 10px;
    color: #fff;
    font-size: 13px;
    line-height: 1.4;
    word-wrap: break-word;
    background-color: #4e4e4e;
    border-color: #4e4e4e;
    border-radius: 4px
}

.bdl-Tooltip:before, .tooltip:before {
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    content: ""
}

.bdl-Tooltip.is-error:after, .is-error.tooltip:after {
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: ""
}

.bdl-Tooltip.is-error, .is-error.tooltip {
    color: #222;
    background-color: #fdebee;
    border: 1px solid #f69bab
}

.bdl-Tooltip.is-error .tooltip-close-button .bdl-Tooltip-iconClose path, .is-error.tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #222
}

.bdl-Tooltip.is-callout, .is-callout.tooltip {
    color: #fafbfd;
    background-color: #003c84;
    border-color: #003c84
}

.bdl-Tooltip.is-callout a, .is-callout.tooltip a {
    color: #fafbfd
}

.bdl-Tooltip.is-callout .tooltip-close-button .bdl-Tooltip-iconClose path, .is-callout.tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #fafbfd
}

.bdl-Tooltip.is-metadata-tooltip, .is-metadata-tooltip.tooltip {
    margin-left: -8px
}

.bdl-Tooltip.with-close-button, .with-close-button.tooltip {
    padding-right: 28px
}

.bdl-Tooltip .tooltip-close-button, .tooltip .tooltip-close-button {
    position: absolute;
    top: 9px;
    right: 3px;
    width: 22px;
    cursor: pointer
}

.bdl-Tooltip .tooltip-close-button .bdl-Tooltip-iconClose, .tooltip .tooltip-close-button .bdl-Tooltip-iconClose {
    height: 16px
}

.bdl-Tooltip .tooltip-close-button .bdl-Tooltip-iconClose path, .tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #fff
}

.tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-center > .tooltip {
    margin-bottom: 10px
}

.tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-center > .tooltip:before {
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-top-color: inherit
}

.tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-center > .is-error.tooltip:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-top-color: #fdebee
}

.tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-left > .tooltip {
    margin-bottom: 10px
}

.tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-left > .tooltip:before {
    top: 100%;
    left: 10px;
    border-top-color: inherit
}

.tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-left > .is-error.tooltip:after {
    top: 100%;
    left: 11px;
    border-top-color: #fdebee
}

.tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-right > .tooltip {
    margin-bottom: 10px
}

.tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-right > .tooltip:before {
    top: 100%;
    right: 10px;
    border-top-color: inherit
}

.tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-right > .is-error.tooltip:after {
    top: 100%;
    right: 11px;
    border-top-color: #fdebee
}

.tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-middle.tooltip-target-attached-right > .tooltip {
    margin-left: 10px
}

.tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-middle.tooltip-target-attached-right > .tooltip:before {
    top: 50%;
    right: 100%;
    margin-top: -6px;
    border-right-color: inherit
}

.tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-middle.tooltip-target-attached-right > .is-error.tooltip:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-right-color: #fdebee
}

.tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-middle.tooltip-target-attached-left > .tooltip {
    margin-right: 10px
}

.tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-middle.tooltip-target-attached-left > .tooltip:before {
    top: 50%;
    left: 100%;
    margin-top: -6px;
    border-left-color: inherit
}

.tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-middle.tooltip-target-attached-left > .is-error.tooltip:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-left-color: #fdebee
}

.tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-center > .tooltip {
    margin-top: 10px
}

.tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-center > .tooltip:before {
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-bottom-color: inherit
}

.tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-center > .is-error.tooltip:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #fdebee
}

.tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-left > .tooltip {
    margin-top: 10px
}

.tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-left > .tooltip:before {
    bottom: 100%;
    left: 10px;
    border-bottom-color: inherit
}

.tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-left > .is-error.tooltip:after {
    bottom: 100%;
    left: 11px;
    border-bottom-color: #fdebee
}

.tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-right > .tooltip {
    margin-top: 10px
}

.tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-right > .tooltip:before {
    right: 10px;
    bottom: 100%;
    border-bottom-color: inherit
}

.tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-right > .is-error.tooltip:after {
    right: 11px;
    bottom: 100%;
    border-bottom-color: #fdebee
}

.tooltip-element {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 13px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    z-index: 190
}

.tooltip-element, .tooltip-element *, .tooltip-element :after, .tooltip-element :before {
    box-sizing: border-box !important
}

.bcu-item-action {
    width: 24px;
    height: 24px
}

.bcu-item-action .crawler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.bcu-item-action button {
    display: flex
}

.bcu-item-action button svg {
    display: block
}

.bcu-item-action button .be-icon-in-progress {
    width: 24px;
    height: 24px
}

.bcu-item-action button .be-icon-in-progress svg {
    display: none
}

.bcu-item-action button:hover .be-icon-in-progress svg {
    display: block
}

.bcu-item-action button:hover .be-icon-in-progress .crawler {
    display: none
}

.ReactVirtualized__Table__headerRow {
    font-weight: 700;
    text-transform: uppercase
}

.ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center
}

.ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
    margin-right: 10px;
    min-width: 0
}

.ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap
}

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px
}

.ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
    display: flex;
    align-items: center
}

.ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor
}

.bcu-item-row {
    border-bottom: 1px solid rgba(190, 212, 222, .4);
    outline: none
}

.bcu-item-list-action-column:last-child {
    margin-right: 18px
}

.bcu-drag-drop-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: .2
}

.bcu-upload-state {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center
}

.bcu-upload-state svg {
    margin-bottom: 10px
}

.bcu-upload-state.bcu-is-droppable .bcu-drag-drop-overlay {
    display: block;
    background: #0061d5
}

.bcu-upload-state.bcu-is-not-droppable .bcu-drag-drop-overlay {
    display: block;
    background: #c82341
}

.bcu-upload-state.bcu-has-items {
    display: none
}

.bcu-upload-state.bcu-has-items.bcu-is-droppable, .bcu-upload-state.bcu-has-items.bcu-is-not-droppable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: hsla(0, 0%, 100%, .8)
}

.bcu-upload-state.bcu-has-items.bcu-is-droppable .bcu-drag-drop-overlay, .bcu-upload-state.bcu-has-items.bcu-is-not-droppable .bcu-drag-drop-overlay {
    display: none
}

.bcu-upload-state-message + .be-input-btn {
    margin-top: 10px
}

.bcu-upload-state-message + .be-input-link {
    margin-top: 5px
}

.bcu-upload-input-container .be-input-link {
    display: inline-block
}

.bcu-droppable-content {
    position: relative;
    display: flex;
    flex: 1 0 200px;
    flex-direction: column
}

.bcu-droppable-content .bcu-item-list {
    position: relative;
    flex: 1
}

.bcu-droppable-content .bcu-item-list .ReactVirtualized__Table__Grid {
    outline: none
}

.bcu-uploads-manager-action {
    margin: 5px -20px 5px 5px
}

.bcu-uploads-manager-action .ex-btn-primary {
    display: flex
}

.bcu-overall-progress-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-right: 65px;
    padding-left: 30px;
    color: #fff;
    line-height: 60px;
    background: #0061d5;
    cursor: pointer
}

.bcu-overall-progress-bar:hover {
    background: #0063d9
}

.bcu-overall-progress-bar .bcu-progress {
    background: #fff
}

.bcu-overall-progress-bar .bcu-progress-container {
    background: transparent
}

.bcu-overall-progress-bar .bcu-upload-status {
    flex: none;
    margin-right: 20px;
    font-size: 16px
}

.be.bcu-uploads-manager-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 0;
    transition: max-height .5s
}

.be.bcu-uploads-manager-container .bcu-progress-container {
    margin-right: 0
}

.be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-container {
    display: none
}

.be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-label {
    margin-left: auto
}

.be.bcu-uploads-manager-container.bcu-is-visible {
    max-height: 60px
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-item-list {
    display: none;
    height: 375px
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-item-list .buik-btn-plain:focus {
    border: 1px solid #bcbcbc
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle {
    position: absolute;
    top: 0;
    right: 26px;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto 0;
    vertical-align: middle;
    background: 0 0;
    border: 0
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after, .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
    position: absolute;
    width: 6px;
    height: 6px;
    border: 0 solid #fff;
    content: ""
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
    bottom: 0;
    left: 0;
    border-bottom-width: 2px;
    border-left-width: 2px
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after {
    top: 0;
    right: 0;
    border-top-width: 2px;
    border-right-width: 2px
}

.be.bcu-uploads-manager-container.bcu-is-expanded {
    max-height: 435px
}

.be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-item-list {
    display: block
}

.be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-toggle:before {
    bottom: 7px;
    left: 7px
}

.be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-toggle:after {
    top: 7px;
    right: 7px
}

.be.bcu-uploads-manager-container .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:before {
    bottom: -3px;
    left: -3px
}

.be.bcu-uploads-manager-container .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:after {
    top: -3px;
    right: -3px
}

.be.bcu-uploads-manager-container.bcu-is-expanded .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:before {
    bottom: 6px;
    left: 6px
}

.be.bcu-uploads-manager-container.bcu-is-expanded .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:after {
    top: 6px;
    right: 6px
}

.be.bcu-uploads-manager-container .bcu-item-row {
    padding-right: 5px
}

.be.bcu-uploads-manager-container .bcu-item-icon-name {
    padding-left: 5px
}

.be.bcu-uploads-manager-container .bcu-progress {
    box-shadow: none
}

.be.bcu-uploads-manager-container .bcu-item-list {
    position: relative
}

.bcu-footer {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #fcfcfc;
    border-top: 1px solid #e8e8e8
}

.bcu-footer .bcu-footer-message {
    padding: 0 10px;
    text-align: center
}

.bcu-footer .bcu-footer-right .ex-btn {
    margin-left: 8px
}

@font-face {
    font-weight: 400;
    font-family: Lato;
    font-style: normal;
    src: local("Lato Regular"), local("Lato-Regular"), url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Regular.woff2) format("woff2"), url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Regular.woff) format("woff")
}

@font-face {
    font-weight: 700;
    font-family: Lato;
    font-style: normal;
    src: local("Lato Bold"), local("Lato-Bold"), url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Bold.woff2) format("woff2"), url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Bold.woff) format("woff")
}

.ex-btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 5px;
    padding: 10px 16px;
    color: #4e4e4e;
    font-weight: 400;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: .035em;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    vertical-align: top;
    background-color: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .05s ease-in-out, border-color .05s ease-in-out;
    -webkit-appearance: none;
    zoom: 1;
    -webkit-font-smoothing: antialiased
}

.ex-btn:active, .ex-btn:focus, .ex-btn:hover {
    text-decoration: none;
    outline: none
}

.ex-btn:not(.bdl-is-disabled):hover, .ex-btn:not(.is-disabled):hover {
    background-color: #f7f7f7
}

.ex-btn:not(.bdl-is-disabled):active, .ex-btn:not(.is-disabled):active {
    background-color: #ebebeb;
    border-color: #bcbcbc
}

.ex-btn:not(.bdl-is-disabled):focus, .ex-btn:not(.is-disabled):focus {
    border-color: #222;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1)
}

.ex-btn.bdl-is-disabled, .ex-btn.is-disabled, .ex-btn.is-loading {
    top: 0;
    cursor: default
}

.ex-btn.is-loading .ex-btn-content {
    visibility: hidden
}

.ex-btn.is-loading .ex-btn-loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

.ex-btn.bdl-is-disabled, .ex-btn.is-disabled {
    box-shadow: none;
    opacity: .4
}

.btn-plain, .btn-plain:active, .btn-plain:hover {
    margin: 0;
    padding: 0;
    font-weight: 400;
    border: none;
    cursor: pointer
}

.btn-plain, .btn-plain:active, .btn-plain:focus, .btn-plain:hover {
    background: transparent;
    box-shadow: none
}

button.btn-plain::-moz-focus-inner {
    border: 0
}

button svg {
    pointer-events: none
}

.ex-btn-primary {
    color: #fff;
    background-color: #0061d5;
    border-color: #0061d5;
    -webkit-font-smoothing: antialiased
}

.ex-btn-primary:not(.bdl-is-disabled):focus, .ex-btn-primary:not(.is-disabled):focus {
    background-color: #0074fe;
    border: 1px solid #0061d5;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .8), 0 1px 2px rgba(0, 0, 0, .1)
}

.ex-btn-primary:not(.bdl-is-disabled):hover, .ex-btn-primary:not(.is-disabled):hover {
    background-color: #0074fe;
    border-color: #0074fe
}

.ex-btn-primary:not(.bdl-is-disabled):active, .ex-btn-primary:not(.is-disabled):active {
    background-color: #004eac;
    border-color: #004eac;
    box-shadow: none
}

.ex-btn-primary.is-loading .ex-btn-content {
    background-color: #bcbcbc
}

.inline-alert {
    display: none;
    margin: 10px 0;
    padding: 14px 10px;
    color: #222;
    border-radius: 4px
}

.inline-alert.inline-alert-visible {
    display: block
}

.inline-alert.inline-alert-warning {
    background-color: #fef7e8;
    border: 1px solid #fad98d
}

.inline-alert.inline-alert-error {
    background-color: #fdebee;
    border: 1px solid #f69bab
}

.inline-alert.inline-alert-success {
    background-color: #e9f8f2;
    border: 1px solid #26c281
}

.inline-alert.inline-alert-info {
    background-color: #e9f2fe;
    border: 1px solid #91c2fd
}

.inline-alert.inline-alert-generic {
    background-color: #e8e8e8;
    border: 1px solid #909090
}

.accessibility-hidden {
    position: absolute;
    top: auto;
    left: -9999px
}

.overlay-wrapper {
    position: relative;
    display: inherit
}

.overlay-wrapper .overlay {
    position: absolute;
    z-index: 90;
    transform: rotateY(-10deg) rotateX(-10deg);
    transform-origin: left top;
    transform-style: preserve-3d;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 75ms, opacity 75ms, transform .125s;
    pointer-events: none
}

.overlay-wrapper.is-visible .overlay {
    transform: rotateY(0deg) rotateX(0deg);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    pointer-events: all
}

.overlay {
    margin: 0;
    padding: 8px 0;
    background: #fff;
    border: 1px solid #bcbcbc;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1)
}

.overlay hr {
    height: 0;
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #bcbcbc
}

.toggle-overlay-container > .ex-btn {
    margin-bottom: 0;
    margin-left: 0
}

.toggle-overlay .overlay {
    margin-top: 5px
}

.bdl-Pill, .ex-btn.bdl-Pill, .ex-btn.pill, .pill {
    display: flex;
    flex: none;
    align-items: center;
    max-width: 100%;
    height: 24px;
    margin: 1px;
    padding: 2px 10px;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #0061d5;
    border-radius: 3px
}

.bdl-Pill .bdl-Pill-text, .bdl-Pill .pill-text, .ex-btn.bdl-Pill .bdl-Pill-text, .ex-btn.bdl-Pill .pill-text, .ex-btn.pill .bdl-Pill-text, .ex-btn.pill .pill-text, .pill .bdl-Pill-text, .pill .pill-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bdl-Pill .close-btn, .ex-btn.bdl-Pill .close-btn, .ex-btn.pill .close-btn, .pill .close-btn {
    flex: none;
    margin: 0 0 0 4px;
    padding: 4px 0 4px 4px;
    color: #fff;
    font-weight: 700;
    font-size: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer
}

.bdl-is-disabled.pill, .bdl-Pill.bdl-is-disabled, .bdl-Pill.is-disabled, .ex-btn.bdl-is-disabled.pill, .ex-btn.bdl-Pill.bdl-is-disabled, .ex-btn.bdl-Pill.is-disabled, .ex-btn.is-disabled.pill, .is-disabled.pill {
    opacity: .5
}

.bdl-is-disabled.pill .close-btn, .bdl-Pill.bdl-is-disabled .close-btn, .bdl-Pill.is-disabled .close-btn, .ex-btn.bdl-is-disabled.pill .close-btn, .ex-btn.bdl-Pill.bdl-is-disabled .close-btn, .ex-btn.bdl-Pill.is-disabled .close-btn, .ex-btn.is-disabled.pill .close-btn, .is-disabled.pill .close-btn {
    cursor: default
}

.bdl-Pill.is-invalid, .ex-btn.bdl-Pill.is-invalid, .ex-btn.is-invalid.pill, .is-invalid.pill {
    background-color: #ed3757
}

.bdl-Pill.is-selected, .ex-btn.bdl-Pill.is-selected, .ex-btn.is-selected.pill, .is-selected.pill {
    box-shadow: inset 0 0 0 1px #fff
}

.bdl-Pill-warning, .pill-warning {
    color: #f5b31b;
    background-color: #fef7e8;
    border: 1px solid #fad98d
}

.bdl-Pill-warning a, .pill-warning a {
    color: #f5b31b
}

.bdl-Pill--error, .pill-error {
    color: #ed3757;
    background-color: #fdebee;
    border: 1px solid #f69bab
}

.bdl-Pill--error a, .pill-error a {
    color: #ed3757
}

.bdl-Pill.bdl-PillCloud-button, .bdl-Pill.pill-cloud-button, .bdl-PillCloud-button.pill, .pill-cloud-button.pill {
    display: inline-block;
    margin: 3px;
    color: #0061d5;
    background-color: #fff;
    border: 1px solid #0061d5
}

.bdl-Pill.bdl-PillCloud-button.is-selected, .bdl-Pill.is-selected.pill-cloud-button, .bdl-PillCloud-button.is-selected.pill, .is-selected.pill-cloud-button.pill {
    box-shadow: none
}

.bdl-Pill.bdl-PillCloud-button:not(.bdl-is-disabled):focus, .bdl-Pill.bdl-PillCloud-button:not(.is-disabled):focus, .bdl-Pill.pill-cloud-button:not(.bdl-is-disabled):focus, .bdl-Pill.pill-cloud-button:not(.is-disabled):focus, .bdl-PillCloud-button.pill:not(.bdl-is-disabled):focus, .bdl-PillCloud-button.pill:not(.is-disabled):focus, .pill-cloud-button.pill:not(.bdl-is-disabled):focus, .pill-cloud-button.pill:not(.is-disabled):focus {
    border-color: #004eac;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1)
}

.bdl-PillCloud, .pill-cloud-container {
    padding: 8px;
    overflow-y: scroll;
    border: 1px solid #e8e8e8
}

.be {
    font-size: 13px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #fff;
    border: 0;
    outline: none
}

.be, .be * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box !important
}

.be * {
    font-family: inherit
}

.be :after, .be :before {
    box-sizing: inherit;
    color: inherit;
    font-family: inherit;
    text-rendering: inherit
}

.be .lnk, .be a {
    color: #0061d5;
    text-decoration: none
}

.be .lnk {
    display: inline;
    margin: 0;
    padding: 0;
    background: transparent none;
    border: none;
    cursor: pointer;
    filter: none
}

.be .lnk, .be .lnk:active, .be .lnk:hover {
    height: auto;
    line-height: inherit;
    box-shadow: none
}

.be .lnk:active, .be .lnk:hover {
    background: transparent
}

.be .lnk:active {
    color: #0061d5
}

.be .lnk.bdl-is-disabled:focus, .be .lnk.is-disabled:focus, .be .lnk:active, .be .lnk:focus, .be a.bdl-is-disabled:focus, .be a.is-disabled:focus, .be a:focus {
    text-decoration: underline
}

.be .lnk.bdl-is-disabled, .be .lnk.bdl-is-disabled:active, .be .lnk.bdl-is-disabled:hover, .be .lnk.is-disabled, .be .lnk.is-disabled:active, .be .lnk.is-disabled:hover, .be a.bdl-is-disabled, .be a.bdl-is-disabled:active, .be a.bdl-is-disabled:hover, .be a.is-disabled, .be a.is-disabled:active, .be a.is-disabled:hover {
    color: #909090;
    text-decoration: none;
    cursor: default
}

.be .lnk-plain {
    color: inherit
}

.be .lnk-plain:active, .be .lnk-plain:hover {
    text-decoration: none
}

.be .lnk-plain.bdl-is-disabled, .be .lnk-plain.bdl-is-disabled:active, .be .lnk-plain.bdl-is-disabled:hover, .be .lnk-plain.is-disabled, .be .lnk-plain.is-disabled:active, .be .lnk-plain.is-disabled:hover {
    color: #909090
}

.be a svg {
    pointer-events: none
}

.be .link {
    overflow: hidden;
    color: #767676;
    line-height: 1.5em;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    transition: color .05s;
    font-smoothing: antialiased
}

.be .link:hover {
    color: #0061d5
}

.be .link-group-title {
    color: #a7a7a7;
    font-size: 10px;
    line-height: 28px;
    letter-spacing: .1em;
    text-transform: uppercase
}

.be div[contentEditable=true], .be input[type=email], .be input[type=number], .be input[type=password], .be input[type=search], .be input[type=tel], .be input[type=text], .be input[type=url], .be textarea {
    width: 262px;
    padding: 7px;
    color: #222;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
    transition: border-color .15s linear, box-shadow .1s linear;
    -webkit-font-smoothing: antialiased
}

.be div[contentEditable=true]:hover, .be input[type=email]:hover, .be input[type=number]:hover, .be input[type=password]:hover, .be input[type=search]:hover, .be input[type=tel]:hover, .be input[type=text]:hover, .be input[type=url]:hover, .be textarea:hover {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15)
}

.be div[contentEditable=true]:focus, .be input[type=email]:focus, .be input[type=number]:focus, .be input[type=password]:focus, .be input[type=search]:focus, .be input[type=tel]:focus, .be input[type=text]:focus, .be input[type=url]:focus, .be textarea:focus {
    border: 1px solid #0061d5;
    outline: 0;
    box-shadow: none
}

.be input:-ms-input-placeholder, .be input::-moz-placeholder, .be input::-webkit-input-placeholder {
    color: #d3d3d3
}

.be div[contentEditable=true].is-invalid, .be input[type=email].is-invalid, .be input[type=number].is-invalid, .be input[type=password].is-invalid, .be input[type=search].is-invalid, .be input[type=tel].is-invalid, .be input[type=text].is-invalid, .be input[type=url].is-invalid, .be textarea.is-invalid {
    border: 1px solid #ed3757
}

.be div[contentEditable=true].is-invalid:focus, .be input[type=email].is-invalid:focus, .be input[type=number].is-invalid:focus, .be input[type=password].is-invalid:focus, .be input[type=search].is-invalid:focus, .be input[type=tel].is-invalid:focus, .be input[type=text].is-invalid:focus, .be input[type=url].is-invalid:focus, .be textarea.is-invalid:focus {
    border: 1px solid #f44
}

.be div[contentEditable=true]:disabled, .be input[type=email]:disabled, .be input[type=number]:disabled, .be input[type=password]:disabled, .be input[type=search]:disabled, .be input[type=tel]:disabled, .be input[type=text]:disabled, .be input[type=url]:disabled, .be textarea:disabled {
    color: #bcbcbc;
    background-color: #fbfbfb;
    border-color: #e8e8e8;
    box-shadow: none
}

.be div[contentEditable=true]:disabled:hover, .be input[type=email]:disabled:hover, .be input[type=number]:disabled:hover, .be input[type=password]:disabled:hover, .be input[type=search]:disabled:hover, .be input[type=tel]:disabled:hover, .be input[type=text]:disabled:hover, .be input[type=url]:disabled:hover, .be textarea:disabled:hover {
    border-color: #e8e8e8
}

.be input[type=search] {
    -webkit-appearance: textfield
}

.be input[type=search]::-webkit-search-cancel-button, .be input[type=search]::-webkit-search-results-button {
    display: none
}

.be input::-ms-clear {
    display: none
}

.be textarea {
    vertical-align: top
}

.be .input-x-small, .be .input-x-small[type] {
    width: 100px
}

.be .input-small, .be .input-small[type] {
    width: 150px
}

.be .input-medium, .be .input-medium[type] {
    width: 300px
}

.be .input-large, .be .input-large[type] {
    width: 375px
}

.be .input-x-large, .be .input-x-large[type] {
    width: 475px
}

.be .input-full, .be .input-full[type] {
    width: 100%
}

.be .label-sep:after {
    content: ":"
}

.be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container select {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 34px;
    padding: 5px 0 5px 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.be .select-container select {
    padding-right: 25px;
    color: #222;
    background: none;
    border: none
}

.be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled {
    cursor: default
}

.be .select-container {
    position: relative;
    display: inline-block
}

.be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container .select-container-inner {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: top
}

.be .select-container .select-overlay {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0
}

.be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container .select-overlay {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .05);
    transition: border-color .15s linear, box-shadow .1s linear
}

.be .select-container .bdl-SelectButton:before, .be .select-container .select-button:before, .be .select-container .select-overlay:before {
    position: absolute;
    top: 15px;
    right: 11px;
    width: 0;
    height: 0;
    border-top: 3px solid #4e4e4e;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    transition: transform .15s;
    content: ""
}

.be .select-container .bdl-SelectButton[aria-expanded=true]:before, .be .select-container .select-button[aria-expanded=true]:before {
    transform: rotate(180deg)
}

.be .select-container .bdl-SelectButton, .be .select-container .select-button {
    padding-right: 25px;
    color: #222
}

.be .select-container .bdl-SelectButton:focus, .be .select-container .select-button:focus, .be .select-container select:focus {
    outline: none
}

.be .select-container .bdl-SelectButton:focus, .be .select-container .select-button:focus, .be .select-container select:focus ~ .select-overlay {
    border: 1px solid #0061d5
}

.be .select-container .bdl-SelectButton:hover, .be .select-container .select-button:hover, .be .select-container select:hover ~ .select-overlay {
    border: 1px solid #4c4c4c;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .1)
}

.be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled {
    color: #bcbcbc
}

.be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled ~ .select-overlay {
    background-color: #fbfbfb;
    border-color: #e8e8e8;
    box-shadow: none
}

.be .select-container .bdl-SelectButton:disabled:hover, .be .select-container .select-button:disabled:hover, .be .select-container select:disabled:hover ~ .select-overlay {
    box-shadow: none
}

.be .select-container .bdl-SelectButton:disabled:before, .be .select-container .select-button:disabled:before, .be .select-container select:disabled ~ .select-overlay:before {
    border-top: 3px solid #d5d5d5
}

.be .select-container.is-invalid .bdl-SelectButton:focus ~ .select-overlay, .be .select-container.is-invalid .select-button:focus ~ .select-overlay, .be .select-container.is-invalid select + .select-overlay, .be .select-container.is-invalid select:focus ~ .select-overlay, .be .select-container.is-invalid select:hover + .select-overlay {
    border: 1px solid #ed3757
}

.be .select-container .bdl-SelectButton:active, .be .select-container .select-button:active {
    background-color: #e8e8e8
}

.be .select-container-x-small {
    width: 50px
}

.be .select-container-small {
    width: 100px
}

.be .select-container-medium {
    width: 200px
}

.be .select-container-large {
    width: 262px
}

.be .select-container-x-large {
    width: 345px
}

.be .select-container.huge {
    width: 500px
}

.be select::-ms-expand {
    display: none
}

.be ::-webkit-input-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be ::-moz-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be :-ms-input-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be ::-webkit-file-upload-button {
    cursor: pointer
}

.be ul {
    margin: 0;
    padding: 0
}

.be ul li {
    list-style: none
}

.be .bdl-SelectButton, .be .btn-plain, .be .select-button, .be input, .be select, .be textarea {
    font: inherit
}

.ReactModal__Body--open .be {
    position: relative
}

.be-modal {
    font-size: 13px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px
}

.be-modal, .be-modal * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box !important
}

.be-modal * {
    font-family: inherit
}

.be-modal :after, .be-modal :before {
    box-sizing: inherit;
    color: inherit;
    font-family: inherit;
    text-rendering: inherit
}

.be-modal ::-webkit-input-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be-modal ::-moz-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be-modal :-ms-input-placeholder {
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: .6
}

.be-modal ::-webkit-file-upload-button {
    cursor: pointer
}

.be-modal ul {
    margin: 0;
    padding: 0
}

.be-modal ul li {
    list-style: none
}

.be-modal .bdl-SelectButton, .be-modal .btn-plain, .be-modal .select-button, .be-modal input, .be-modal select, .be-modal textarea {
    font: inherit
}

.be-app-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
}

.be-input-link {
    display: block;
    color: #0061d5;
    cursor: pointer
}

.be-input-link:active, .be-input-link:focus, .be-input-link:hover {
    text-decoration: underline
}

.be-input-link input {
    display: none
}

.be-input-btn {
    display: block;
    cursor: pointer
}

.be-hidden, .be-input-btn input {
    display: none
}

.be-logo {
    padding-left: 20px
}

.be-logo .be-logo-custom {
    max-width: 80px;
    max-height: 32px
}

.be-is-small .be-logo .be-logo-custom {
    max-width: 75px
}

.be-logo .be-logo-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 32px;
    background-color: #e8e8e8;
    border: 1px dashed
}

.be-is-small .be-logo .be-logo-placeholder {
    width: 60px
}

.be-logo .be-logo-placeholder span {
    font-size: 10px;
    text-transform: uppercase
}

.be-logo svg {
    display: block
}

.be .be-header {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    padding: 0 25px 0 0;
    background: #fcfcfc;
    border-bottom: 1px solid #e8e8e8
}

.be-is-small .be .be-header {
    padding-right: 20px
}

.be .be-header .be-search {
    flex: 1;
    padding-left: 20px
}

.be .be-header input[type=search] {
    width: 100%;
    font: inherit;
    -webkit-appearance: none
}

.dropdown-menu-element-attached-center .aria-menu, .dropdown-menu-element-attached-left .aria-menu, .dropdown-menu-element-attached-right .aria-menu {
    animation: fade-in .15s cubic-bezier(0, 0, .6, 1)
}

.dropdown-menu-element {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 14px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    z-index: 170;
    margin-top: 5px;
    perspective: 500px;
    perspective-origin: top
}

.dropdown-menu-element, .dropdown-menu-element *, .dropdown-menu-element :after, .dropdown-menu-element :before {
    box-sizing: border-box !important
}

.menu-toggle {
    display: flex;
    align-items: center
}

.menu-toggle > .toggle-arrow {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform;
    flex: none;
    margin-left: 5px
}

.dropdown-menu-enabled .menu-toggle > .toggle-arrow, .is-toggled .menu-toggle > .toggle-arrow {
    transform: rotate(180deg)
}

.lnk .menu-toggle > .toggle-arrow {
    margin-top: 2px
}

.lnk .menu-toggle > .toggle-arrow path {
    fill: #0061d5
}

.ex-btn-primary .menu-toggle .fill-color {
    fill: #fff
}

.lnk.bdl-is-disabled .menu-toggle .fill-color, .lnk.is-disabled .menu-toggle .fill-color {
    fill: #909090
}

.aria-menu {
    padding: 12px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
    margin: 0;
    white-space: nowrap;
    list-style: none;
    background: #fff
}

.aria-menu:not(.should-outline-focus):focus {
    outline: none
}

.aria-menu [role=separator] {
    height: 0;
    margin: 8px 4px;
    border: 0;
    border-top: 1px solid #e8e8e8
}

.aria-menu .lnk, .aria-menu .lnk:focus, .aria-menu .lnk:hover, .aria-menu a, .aria-menu a:focus, .aria-menu a:hover {
    text-decoration: none
}

.menu-item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 30px;
    color: #222;
    padding: 8px 48px 8px 8px;
    border-radius: 8px;
    cursor: pointer
}

.menu-item.is-active {
    background-color: rgba(34, 34, 34, .05)
}

.menu-item.menu-section-header {
    color: #000;
    font-weight: 700;
    font-size: 10px
}

.menu-item[aria-disabled] {
    cursor: default;
    opacity: .4
}

.menu-item.is-select-item {
    position: relative;
    padding-left: 38px
}

.menu-item.is-select-item.is-selected:before {
    position: absolute;
    top: 10px;
    left: 14px;
    width: 6px;
    height: 12px;
    margin: auto;
    border-color: #222;
    border-style: none solid solid none;
    border-width: 2px;
    transform: rotate(45deg);
    content: ""
}

.menu-item:not([aria-disabled]):focus, .menu-item:not([aria-disabled]):hover {
    color: #222;
    background-color: #f4f4f4
}

.menu-item svg:not(.menu-item-arrow) {
    margin-right: 16px
}

.submenu-target {
    position: relative
}

.submenu-target .submenu {
    position: absolute;
    top: -12px;
    right: auto;
    left: calc(100% - -12px)
}

.submenu-target .submenu.is-left-aligned {
    right: 100%;
    left: auto
}

.submenu-target .submenu.is-hidden {
    right: auto;
    left: -9999px
}

.submenu-target .submenu.is-bottom-aligned {
    top: auto;
    bottom: -12px
}

.submenu-target .menu-item-arrow {
    position: absolute;
    top: 12px;
    right: 8px
}

.bdl-Pagination-dropdownMenu {
    max-height: 300px;
    margin-bottom: 5px;
    overflow: auto
}

.bdl-ButtonGroup, .ex-btn-group {
    position: relative
}

.bdl-ButtonGroup .toggle-overlay, .ex-btn-group .toggle-overlay {
    display: inline
}

.bdl-ButtonGroup .toggle-overlay > .bdl-targeted-click-through > .ex-btn, .bdl-ButtonGroup .toggle-overlay > .ex-btn, .ex-btn-group .toggle-overlay > .bdl-targeted-click-through > .ex-btn, .ex-btn-group .toggle-overlay > .ex-btn {
    margin-left: 0;
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 0 4px 4px 0
}

.bdl-ButtonGroup .toggle-overlay > .bdl-targeted-click-through > .ex-btn.ex-btn-primary, .bdl-ButtonGroup .toggle-overlay > .ex-btn.ex-btn-primary, .ex-btn-group .toggle-overlay > .bdl-targeted-click-through > .ex-btn.ex-btn-primary, .ex-btn-group .toggle-overlay > .ex-btn.ex-btn-primary {
    border-left-color: #004aa2
}

.bdl-ButtonGroup .toggle-overlay .icon-caret-down:after, .ex-btn-group .toggle-overlay .icon-caret-down:after {
    content: ""
}

.bdl-ButtonGroup .toggle-overlay .icon-caret-down path, .ex-btn-group .toggle-overlay .icon-caret-down path {
    fill: #fff
}

.bdl-ButtonGroup .toggle-overlay .overlay-wrapper, .ex-btn-group .toggle-overlay .overlay-wrapper {
    position: static
}

.bdl-ButtonGroup .toggle-overlay .overlay-wrapper .overlay, .ex-btn-group .toggle-overlay .overlay-wrapper .overlay {
    left: 0
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn, .bdl-ButtonGroup > .ex-btn, .ex-btn-group > .bdl-targeted-click-through > .ex-btn, .ex-btn-group > .ex-btn {
    margin: 5px 0 5px -1px;
    border-radius: 0
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn.ex-btn-primary, .bdl-ButtonGroup > .ex-btn.ex-btn-primary, .ex-btn-group > .bdl-targeted-click-through > .ex-btn.ex-btn-primary, .ex-btn-group > .ex-btn.ex-btn-primary {
    margin: 5px 0;
    border-right-color: #004aa2
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn.ex-btn-primary.is-selected, .bdl-ButtonGroup > .ex-btn.ex-btn-primary.is-selected, .ex-btn-group > .bdl-targeted-click-through > .ex-btn.ex-btn-primary.is-selected, .ex-btn-group > .ex-btn.ex-btn-primary.is-selected {
    color: #fff;
    background-color: #004eac;
    border-color: #003e89;
    box-shadow: none
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn.ex-btn-primary:last-of-type, .bdl-ButtonGroup > .ex-btn.ex-btn-primary:last-of-type, .ex-btn-group > .bdl-targeted-click-through > .ex-btn.ex-btn-primary:last-of-type, .ex-btn-group > .ex-btn.ex-btn-primary:last-of-type {
    border-right-color: #0061d5
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn:first-child, .bdl-ButtonGroup > .ex-btn:first-child, .ex-btn-group > .bdl-targeted-click-through > .ex-btn:first-child, .ex-btn-group > .ex-btn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn:last-child, .bdl-ButtonGroup > .ex-btn:last-child, .ex-btn-group > .bdl-targeted-click-through > .ex-btn:last-child, .ex-btn-group > .ex-btn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn.is-selected, .bdl-ButtonGroup > .ex-btn.is-selected, .ex-btn-group > .bdl-targeted-click-through > .ex-btn.is-selected, .ex-btn-group > .ex-btn.is-selected {
    z-index: 2;
    color: #4e4e4e;
    background-color: #e8e8e8;
    border-color: #767676;
    box-shadow: none
}

.bdl-ButtonGroup > .bdl-targeted-click-through > .ex-btn:focus, .bdl-ButtonGroup > .ex-btn:focus, .ex-btn-group > .bdl-targeted-click-through > .ex-btn:focus, .ex-btn-group > .ex-btn:focus {
    z-index: 3
}

.bdl-ButtonGroup.bdl-is-disabled > .bdl-Button, .bdl-ButtonGroup.bdl-is-disabled > .bdl-targeted-click-through > .bdl-Button, .bdl-ButtonGroup.bdl-is-disabled > .bdl-targeted-click-through > .ex-btn, .bdl-ButtonGroup.bdl-is-disabled > .ex-btn, .bdl-ButtonGroup.is-disabled > .bdl-Button, .bdl-ButtonGroup.is-disabled > .bdl-targeted-click-through > .bdl-Button, .bdl-ButtonGroup.is-disabled > .bdl-targeted-click-through > .ex-btn, .bdl-ButtonGroup.is-disabled > .ex-btn, .bdl-is-disabled.ex-btn-group > .bdl-Button, .bdl-is-disabled.ex-btn-group > .bdl-targeted-click-through > .bdl-Button, .bdl-is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn, .bdl-is-disabled.ex-btn-group > .ex-btn, .is-disabled.ex-btn-group > .bdl-Button, .is-disabled.ex-btn-group > .bdl-targeted-click-through > .bdl-Button, .is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn, .is-disabled.ex-btn-group > .ex-btn {
    color: #767676;
    background-color: #fbfbfb;
    border: 1px solid #bcbcbc;
    box-shadow: none;
    cursor: default;
    opacity: .4
}

.bdl-ButtonGroup.bdl-is-disabled > .bdl-targeted-click-through > .ex-btn-primary, .bdl-ButtonGroup.bdl-is-disabled > .ex-btn-primary, .bdl-ButtonGroup.is-disabled > .bdl-targeted-click-through > .ex-btn-primary, .bdl-ButtonGroup.is-disabled > .ex-btn-primary, .bdl-is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn-primary, .bdl-is-disabled.ex-btn-group > .ex-btn-primary, .is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn-primary, .is-disabled.ex-btn-group > .ex-btn-primary {
    color: #fff;
    background-color: #0061d5;
    border-color: #0061d5 #004aa2 #0061d5 #0061d5
}

.bdl-ButtonGroup.bdl-is-disabled > .bdl-targeted-click-through > .ex-btn-primary:last-of-type, .bdl-ButtonGroup.bdl-is-disabled > .ex-btn-primary:last-of-type, .bdl-ButtonGroup.is-disabled > .bdl-targeted-click-through > .ex-btn-primary:last-of-type, .bdl-ButtonGroup.is-disabled > .ex-btn-primary:last-of-type, .bdl-is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn-primary:last-of-type, .bdl-is-disabled.ex-btn-group > .ex-btn-primary:last-of-type, .is-disabled.ex-btn-group > .bdl-targeted-click-through > .ex-btn-primary:last-of-type, .is-disabled.ex-btn-group > .ex-btn-primary:last-of-type {
    border-right-color: #0061d5
}

.bdl-Pagination {
    display: flex
}

.bdl-Pagination .bdl-Pagination-count {
    display: flex;
    align-items: center
}

.be-is-small .bdl-Pagination .bdl-Pagination-count {
    display: none
}

.bdl-Pagination .bdl-Pagination-toggle {
    margin-right: 5px;
    margin-left: 5px
}

.be-breadcrumb {
    display: flex;
    align-items: center;
    min-width: 20px
}

.be-breadcrumb:only-child {
    min-width: 64px
}

.be .be-breadcrumb .btn-plain {
    color: #767676
}

.be .be-breadcrumb .btn-plain:last-child {
    color: #222
}

.be-breadcrumb-seperator {
    flex-shrink: 0;
    margin: 1px 8px 0 5px
}

.bdl-Breadcrumb-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.be .btn-plain.be-breadcrumbs-drop-down {
    color: #767676;
    letter-spacing: 1.5px
}

.be .btn-plain.be-breadcrumbs-drop-down.be-breadcrumbs-condensed {
    letter-spacing: inherit
}

.be-breadcrumbs {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 20px
}

.be-breadcrumb-more {
    display: flex;
    align-items: center;
    min-width: 35px
}

.be-inline-breadcrumbs {
    display: flex;
    min-width: 0;
    color: #767676;
    font-size: 11px
}

.be-inline-breadcrumbs .be-breadcrumbs {
    padding-right: 0
}

.be-inline-breadcrumbs .be-breadcrumb:first-child {
    min-width: 43px
}

.be .be-inline-breadcrumbs .be-breadcrumb .btn-plain {
    color: inherit;
    font-size: 11px
}

.be-inline-breadcrumbs .be-breadcrumb-more {
    min-width: 16px
}

.ex-btn.be-btn-sort {
    padding: 7px 6px
}

.bce:not(.be-is-small) .ex-btn.be-btn-sort {
    display: none
}

.ex-btn.be-btn-sort svg {
    display: block
}

.be-sort-selected {
    display: flex;
    flex: 0 0 26px
}

.be-menu-sort.aria-menu .menu-item {
    padding-right: 45px
}

.ex-btn.be-btn-add {
    margin-left: 7px;
    padding: 5px 6px
}

.ex-btn.be-btn-add svg {
    display: block
}

.bdl-GridViewSlider {
    display: flex;
    margin-right: 5px
}

.bdl-GridViewSlider-range {
    margin-right: 7px;
    margin-left: 7px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.bdl-GridViewSlider-range::-webkit-slider-runnable-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px
}

.bdl-GridViewSlider-range::-webkit-slider-thumb {
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%;
    width: 10px;
    margin-top: -4px;
    -webkit-appearance: none;
    appearance: none
}

.bdl-GridViewSlider-range::-moz-range-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px
}

.bdl-GridViewSlider-range::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%
}

.bdl-GridViewSlider-range::-ms-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px;
    color: transparent
}

.bdl-GridViewSlider-range::-ms-fill-lower, .bdl-GridViewSlider-range::-ms-fill-upper {
    background: #e8e8e8;
    border-radius: 10px
}

.bdl-GridViewSlider-range::-ms-thumb {
    width: 10px;
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%
}

.bdl-GridViewSlider-range:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px
}

.bdl-GridViewSlider-range:focus {
    outline: none
}

.bdl-GridViewSlider-range:focus::-ms-fill-lower {
    background: #909090
}

.bdl-GridViewSlider-range:focus::-ms-fill-upper {
    background: #d3d3d3
}

.bdl-GridViewSlider-button {
    display: flex;
    align-items: center;
    justify-content: center
}

.bdl-ViewModeChangeButton {
    padding: 5px 6px
}

.bdl-ViewModeChangeButton svg {
    display: block
}

.be-sub-header-right {
    display: flex;
    align-items: center
}

.be-sub-header-right .bdl-ViewModeChangeButton, .be-sub-header-right .be-btn-sort {
    margin-left: 7px
}

.be-sub-header {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 25px;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: 0 4px 6px -2px rgba(51, 51, 51, .04)
}

.be-is-small .be-sub-header {
    padding: 0 15px 0 18px
}

.bce:not(.be-is-small) .be-sub-header {
    box-shadow: none
}

.bce.be-is-small .be-sub-header {
    border-bottom: 0
}

.bce-footer {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    justify-content: flex-end;
    max-height: 70px;
    padding: 0 20px;
    background-color: #fcfcfc;
    border-top: 1px solid #e8e8e8
}

.bce-footer:empty {
    display: none;
    flex-basis: 0
}

.bce-footer .bdl-ButtonGroup, .bce-footer .ex-btn-group {
    margin-right: 5px;
    margin-left: 5px
}

.error-mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    overflow: hidden;
    border: 1px dashed #909090;
    border-radius: 3px
}

.error-mask .error-mask-sad-cloud {
    margin-bottom: 20px
}

.error-mask h4 {
    margin-top: -10px
}

.error-mask h4, .error-mask h5 {
    width: 100%;
    margin-bottom: 0;
    color: #767676;
    text-align: center
}

.be .be-default-error {
    margin: 8px
}

.notifications-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 180;
    height: 0
}

.notification {
    display: flex;
    align-items: center;
    max-width: 572px;
    min-height: 48px;
    margin: 10px auto;
    padding: 10px 10px 10px 20px;
    overflow: hidden;
    color: #222;
    font-weight: 700;
    background-color: #a7a7a7;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
    opacity: .9;
    transition: opacity .1s ease-out
}

.notification.info {
    background-color: #92e0c0
}

.notification.warn {
    background-color: #fad98d
}

.notification.error {
    background-color: #f69bab
}

.notification.is-hidden {
    box-shadow: none;
    opacity: 0
}

.notification > span {
    margin-right: auto;
    padding-right: 10px;
    font-size: 15px
}

.notification > svg {
    flex: none;
    margin-right: 15px
}

.notification.wrap > span {
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word
}

.notification.ellipsis > span {
    overflow: hidden;
    text-overflow: ellipsis
}

.notification a, .notification button {
    flex: none;
    color: #222;
    border-color: #222
}

.notification a.ex-btn.is-disabled, .notification a.ex-btn:not(.is-disabled), .notification button.ex-btn.is-disabled, .notification button.ex-btn:not(.is-disabled) {
    margin: 0 5px;
    padding: 7px 13px;
    background-color: transparent;
    border-color: inherit
}

.notification a.close-btn, .notification button.close-btn {
    display: flex;
    padding: 2px 7px;
    font-weight: 700;
    font-size: 14px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: .6
}

.bcpr .bcpr-notification {
    position: absolute;
    width: 100%
}

.bcpr .bcpr-notification .notification > svg {
    display: none
}

.bcpr-FileInfo {
    display: flex;
    align-items: center
}

.bcpr-FileInfo-name {
    padding-left: 5px;
    font-weight: 700
}

.bcpr-PreviewHeader {
    display: flex;
    flex: 0 0 60px;
    padding: 0;
    font-size: 13px
}

.bcpr-PreviewHeader .bcpr-PreviewHeader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background-color: #fbfbfb;
    border-bottom: 1px solid #e8e8e8
}

.bcpr-PreviewHeader .bp-header {
    flex: 1;
    height: 60px
}

.bcpr-PreviewHeader button.ba-btn-annotate-draw-cancel, .bcpr-PreviewHeader button.ba-btn-annotate-point-exit {
    color: #e8e8e8;
    background-color: #222;
    border: 1px solid #e8e8e8
}

.bcpr-PreviewHeader button.ba-btn-annotate-draw-cancel:hover, .bcpr-PreviewHeader button.ba-btn-annotate-point-exit:hover {
    background-color: #000
}

.bcpr-PreviewHeader button.ba-btn-annotate-draw-post {
    color: #222;
    background-color: #e8e8e8
}

.bcpr-PreviewHeader button.ba-btn-annotate-draw-post:hover {
    background-color: #fff
}

.bcpr-PreviewHeader .ba-draw-post-cancel-container, .bcpr-PreviewHeader .ba-point-post-cancel-container {
    position: absolute;
    top: 6px;
    right: 0;
    margin-right: 20px
}

.bcpr-PreviewHeader .ba-draw-post-cancel-container button, .bcpr-PreviewHeader .ba-point-post-cancel-container button {
    height: 32px;
    margin: 8px
}

.bcpr-PreviewHeader .ba-draw-undo-redo-container {
    margin: 0 auto
}

.bcpr-PreviewHeader .ba-draw-undo-redo-container .ba-btn-annotate-draw-redo, .bcpr-PreviewHeader .ba-draw-undo-redo-container .ba-btn-annotate-draw-undo, .bcpr-PreviewHeader .ba-draw-undo-redo-container .bdl-is-disabled.ba-btn-annotate-draw-redo, .bcpr-PreviewHeader .ba-draw-undo-redo-container .bdl-is-disabled.ba-btn-annotate-draw-undo, .bcpr-PreviewHeader .ba-draw-undo-redo-container .is-disabled.ba-btn-annotate-draw-redo, .bcpr-PreviewHeader .ba-draw-undo-redo-container .is-disabled.ba-btn-annotate-draw-undo, .bcpr-PreviewHeader .ba-draw-undo-redo-container button.ba-btn-annotate-draw-redo, .bcpr-PreviewHeader .ba-draw-undo-redo-container button.ba-btn-annotate-draw-undo {
    margin: 5px;
    background: none;
    border: none
}

.bcpr-PreviewHeader .ba-draw-undo-redo-container .ba-btn-annotate-draw-redo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container .ba-btn-annotate-draw-undo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container .bdl-is-disabled.ba-btn-annotate-draw-redo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container .bdl-is-disabled.ba-btn-annotate-draw-undo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container .is-disabled.ba-btn-annotate-draw-redo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container .is-disabled.ba-btn-annotate-draw-undo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container button.ba-btn-annotate-draw-redo svg, .bcpr-PreviewHeader .ba-draw-undo-redo-container button.ba-btn-annotate-draw-undo svg {
    vertical-align: middle;
    fill: #e8e8e8
}

.bcpr-PreviewHeader--basic .bcpr-PreviewHeader-content {
    color: #fbfbfb;
    background: #222;
    border-bottom: 1px solid #222
}

.bcpr-PreviewHeader-controls {
    display: flex
}

.be .bcpr-PreviewHeader-controls .bcpr-bcow-btn {
    margin: 0 10px 0 0;
    padding: 0 5px
}

.be .bcpr-PreviewHeader-controls .bcpr-bcow-btn:active, .be .bcpr-PreviewHeader-controls .bcpr-bcow-btn:focus, .be .bcpr-PreviewHeader-controls .bcpr-bcow-btn:hover {
    background: none
}

.bcpr-PreviewHeader-button.btn-plain {
    display: flex;
    align-items: center;
    color: #fbfbfb
}

.bcpr-PreviewHeader-button.btn-plain, .bcpr-PreviewHeader-button.btn-plain:active, .bcpr-PreviewHeader-button.btn-plain:hover {
    padding: 5px 10px
}

.bcpr-PreviewHeader-button.btn-plain:focus, .bcpr-PreviewHeader-button.btn-plain:hover {
    color: #e8e8e8
}

.bcpr-PreviewHeader-button.btn-plain:focus .fill-color, .bcpr-PreviewHeader-button.btn-plain:hover .fill-color {
    fill: #767676
}

.bcpr-PreviewHeader-button-close.btn-plain {
    font-weight: 700
}

.bcpr-PreviewHeader-button-close.btn-plain .icon-close {
    margin: -3px
}

.bcow-tooltip a, .bcow-tooltip a:active, .bcow-tooltip a:focus, .bcow-tooltip a:hover, .bcow-tooltip a:visited {
    color: #fff;
    text-decoration: underline
}

.bcow-menu-item-description a, .bcow-menu-item-description a:active, .bcow-menu-item-description a:focus, .bcow-menu-item-description a:hover, .bcow-menu-item-description a:visited {
    color: #222;
    text-decoration: underline
}

.menu-item.bcow-box-tools-uninstalled {
    opacity: 1
}

.menu-item.bcow-box-tools-uninstalled .bcow-menu-item-title, .menu-item.bcow-box-tools-uninstalled svg {
    opacity: .4
}

.be.bcow {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif
}

.be.bcow .ex-btn {
    padding: 3px 13px 3px 10px
}

.be.bcow .ex-btn-content {
    display: flex;
    align-items: center
}

.be.bcow .bcow-integration-icon {
    padding: 3px
}

.bcow-menu .bcow-menu-item-title {
    margin: 0;
    overflow: hidden;
    color: #222;
    font-weight: 700;
    text-overflow: ellipsis
}

.bcow-menu .bcow-menu-item-description {
    max-width: 200px;
    margin: 0;
    color: #767676;
    font-size: 11px;
    line-height: 14px;
    white-space: normal;
    word-wrap: break-word
}

.bcow-portal-container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh
}

.bcow-portal-container .bcow-portal-loading-indicator div {
    background-color: #222
}

.bcow-portal-container .error-mask {
    max-width: 650px;
    margin: 0 auto;
    font-size: 16px
}

.bcpr-PreviewError {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #222;
    text-align: center
}

.bcpr-PreviewError-message {
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px
}

@keyframes bdl-PreviewLoadingRing-spin {
    to {
        transform: rotate(1turn)
    }
}

.bdl-PreviewLoadingRing {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%
}

.bdl-PreviewLoadingRing.bdl-PreviewLoadingRing--dark .bdl-PreviewLoadingRing-border:before {
    background: linear-gradient(0deg, #000 50%, rgba(0, 0, 0, .9)) 0 0 no-repeat, linear-gradient(90deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .6)) 100% 0 no-repeat, linear-gradient(180deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .3)) 100% 100% no-repeat, linear-gradient(1turn, rgba(0, 0, 0, .3), transparent) 0 100% no-repeat;
    background-size: 50% 50%
}

.bdl-PreviewLoadingRing.bdl-PreviewLoadingRing--dark .bdl-PreviewLoadingRing-content {
    background-color: #000
}

.bdl-PreviewLoadingRing.bdl-PreviewLoadingRing--light .bdl-PreviewLoadingRing-border:before {
    background: linear-gradient(0deg, #fff 50%, hsla(0, 0%, 100%, .9)) 0 0 no-repeat, linear-gradient(90deg, hsla(0, 0%, 100%, .9), hsla(0, 0%, 100%, .6)) 100% 0 no-repeat, linear-gradient(180deg, hsla(0, 0%, 100%, .6), hsla(0, 0%, 100%, .3)) 100% 100% no-repeat, linear-gradient(1turn, hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0)) 0 100% no-repeat;
    background-size: 50% 50%
}

.bdl-PreviewLoadingRing.bdl-PreviewLoadingRing--light .bdl-PreviewLoadingRing-content {
    background-color: #fff
}

.bdl-PreviewLoadingRing-border, .bdl-PreviewLoadingRing-content {
    position: absolute;
    border-radius: 50%
}

.bdl-PreviewLoadingRing-border {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0061d5;
    animation: bdl-PreviewLoadingRing-spin 1s linear infinite
}

.bdl-PreviewLoadingRing-border:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 50%;
    content: ""
}

.bdl-PreviewLoadingRing-content {
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center
}

@keyframes bdl-PreviewLoading-pulse {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(.95)
    }
}

.bdl-PreviewLoading-icon {
    width: 50%;
    height: 50%;
    transform: scale(1);
    animation: bdl-PreviewLoading-pulse .5s ease-in-out infinite alternate both
}

.bcpr-PreviewMask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.bcpr {
    display: flex;
    flex: 1;
    flex-direction: column
}

.bcpr .bcpr-body {
    display: flex;
    flex: 1;
    min-height: 0
}

.bcpr .bcpr-container {
    position: relative;
    display: flex;
    flex: 1
}

.bcpr .bcpr-content {
    flex: 1
}

.bcpr .bp-navigate {
    display: none !important
}

.bcpr .bcpr-navigate-left, .bcpr .bcpr-navigate-right {
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 64px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .5s ease
}

.bcpr .bcpr-navigate-left:focus, .bcpr .bcpr-navigate-left:hover, .bcpr .bcpr-navigate-right:focus, .bcpr .bcpr-navigate-right:hover {
    opacity: 1
}

.bcpr .bcpr-navigate-right {
    right: 0
}

.bcpr .bcpr-navigate-left {
    left: 0;
    transition: opacity .5s ease, left .3s cubic-bezier(.4, 0, .2, 1)
}

.bcpr .bcpr-nav-is-visible .bcpr-navigate-left, .bcpr .bcpr-nav-is-visible .bcpr-navigate-right {
    opacity: 1
}

.bcpr.be-is-small .bcpr-body {
    position: relative
}

.bcpr.be-is-small .bcs.bcs-is-open {
    position: absolute;
    right: 0
}

.bcpr.bcpr-thumbnails-open .bcpr-navigate-left {
    left: 226px
}

.bdl-GuideTooltip {
    display: flex
}

.bdl-GuideTooltip.bdl-Tooltip {
    max-width: 408px;
    padding: 24px 48px 24px 24px
}

.bdl-GuideTooltip-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px
}

.bdl-GuideTooltip-icon {
    width: 32px;
    margin-right: 16px
}

.bdl-GuideTooltip-title {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5
}

.bdl-GuideTooltip-body {
    font-size: 14px;
    line-height: 1.57
}

.bdl-GuideTooltip-body, .bdl-GuideTooltip-title {
    letter-spacing: .3px
}

button.bdl-GuideTooltip-previousButton, button.bdl-GuideTooltip-previousButton:not(.is-disabled):active, button.bdl-GuideTooltip-previousButton:not(.is-disabled):hover {
    color: #fafbfd;
    background-color: #003c84
}

button.bdl-GuideTooltip-previousButton {
    border: 1px solid #fafbfd
}

button.bdl-GuideTooltip-previousButton:not(.is-disabled):hover {
    border: 1px solid #ccd8e6
}

button.bdl-GuideTooltip-previousButton:not(.is-disabled):active {
    border: 1px solid #7f9dc1
}

button.bdl-GuideTooltip-nextButton {
    color: #003c84;
    background-color: #fafbfd;
    border: 1px solid #fafbfd
}

button.bdl-GuideTooltip-nextButton:not(.is-disabled):hover {
    background-color: #e5ebf2;
    border: 1px solid #e5ebf2
}

button.bdl-GuideTooltip-nextButton:not(.is-disabled):active {
    background-color: #ccd8e6;
    border: 1px solid #ccd8e6
}

.bdl-GuideTooltip-navigation button:first-of-type {
    margin-left: 0
}

.bdl-AdditionalTabTooltip.tooltip {
    max-width: 250px
}

.bdl-AdditionalTab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 60px;
    background-color: transparent
}

.bdl-AdditionalTab:hover {
    background-color: #f4f4f4
}

.bdl-AdditionalTab.bdl-is-disabled {
    opacity: .5
}

.bdl-AdditionalTab.bdl-is-hidden {
    display: none
}

.bdl-AdditionalTab:hover svg path {
    fill: #4e4e4e
}

.bdl-AdditionalTab-icon {
    animation: fade-in .5s cubic-bezier(0, 0, .6, 1);
    width: 24px;
    height: 24px
}

@keyframes fade-in {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.bdl-AdditionalTabPlaceholder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 60px;
    background-color: transparent
}

.bdl-AdditionalTabPlaceholder:hover {
    background-color: #f4f4f4
}

.bdl-AdditionalTabPlaceholder.bdl-is-disabled {
    opacity: .5
}

.bdl-AdditionalTabPlaceholder.bdl-is-hidden {
    display: none
}

.bdl-AdditionalTabPlaceholder-icon {
    animation: fade-in .5s cubic-bezier(0, 0, .6, 1);
    width: 24px;
    height: 24px;
    background-color: #909090;
    border-radius: 5px
}

.bdl-AdditionalTabPlaceholder-icon--loading {
    animation: loading-pulse .5s ease-in-out infinite alternate, fade-in .5s cubic-bezier(0, 0, .6, 1)
}

@keyframes loading-pulse {
    0% {
        background-color: #f4f4f4
    }
    to {
        background-color: #e8e8e8
    }
}

.bdl-AdditionalTabPlaceholder-moreIcon {
    animation: fade-in .5s cubic-bezier(0, 0, .6, 1)
}

.bdl-AdditionalTabs {
    display: flex;
    flex-direction: column
}

.bdl-BackButton, .bdl-BackButton:focus, .bdl-BackButton:hover {
    display: flex
}

.bcs .bcs-NavButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 60px;
    background-color: transparent
}

.bcs .bcs-NavButton:hover {
    background-color: #f4f4f4
}

.bcs .bcs-NavButton.bdl-is-disabled {
    opacity: .5
}

.bcs .bcs-NavButton.bdl-is-hidden {
    display: none
}

.bcs .bcs-NavButton:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    display: block;
    width: 3px;
    content: "";
    pointer-events: none
}

.bcs .bcs-NavButton:hover svg .fill-color {
    fill: #4e4e4e
}

.bcs .bcs-NavButton.bcs-is-selected:before {
    background-color: #0061d5
}

.bcs .bcs-NavButton.bcs-is-selected svg .fill-color {
    fill: #0061d5
}

.bcs-SidebarNavSign {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 60px;
    background-color: transparent
}

.bcs-SidebarNavSign:hover {
    background-color: #f4f4f4
}

.bcs-SidebarNavSign.bdl-is-disabled {
    opacity: .5
}

.bcs-SidebarNavSign.bdl-is-hidden {
    display: none
}

.bcs-SidebarNavSign-icon {
    width: 24px;
    height: 24px
}

.bdl-SidebarToggleButton {
    margin: 0 3px;
    padding: 4px;
    border-radius: 4px
}

.bdl-SidebarToggleButton path {
    fill: #909090
}

.bdl-SidebarToggleButton:not(.bdl-is-disabled):hover, .bdl-SidebarToggleButton:not(.is-disabled):hover {
    background-color: #f4f4f4
}

.bdl-SidebarToggleButton:not(.bdl-is-disabled):focus, .bdl-SidebarToggleButton:not(.is-disabled):focus {
    border-color: #96a0a6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1)
}

.bdl-SidebarToggleButton.bdl-is-collapsed, .bdl-SidebarToggleButton.bdl-is-collapsed:hover {
    background-color: #0061d5
}

.bdl-SidebarToggleButton.bdl-is-collapsed:hover path, .bdl-SidebarToggleButton.bdl-is-collapsed path {
    fill: #fff
}

.bcs .bcs-SidebarNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #e8e8e8
}

.bcs .bcs-SidebarNav-tabs {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column
}

.bcs .bcs-SidebarNav-main, .bcs .bcs-SidebarNav-secondary {
    flex: 0 1 auto
}

.bcs .bcs-SidebarNav-overflow {
    display: flex;
    flex: 1 1 1px;
    flex-direction: column;
    overflow: hidden
}

.bcs .bcs-SidebarNav-overflow:after, .bcs .bcs-SidebarNav-overflow:before {
    display: block;
    flex: 0 0 1px;
    width: 44px;
    height: 1px;
    margin: 8px auto 0;
    background: #e8e8e8;
    content: ""
}

.bcs .bcs-SidebarNav-overflow .bdl-AdditionalTab {
    flex-shrink: 0
}

.bcs .bcs-SidebarNav-overflow .bdl-AdditionalTabs {
    flex: 1 1 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-x: hidden;
    overflow-y: auto
}

.bcs .bcs-SidebarNav-footer {
    display: flex;
    flex: 0 0 60px;
    align-items: center;
    justify-content: center
}

.bcs .bcs-SidebarNav-footer .btn-plain.bdl-SidebarToggleButton {
    height: 24px;
    margin: 0 3px;
    padding: 4px
}

.bcs-content {
    position: relative;
    width: 341px;
    margin-left: -1px;
    border-left: 1px solid #e8e8e8
}

.bcs-content .bcs-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    margin: 0 25px;
    padding: 0;
    border-bottom: 1px solid #e8e8e8
}

.bcs-content .bcs-content-header .bcs-title {
    display: flex;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px
}

.bcs-content .bcs-scroll-content-wrapper {
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto
}

.bcs-content .bcs-scroll-content {
    width: 323px
}

.bcs-section-content {
    padding: 15px 0 5px
}

.bcs-section {
    margin: 0 8px 20px 25px
}

.be .btn-plain.bcs-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 10px;
    color: initial;
    text-align: left;
    border-bottom: 1px solid #e8e8e8
}

.be .btn-plain.bcs-section-title .icon-caret-down {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform
}

.be .btn-plain.bcs-section-title:active, .be .btn-plain.bcs-section-title:focus {
    text-decoration: none;
    border-bottom-color: #0061d5
}

.be .btn-plain.bcs-section-title:active svg path, .be .btn-plain.bcs-section-title:focus svg path {
    fill: #222
}

.be .bcs-section-open .btn-plain.bcs-section-title .icon-caret-down {
    transform: rotate(180deg)
}

.be .bcs-sidebar-loading {
    padding-top: 5px
}

.be .bcs-loading-error {
    width: 340px
}

.be-transcript .be-transcript-buttons {
    margin-top: 5px;
    text-align: right
}

.be-transcript .be-transcript-buttons .ex-btn {
    margin: 0 0 0 5px
}

.be-transcript .be-transcript-text textarea {
    width: 100%;
    resize: none
}

.be-transcript .be-transcript-editing-row {
    background: #ecf4fc
}

.be-transcript .be-transcript-row, .be-transcript .btn-plain.be-transcript-row {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    overflow: visible;
    line-height: 20px;
    white-space: normal;
    text-align: left
}

.be-transcript .be-transcript-row:active, .be-transcript .be-transcript-row:focus, .be-transcript .be-transcript-row:hover, .be-transcript .btn-plain.be-transcript-row:active, .be-transcript .btn-plain.be-transcript-row:focus, .be-transcript .btn-plain.be-transcript-row:hover {
    text-decoration: none;
    background: #ecf4fc
}

.be-transcript .be-transcript-time {
    padding: 5px 20px 5px 5px
}

.be-transcript .be-transcript-text {
    flex: 1;
    padding: 5px 5px 5px 0
}

.be-transcript .be-transcript-content-collapsed .be-transcript-text {
    padding-right: 10px
}

.be-transcript {
    position: relative
}

.be-transcript .be-transcript-actions {
    position: absolute;
    top: -46px;
    right: 15px
}

.be-transcript .btn-plain.be-transcript-copy, .be-transcript .btn-plain.be-transcript-edit, .be-transcript .btn-plain.be-transcript-expand {
    width: 24px;
    height: 24px;
    margin-left: 7px;
    padding-top: 1px;
    border-radius: 3px
}

.be-transcript .btn-plain.be-transcript-copy:hover svg path, .be-transcript .btn-plain.be-transcript-edit:hover svg path, .be-transcript .btn-plain.be-transcript-expand:hover svg path {
    fill: #222
}

.be-transcript .btn-plain.be-transcript-copy.be-transcript-is-editing, .be-transcript .btn-plain.be-transcript-edit.be-transcript-is-editing, .be-transcript .btn-plain.be-transcript-expand.be-transcript-is-editing {
    background-color: #e8e8e8
}

.be-transcript .btn-plain.be-transcript-copy.be-transcript-is-editing .icon-edit > .fill-color, .be-transcript .btn-plain.be-transcript-edit.be-transcript-is-editing .icon-edit > .fill-color, .be-transcript .btn-plain.be-transcript-expand.be-transcript-is-editing .icon-edit > .fill-color {
    fill: #f4f4f4
}

.be-transcript .btn-plain.be-transcript-copy.be-transcript-copied {
    background-color: #e8e8e8;
    transition: background-color 1s
}

.be-transcript .btn-plain.be-transcript-copy.be-transcript-copied svg path {
    fill: #26c281
}

.be-transcript .be-transcript-edit-message {
    margin-bottom: 15px;
    color: #767676
}

.be-transcript .be-transcript-content-collapsed {
    max-height: 400px;
    overflow: auto
}

.bdl-LabelPill {
    color: #222;
    font-weight: 700
}

.bdl-LabelPill--sizeRegular {
    padding: 4px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 10px
}

.bdl-LabelPill--sizeLarge {
    padding: 4px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 20px
}

.bdl-LabelPill--default, .bdl-LabelPill--highlight, .bdl-LabelPill--info {
    background-color: #e8e8e8
}

.bdl-LabelPill--warning {
    background-color: #fad98d
}

.bdl-LabelPill--error {
    background-color: #f69bab
}

.bdl-LabelPill--success {
    background-color: #92e0c0
}

.bdl-LabelPill--alert {
    background-color: #f69bab
}

.bdl-LabelPill--ftux {
    background-color: #ccdff7
}

.bdl-LabelPill-iconContent {
    position: relative;
    top: 2px
}

.bdl-LabelPill-iconContent path {
    fill: currentColor
}

.bdl-LabelPill--sizeLarge .bdl-LabelPill-textContent {
    margin: 0 4px
}

.bdl-LabelPill--sizeLarge .bdl-LabelPill-iconContent {
    top: 1px
}

.bdl-LabelPill-textContent {
    margin: 0 4px
}

.avatar {
    position: relative;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.avatar .avatar-icon, .avatar .avatar-image, .avatar .avatar-initials {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .3s;
    transition-property: transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.avatar .avatar-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    line-height: 1
}

.avatar.avatar--isExternal .bdl-Avatar-externalBadge {
    width: 14px;
    height: 14px;
    border-width: 1px
}

.avatar.avatar--isExternal .badges .bottom-right-badge {
    bottom: -4px;
    left: calc(100% - 10px)
}

.avatar .avatar-initials[data-bg-idx="0"] {
    background-color: #2486fc
}

.avatar .avatar-initials[data-bg-idx="1"] {
    background-color: #0061d5
}

.avatar .avatar-initials[data-bg-idx="2"] {
    background-color: #003c84
}

.avatar .avatar-initials[data-bg-idx="3"] {
    background-color: #767676
}

.avatar .avatar-initials[data-bg-idx="4"] {
    background-color: #26c281
}

.avatar .avatar-initials[data-bg-idx="5"] {
    background-color: #f5b31b
}

.avatar .avatar-initials[data-bg-idx="6"] {
    background-color: #4826c2
}

.avatar .avatar-initials[data-bg-idx="7"] {
    background-color: #9f3fed
}

.avatar .avatar-initials[data-bg-idx="8"] {
    background-color: #ed3757
}

.avatar.avatar--small {
    width: 24px;
    height: 24px
}

.avatar.avatar--small .avatar-initials {
    font-size: 11px
}

.avatar.avatar--small.avatar--isExternal .bdl-Avatar-externalBadge {
    width: 16px;
    height: 16px;
    border-width: 2px
}

.avatar.avatar--small.avatar--isExternal .badges .bottom-right-badge {
    bottom: -4px;
    left: calc(100% - 10px)
}

.avatar.avatar--large {
    width: 44px;
    height: 44px
}

.avatar.avatar--large .avatar-initials {
    font-size: 14px
}

.bdl-Avatar-externalBadge {
    background-color: #fff;
    border-color: #fff;
    border-style: solid;
    border-radius: 50%
}

.bdl-RoundPill {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 28px;
    margin: 3px 2px;
    padding-top: 2px;
    padding-left: 2px;
    overflow: hidden;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #e8e8e8;
    border: 1px solid #e8e8e8;
    border-radius: 14px
}

.bdl-RoundPill .bdl-Avatar-externalBadge {
    border-color: #e8e8e8
}

.bdl-RoundPill.bdl-RoundPill--selected {
    font-weight: 700;
    background-color: #d3d3d3;
    border: 1px solid #fff
}

.bdl-RoundPill.bdl-RoundPill--selected .bdl-Avatar-externalBadge {
    border-color: #d3d3d3
}

.bdl-RoundPill-avatar path, .bdl-RoundPill-closeBtn path {
    fill: #909090
}

.bdl-RoundPill-text {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 8px !important;
    padding-top: 1px;
    overflow: hidden;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bdl-RoundPill-closeBtn {
    position: unset;
    top: unset;
    flex-grow: 0;
    flex-shrink: 0;
    height: 12px;
    margin-right: 8px;
    cursor: pointer
}

.bdl-RoundPill--warning {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 28px;
    margin: 3px 2px;
    padding-top: 2px;
    padding-left: 2px;
    overflow: hidden;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fef7e8;
    border: 1px solid #fad98d;
    border-radius: 14px
}

.bdl-RoundPill--warning .bdl-Avatar-externalBadge {
    border-color: #fef7e8
}

.bdl-RoundPill--warning.bdl-RoundPill--selected {
    font-weight: 700;
    background-color: #fdf0d1;
    border: 1px solid #f5b31b
}

.bdl-RoundPill--warning.bdl-RoundPill--selected .bdl-Avatar-externalBadge {
    border-color: #fdf0d1
}

.bdl-RoundPill--error {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 28px;
    margin: 3px 2px;
    padding-top: 2px;
    padding-left: 2px;
    overflow: hidden;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fdebee;
    border: 1px solid #f69bab;
    border-radius: 14px
}

.bdl-RoundPill--error .bdl-Avatar-externalBadge {
    border-color: #fdebee
}

.bdl-RoundPill--error.bdl-RoundPill--selected {
    font-weight: 700;
    background-color: #fbd7dd;
    border: 1px solid #ed3757
}

.bdl-RoundPill--error.bdl-RoundPill--selected .bdl-Avatar-externalBadge {
    border-color: #fbd7dd
}

.bdl-RoundPill--disabled {
    opacity: .5
}

.bdl-RoundPill--disabled .bdl-RoundPill-closeBtn {
    cursor: default
}

.pill-selector-suggested {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    color: #909090
}

.pill-selector-suggested > span {
    margin-left: 3px
}

.pill-selector-suggested .suggested-pill-invisible-button {
    padding: 0
}

.pill-selector-suggested .suggested-pill {
    margin: 0 3px;
    padding: 0;
    color: #909090;
    text-decoration: underline;
    transition: .1s ease
}

.pill-selector-suggested .suggested-pill:hover {
    cursor: pointer
}

.pill-selector-suggested .suggested-pill span {
    padding: 0 3px
}

.pill-selector-suggested .invisible-button:hover .suggested-pill {
    color: #4e4e4e
}

.be-keywords .be-keywords-buttons {
    margin: 7px -5px 0 0;
    text-align: right
}

.be-keywords .be-keywords-buttons .ex-btn {
    margin: 0 0 0 5px
}

.be-keywords .bdl-EditableKeywords {
    margin: 0
}

.be-keywords .bdl-EditableKeywords .bdl-PillSelector {
    width: 100%;
    margin: 0
}

.be-keywords .bdl-EditableKeywords .bdl-PillSelector input[type=text].bdl-PillSelector-input {
    min-width: 97%;
    height: 24px;
    margin: 2px 0 0 2px;
    padding: 0;
    border: 1px solid #fcfcfc;
    box-shadow: none
}

.be .btn-plain.be-timeline-time {
    position: absolute;
    height: 6px;
    background-color: #0061d5;
    border-radius: 6px;
    cursor: pointer;
    opacity: .6
}

.be .btn-plain.be-timeline-time:active, .be .btn-plain.be-timeline-time:focus, .be .btn-plain.be-timeline-time:hover {
    opacity: 1
}

.be-timeline {
    display: flex;
    flex-direction: column;
    margin: 15px 0 0;
    color: #767676;
    font-size: 12px
}

.be-timeline:last-child {
    padding-bottom: 5px
}

.be-timeline .be-timeline-label {
    padding-bottom: 6px
}

.be-timeline .be-timeline-line-wrapper {
    position: relative;
    flex: 1;
    height: 6px
}

.be-timeline .be-timeline-line {
    position: absolute;
    width: 290px;
    height: 6px;
    background-color: #0061d5;
    border-radius: 6px;
    opacity: .25
}

.be-timeline .be-timeline-btns {
    margin-top: 10px
}

.be-timeline .be-timeline-btns button:last-child {
    margin-left: 10px
}

.be-timeline .be-timeline-btns .btn-plain:hover svg path {
    fill: #222
}

.be-keywords .bdl-PillCloud, .be-keywords .pill-cloud-container {
    padding: 0;
    overflow: hidden;
    border: 0
}

.be-keywords .bdl-PillCloud .ex-btn.pill.pill-cloud-button, .be-keywords .pill-cloud-container .ex-btn.pill.pill-cloud-button {
    margin: 0 5px 5px 0;
    color: inherit;
    border: 1px solid #d3d3d3
}

.be-keywords .bdl-PillCloud .ex-btn.pill.pill-cloud-button.is-selected, .be-keywords .bdl-PillCloud .ex-btn.pill.pill-cloud-button:hover, .be-keywords .pill-cloud-container .ex-btn.pill.pill-cloud-button.is-selected, .be-keywords .pill-cloud-container .ex-btn.pill.pill-cloud-button:hover {
    color: #fff;
    background-color: #0061d5;
    border-color: #0061d5
}

.be-keywords {
    position: relative
}

.be-keywords .btn-plain.be-keyword-edit {
    position: absolute;
    top: -46px;
    right: 15px;
    width: 24px;
    height: 24px;
    padding-top: 1px;
    border-radius: 3px
}

.be-keywords .btn-plain.be-keyword-edit:hover svg path {
    fill: #222
}

.be-keywords .btn-plain.be-keyword-edit.be-keyword-is-editing {
    background-color: #e8e8e8
}

.be-keywords .btn-plain.be-keyword-edit.be-keyword-is-editing .icon-edit > .fill-color {
    fill: #f4f4f4
}

.be-faces .be-face-wrapper {
    position: relative;
    display: inline-block;
    margin: 0 10px 10px 0
}

.be-faces .btn-plain.be-face {
    overflow: hidden;
    border-radius: 100%
}

.be-faces .btn-plain.be-face img {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .3s;
    transition-property: transform;
    width: 45px;
    height: 45px;
    object-fit: cover;
    vertical-align: middle;
    filter: brightness(1.5)
}

.be-faces .btn-plain.be-face .icon-minus {
    position: absolute;
    top: 16px;
    left: 16px;
    display: none
}

.be-faces .btn-plain.be-face:hover img {
    transform: scale(1.3)
}

.be-faces .btn-plain.be-face:hover .icon-minus {
    display: initial
}

.be-faces .be-face-unselected .btn-plain.be-face img {
    opacity: .4;
    filter: none
}

.be-faces .btn-plain.be-face-delete {
    position: absolute;
    top: -3px;
    right: -5px;
    height: 18px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #909090;
    border-radius: 100%;
    box-shadow: 0 0 2px 0 rgba(51, 51, 51, .4)
}

.be-faces.be-faces-is-editing .btn-plain.be-face:hover img {
    transform: none;
    cursor: default
}

.be-faces {
    position: relative
}

.be-faces .inline-alert {
    margin: 0 0 20px
}

.be-faces .btn-plain.be-face-edit {
    position: absolute;
    top: -46px;
    right: 15px;
    width: 24px;
    height: 24px;
    padding-top: 1px;
    border-radius: 3px
}

.be-faces .btn-plain.be-face-edit:hover svg path {
    fill: #222
}

.be-faces .btn-plain.be-face-edit.be-faces-is-editing {
    background-color: #e8e8e8
}

.be-faces .btn-plain.be-face-edit.be-faces-is-editing .icon-edit > .fill-color {
    fill: #f4f4f4
}

.be-faces .be-faces-buttons {
    margin-right: -5px;
    padding-top: 7px;
    text-align: right
}

.bcs-skills .bcs-scroll-content {
    padding-top: 15px
}

.access-stats-item {
    list-style: none
}

.access-stats-item .access-stats-item-content, .access-stats-item .access-stats-item-content.btn-plain, .access-stats-item .access-stats-item-content.btn-plain:focus, .access-stats-item .access-stats-item-content.btn-plain:hover {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 5px;
    padding: 9px;
    color: #003c84;
    line-height: 10px;
    text-align: left;
    background: #ecf4fc;
    border-radius: 2px
}

.access-stats-item .access-stats-item-content.btn-plain:focus .access-stats-label, .access-stats-item .access-stats-item-content.btn-plain:hover .access-stats-label {
    text-decoration: underline
}

.access-stats-item .access-stats-label {
    flex-grow: 1
}

.access-stats-item svg {
    margin-right: 8px
}

.access-stats .access-stats-view-details {
    margin-top: 10px
}

.access-stats .access-stats-view-details:hover {
    text-decoration: underline
}

.access-stats .access-stats-header {
    margin: 0 0 10px;
    color: #a7a7a7
}

.access-stats .access-stats-list {
    margin: 0
}

.bdl-Label, .label {
    display: block;
    color: #767676;
    font-weight: 700
}

.bdl-Label .bdl-Label-optional, .bdl-Label .label-optional, .bdl-Label .tooltip-icon-container, .label .bdl-Label-optional, .label .label-optional, .label .tooltip-icon-container {
    padding-left: 3px
}

.bdl-Label .tooltip-icon-container .tooltip-icon, .label .tooltip-icon-container .tooltip-icon {
    position: relative;
    top: 3px
}

.bdl-SecurityBadge, .bdl-SecurityBadge:hover {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 2px 8px;
    border-radius: 10px;
    background-color: #fef7e8
}

.bdl-SecurityBadge .icon-alert-default {
    width: 16px;
    height: 16px;
    margin: 0 -3px
}

.bdl-SecurityBadge-name {
    margin-left: 4px;
    font-weight: 700;
    font-size: 10px !important;
    line-height: 16px;
    color: #222 !important;
    text-transform: uppercase
}

.bdl-ClassifiedBadge-editButton, .bdl-ClassifiedBadge-editButton:hover {
    cursor: pointer
}

.bdl-SecurityControlsItem {
    color: #4e4e4e;
    font-size: 12.5px;
    font-style: italic
}

.bdl-SecurityControlsItem:before {
    display: inline-block;
    width: 1em;
    color: #bcbcbc;
    font-weight: 700;
    font-style: normal;
    content: "\2022"
}

.bdl-SecurityControlsItem-tooltipContent p:last-child {
    margin-bottom: 0
}

.bdl-SecurityControlsItem-tooltip {
    max-width: 270px
}

.bdl-SecurityControlsItem-tooltipIcon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 3px;
    vertical-align: middle;
    cursor: pointer
}

@keyframes popup_bounce_in {
    0% {
        transform: scale3d(.1, .1, 1)
    }
    to {
        transform: scaleX(1)
    }
}

.bdl-SecurityControlsModal .bdl-Label, .bdl-SecurityControlsModal .label {
    margin-top: 19px
}

.bdl-SecurityControlsModal-controlsItemList, .bdl-SecurityControlsModal-definition {
    overflow-y: auto;
    line-height: 20px
}

.bdl-SecurityControlsModal-definition {
    max-height: 60px
}

.bdl-SecurityControlsModal-controlsItemList {
    max-height: 240px;
    margin: 0;
    list-style: none
}

.bdl-SecurityControls {
    margin: 0;
    list-style: none
}

.bdl-Classification .bdl-Label, .bdl-Classification .label {
    margin-top: 12px;
    font-weight: 400
}

.bdl-Classification-definition {
    max-height: 100px;
    margin-top: 3px;
    overflow-y: auto;
    line-height: 20px
}

.bdl-Classification-missingMessage {
    color: #909090
}

.collapsible-card {
    border-radius: 4px
}

.collapsible-card .collapsible-card-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 4px
}

.collapsible-card .collapsible-card-header.has-sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1
}

.collapsible-card .collapsible-card-header .bdl-Collapsible-actionItems {
    display: none
}

.collapsible-card .collapsible-card-header:hover .btn-plain.collapsible-card-title {
    text-decoration: underline
}

.collapsible-card .collapsible-card-header:hover .bdl-Collapsible-actionItems {
    display: block
}

.collapsible-card .collapsible-card-action-items {
    position: absolute;
    right: 34px;
    bottom: 0
}

.collapsible-card .collapsible-card-header-caret {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform;
    position: absolute;
    right: 0
}

.collapsible-card .btn-plain.collapsible-card-title {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    text-align: left
}

.collapsible-card .btn-plain.collapsible-card-title:focus {
    text-decoration: underline
}

.collapsible-card .btn-plain.collapsible-card-title:focus + .bdl-Collapsible-actionItems {
    display: block
}

.collapsible-card .collapsible-card-content {
    padding: 10px 0
}

.collapsible-card.is-open .collapsible-card-header-caret {
    transform: rotate(180deg)
}

.collapsible-card.is-open .collapsible-card-header:hover .btn-plain.collapsible-card-title {
    text-decoration: none
}

.collapsible-card.is-open .collapsible-card-header .bdl-Collapsible-actionItems {
    display: block
}

.collapsible-card.is-open .btn-plain.collapsible-card-title:focus {
    text-decoration: none
}

.collapsible-card.is-open .btn-plain.collapsible-card-title + .bdl-Collapsible-actionItems {
    display: block
}

.collapsible-card.is-bordered {
    border: 1px solid #e8e8e8
}

.collapsible-card.is-bordered .collapsible-card-header {
    background-color: #fff
}

.collapsible-card.is-bordered .btn-plain.collapsible-card-title, .collapsible-card.is-bordered .collapsible-card-content {
    padding: 10px
}

.collapsible-card.is-bordered.is-open .btn-plain.collapsible-card-title {
    border-bottom: 1px solid #e8e8e8
}

.collapsible-card.is-bordered .collapsible-card-header-caret {
    right: 10px
}

.collapsible-card:not(.is-bordered) .bdl-PlainButton.collapsible-card-title, .collapsible-card:not(.is-bordered) .btn-plain.collapsible-card-title {
    border-bottom: 1px solid #e8e8e8
}

.collapsible-card:not(.is-bordered) .bdl-PlainButton.collapsible-card-title:active, .collapsible-card:not(.is-bordered) .bdl-PlainButton.collapsible-card-title:focus, .collapsible-card:not(.is-bordered) .bdl-PlainButton.collapsible-card-title:hover, .collapsible-card:not(.is-bordered) .btn-plain.collapsible-card-title:active, .collapsible-card:not(.is-bordered) .btn-plain.collapsible-card-title:focus, .collapsible-card:not(.is-bordered) .btn-plain.collapsible-card-title:hover {
    text-decoration: none;
    border-bottom-color: #0061d5
}

.bcs-SidebarClassification {
    margin: 0 8px 20px 25px
}

.bcs-SidebarClassification:empty {
    margin: 0
}

.bcs-SidebarClassification-edit {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #0061d5
}

.text-input-container {
    position: relative;
    margin: 0 0 20px
}

.text-input-container .text-input-loading {
    display: inline-block;
    margin-left: -21px
}

.text-input-container .text-input-verified {
    display: inline-block;
    margin-bottom: -2px;
    margin-left: -24px
}

.text-input-container .bdl-Label, .text-input-container .label {
    word-wrap: break-word
}

.text-input-container .text-input-description {
    color: #767676;
    word-wrap: break-word
}

.text-input-container input {
    height: 32px;
    margin-top: 5px;
    font-family: inherit
}

.text-input-container input[type=number] {
    -moz-appearance: textfield;
    -ms-appearance: textfield
}

.text-input-container input[type=number]::-webkit-inner-spin-button, .text-input-container input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.text-input-container.is-required input, .text-input-container.show-error input {
    border-color: #ed3757
}

.item-properties {
    margin: 0
}

.item-properties dt {
    color: #767676
}

.item-properties dd {
    margin-bottom: 20px;
    line-height: 16px
}

.item-properties textarea.description-textarea {
    width: 100%;
    min-height: 34px;
    max-height: 110px;
    margin-left: -1px;
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    transition: background-color .2s, border-color .2s, margin-left .2s, padding .2s;
    resize: none
}

.item-properties textarea.description-textarea:hover {
    box-shadow: none
}

.item-properties textarea.description-textarea:focus {
    max-height: none;
    margin-left: 0;
    padding: 7px;
    background-color: #fbfbfb;
    border-color: #e8e8e8
}

.item-properties .url-input input {
    width: 100%;
    margin: 0
}

.item-properties .url-input:not(.show-error) input {
    height: 16px;
    padding: 0;
    border: none;
    box-shadow: none;
    transition: border .2s, height .2s, padding .2s
}

.item-properties .url-input:not(.show-error) input:focus {
    height: 32px;
    padding: 7px;
    border: 1px solid #0061d5
}

.item-properties .bdl-RetentionLink {
    margin-left: 4px
}

.item-properties button {
    display: block
}

.be-date-capitalize {
    text-transform: capitalize
}

.bcs-SidebarVersions:not(:empty) {
    margin-bottom: 20px
}

.bcs-details .bcs-scroll-content {
    padding-top: 15px
}

.bcs-details .item-properties dd {
    margin-left: 0
}

.bcs-details .item-properties dd:last-child {
    margin-bottom: 0
}

.bcs-DetailsSidebar-notices {
    margin: 0 8px 20px 25px
}

.bcs-DetailsSidebar-notices:empty {
    margin: 0
}

.metadata-instance-editor-no-instances {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center
}

.metadata-instance-editor-no-instances svg {
    margin-left: -5px
}

.metadata-instance-editor-no-instances .icon-add-metadata-empty-state {
    height: 105px
}

.metadata-instance-editor-no-instances--call-out {
    margin: 20px 0 10px;
    font-weight: 700
}

.metadata-instance-editor-no-instances--how-add-template {
    width: 200px
}

.toggle-container {
    margin: 0 0 20px
}

.toggle-container .toggle-label {
    display: inline-block;
    margin: 5px 10px;
    line-height: 15px;
    vertical-align: top
}

.bdl-Toggle, .toggle {
    display: none
}

.bdl-Toggle, .bdl-Toggle *, .bdl-Toggle + .toggle-btn, .bdl-Toggle:after, .bdl-Toggle :after, .bdl-Toggle:before, .bdl-Toggle :before, .toggle, .toggle *, .toggle + .toggle-btn, .toggle:after, .toggle :after, .toggle:before, .toggle :before {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.bdl-Toggle + .toggle-btn::selection, .bdl-Toggle::selection, .bdl-Toggle ::selection, .bdl-Toggle:after::selection, .bdl-Toggle :after::selection, .bdl-Toggle:before::selection, .bdl-Toggle :before::selection, .toggle + .toggle-btn::selection, .toggle::selection, .toggle ::selection, .toggle:after::selection, .toggle :after::selection, .toggle:before::selection, .toggle :before::selection {
    background: none
}

.bdl-Toggle + .toggle-btn, .toggle + .toggle-btn {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin: 1px 0;
    padding: 2px;
    background: #bcbcbc;
    border-radius: 40px;
    outline: 0;
    cursor: pointer;
    transition: left .4s ease, background .4s ease
}

.bdl-Toggle + .toggle-btn:after, .bdl-Toggle + .toggle-btn:before, .toggle + .toggle-btn:after, .toggle + .toggle-btn:before {
    position: relative;
    display: block;
    width: 26px;
    content: ""
}

.bdl-Toggle + .toggle-btn:after, .toggle + .toggle-btn:after {
    top: -3px;
    left: -3px;
    height: 26px;
    background: #fff;
    border: 1px solid #767676;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    transition: left .2s ease, background .2s ease
}

.bdl-Toggle + .toggle-btn:before, .toggle + .toggle-btn:before {
    display: none
}

.bdl-Toggle:checked + .toggle-btn, .toggle:checked + .toggle-btn {
    background: #0061d5
}

.bdl-Toggle:checked + .toggle-btn:after, .toggle:checked + .toggle-btn:after {
    left: 50%
}

.toggle-simple {
    position: relative;
    display: flex;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    outline: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.is-toggle-right-aligned .toggle-simple {
    width: 100%
}

.toggle-simple-input {
    position: absolute;
    left: -9999px;
    opacity: 0
}

.toggle-simple-label {
    display: inline-block;
    min-width: 0;
    margin-left: 46px;
    white-space: nowrap
}

.is-toggle-right-aligned .toggle-simple-label {
    flex: 1;
    margin-left: auto;
    overflow: hidden;
    text-overflow: ellipsis
}

.toggle-simple-description {
    margin-top: 2px;
    margin-left: 46px;
    color: #909090
}

.is-toggle-right-aligned .toggle-simple-description {
    margin-right: 46px;
    margin-left: auto
}

.toggle-simple-switch {
    display: inline-block
}

@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .toggle-simple-switch {
        cursor: pointer
    }
}

.is-toggle-right-aligned .toggle-simple-switch {
    position: relative;
    width: 40px
}

.toggle-simple-switch:after, .toggle-simple-switch:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    cursor: pointer;
    content: ""
}

.toggle-simple-switch:before {
    right: 0;
    background-color: #bcbcbc;
    border-radius: 20px;
    transition: background .4s
}

.toggle-simple-switch:after {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #767676;
    border-radius: 100%;
    transition: margin .4s
}

.toggle-simple-input:checked ~ .toggle-simple-switch:before {
    background-color: #0061d5
}

.toggle-simple-input:checked ~ .toggle-simple-switch:after {
    margin-left: 20px
}

.toggle-simple-input:disabled ~ .toggle-simple-switch:after, .toggle-simple-input:disabled ~ .toggle-simple-switch:before {
    cursor: default
}

.toggle-simple-input:disabled ~ .toggle-simple-switch:before {
    opacity: .5
}

.toggle-simple-input:disabled ~ .toggle-simple-switch:after {
    background-color: #fafafa;
    border-color: #c8c8c8
}

.toggle-simple-input:focus ~ .toggle-simple-switch:after {
    border-color: #0061d5
}

.radio-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.radio-label > input[type=radio] {
    flex: none;
    width: 14px;
    height: 14px;
    margin: 0;
    opacity: 0
}

.radio-label > input[type=radio] + span:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 7px solid #fff;
    border-radius: 9001px;
    box-shadow: 0 0 0 1px #909090, 0 -1px 1px rgba(0, 0, 0, .4);
    transition: border-width .1s;
    content: ""
}

.radio-label > input[type=radio]:checked + span:before {
    background-color: #0061d5;
    border: 3px solid #fff
}

.radio-label > input[type=radio]:focus + span:before {
    box-shadow: 0 0 0 2px #0061d5
}

.radio-label > input[type=radio]:disabled + span:before {
    background-color: #bcbcbc;
    border-color: #fbfbfb;
    box-shadow: 0 0 0 1px #d3d3d3
}

.radio-label > input[type=radio] + span + span {
    margin-left: 10px
}

.radio-label > input[type=radio]:disabled + span + span {
    color: #bcbcbc
}

.radio-container {
    margin: 0 0 20px
}

.radio-description {
    margin-top: 2px;
    margin-left: 24px;
    color: #767676
}

.metadata-cascade-notice {
    margin: 10px 0;
    padding: 14px 15px;
    color: #222;
    background-color: #f1e2fd;
    border: none;
    border-radius: 4px
}

.metadata-cascade-editor {
    color: #222;
    background-color: #f4f4f4;
    border: none;
    border-radius: 4px
}

.metadata-cascade-editor hr {
    display: block;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid #e8e8e8
}

.metadata-cascade-editor .metadata-operation-not-immediate {
    color: #767676;
    font-weight: 700
}

.metadata-cascade-editor .metadata-operation-not-immediate svg {
    position: relative;
    top: 8px;
    margin-left: -5px
}

.metadata-cascade-enable {
    margin: 10px 0;
    padding: 14px 15px
}

.toggle-container.metadata-cascade-toggle {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px
}

.cascade-policy-text {
    clear: left
}

.cascade-policy-learnmore-link {
    color: #0061d5
}

.metadata-cascading-mode {
    margin: 10px 0;
    padding: 10px 15px 15px
}

.metadata-cascading-mode .metadata-cascading-options {
    padding: 10px
}

.metadata-cascading-mode .metadata-cascading-options .radio-container {
    margin: 0
}

.bdl-TextMetadataField.text-input-container input[type=text] {
    width: 100%
}

.bdl-SelectButton.is-invalid, .is-invalid.select-button {
    border-color: #ed3757
}

.datalist-item {
    padding: 8px 48px 8px 8px;
    border-radius: 8px;
    cursor: pointer
}

.datalist-item.is-active {
    background-color: rgba(34, 34, 34, .05)
}

.search-input-container {
    display: inline-block;
    min-height: 32px
}

.search-input-container .action-button {
    display: flex;
    align-items: center;
    width: 30px;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer
}

.search-input-container .action-button:focus, .search-input-container .action-button:hover {
    outline: none
}

.search-input-container .action-button path {
    transition: fill .1s linear
}

.search-input-container .action-button svg {
    flex: 1
}

.search-input-container .action-buttons, .search-input-container .search-form-loading-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex
}

.search-input-container .action-buttons {
    right: 0;
    align-items: stretch
}

.search-input-container .search-form-loading-indicator {
    right: 10px;
    align-items: center
}

.search-input-container .clear-button {
    display: none
}

.search-input-container .clear-button path:first-of-type {
    fill: #a7a7a7
}

.search-input-container .search-form.use-clear-button .clear-button {
    display: flex
}

.search-input-container .search-form.use-clear-button.is-empty .clear-button, .search-input-container .search-form.use-clear-button .search-button {
    display: none
}

.search-input-container .search-form.use-clear-button.is-empty .search-button {
    display: flex
}

.search-input-container .search-input {
    padding-right: 30px
}

.search-input-container .search-input:focus + .search-button path, .search-input-container .search-input:focus ~ .clear-button path:first-of-type {
    fill: #4e4e4e
}

.search-input-container .search-form {
    position: relative;
    display: flex;
    align-items: stretch
}

.bdl-SelectField .overlay, .select-field .overlay {
    min-width: 100%
}

.bdl-SelectField li[role=separator], .select-field li[role=separator] {
    margin: 10px 0;
    border-bottom: 1px solid #d3d3d3
}

.bdl-SelectField .select-field-search-container, .select-field .select-field-search-container {
    width: 100%;
    padding-right: 4px;
    padding-bottom: 8px;
    padding-left: 4px
}

.bdl-SelectField .select-field-search-container .search-input, .select-field .select-field-search-container .search-input {
    width: 100%
}

.select-option {
    display: flex;
    align-items: center;
    white-space: nowrap
}

.select-option.is-clear-option {
    color: #0061d5;
    font-weight: 700
}

.select-option.is-disabled {
    pointer-events: none
}

.select-option-check-icon {
    flex: none;
    width: 20px;
    height: 16px;
    margin-left: -5px;
    text-align: left
}

.bdl-SelectField-overlay--scrollable {
    max-height: 340px;
    overflow-y: auto
}

.bdl-SelectFieldDropdown {
    z-index: 4;
    min-width: 100%;
    overflow-x: hidden
}

.bdl-SelectField-optionText {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bdl-EnumMetadataField {
    margin: 0 0 20px
}

.bdl-EnumMetadataField .bdl-Label, .bdl-EnumMetadataField .label {
    word-wrap: break-word
}

.bdl-EnumMetadataField label .select-container {
    display: block
}

.bdl-EnumMetadataField label .select-container .datalist-item.select-option:first-child {
    color: #767676
}

.bdl-EnumMetadataField label .select-container .datalist-item.select-option:first-child .select-option-check-icon {
    visibility: hidden
}

.bdl-EnumMetadataField .bdl-EnumMetadataField-desc {
    display: inline-block;
    margin-bottom: 5px;
    color: #767676;
    word-wrap: break-word
}

.pika-single {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 13px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    position: relative;
    z-index: 9999;
    display: block;
    color: #4e4e4e;
    background: #fff;
    border: 1px solid #bcbcbc;
    border-bottom-color: #909090
}

.pika-single, .pika-single *, .pika-single :after, .pika-single :before {
    box-sizing: border-box !important
}

.pika-single.is-hidden {
    display: none
}

.pika-single.is-bound {
    position: absolute;
    margin-top: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1)
}

.pika-lendar {
    padding: 10px
}

.pika-title {
    position: relative;
    margin-bottom: 10px;
    text-align: center
}

.pika-title select {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 9998;
    margin: 0;
    cursor: pointer;
    opacity: 0
}

.pika-label {
    position: relative;
    z-index: 9999;
    display: inline-block;
    padding: 5px 15px 5px 5px;
    overflow: hidden;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff
}

.pika-label:after {
    position: absolute;
    top: 13px;
    right: 4px;
    border-top: 4px solid #4e4e4e;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: ""
}

.pika-button, .pika-next, .pika-prev {
    background: none;
    border: 0;
    outline: none
}

.pika-next, .pika-prev {
    display: block;
    width: 20px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: -999px;
    cursor: pointer
}

.pika-next.bdl-is-disabled, .pika-next.is-disabled, .pika-prev.bdl-is-disabled, .pika-prev.is-disabled {
    cursor: default
}

.pika-prev {
    float: left
}

.pika-prev:before {
    position: absolute;
    top: 10px;
    left: 10px;
    border-top: 4px solid transparent;
    border-right: 4px solid #4e4e4e;
    border-bottom: 4px solid transparent;
    content: ""
}

.pika-prev.bdl-is-disabled:before, .pika-prev.is-disabled:before {
    opacity: .2
}

.pika-next {
    float: right
}

.pika-next:after {
    position: absolute;
    top: 10px;
    right: 10px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #4e4e4e;
    border-radius: 2px;
    content: ""
}

.pika-next.bdl-is-disabled:after, .pika-next.is-disabled:after {
    opacity: .2
}

.pika-select {
    display: inline-block
}

.pika-table abbr {
    font-weight: 700
}

.pika-table th {
    width: 32px;
    color: #0061d5;
    font-size: 13px;
    text-align: center
}

.pika-table td, .pika-table th {
    width: 14.285714285714286%
}

.pika-table > thead > tr {
    height: 25px;
    vertical-align: top
}

.pika-table > tbody > tr {
    height: 33px
}

.pika-button {
    width: 30px;
    height: 30px;
    margin: 3px;
    font-size: 13px;
    text-align: center
}

.is-today .pika-button {
    font-weight: 700
}

.is-selected .pika-button, .pika-button:hover {
    color: #fff;
    font-weight: 700;
    background-color: #0061d5;
    border-radius: 3px;
    cursor: pointer
}

.bdl-is-disabled .pika-button, .is-disabled .pika-button, .is-outside-current-month .pika-button {
    cursor: default;
    opacity: .3;
    pointer-events: none
}

.date-picker-wrapper {
    position: relative;
    margin: 0 0 20px
}

.date-picker-wrapper button:focus {
    outline: none
}

.date-picker-wrapper .date-picker-icon-holder {
    position: relative;
    display: inline-block
}

.date-picker-wrapper .date-picker-description {
    color: #767676
}

.date-picker-wrapper .date-picker-input {
    margin-top: 5px;
    margin-bottom: 0;
    padding-right: 30px;
    text-overflow: ellipsis
}

.date-picker-wrapper .date-picker-input:focus ~ .bdl-Tooltip, .date-picker-wrapper .date-picker-input:focus ~ .tooltip, .date-picker-wrapper .date-picker-unix-time-input {
    display: none
}

.date-picker-wrapper .date-picker-open-btn {
    position: absolute;
    right: 7px;
    bottom: 10px;
    display: inline-block;
    height: 17px
}

.date-picker-wrapper .date-picker-clear-btn, .date-picker-wrapper .date-picker-icon-alert {
    position: absolute;
    right: 30px;
    bottom: 10px;
    display: inline-block
}

.date-picker-wrapper .date-picker-clear-btn span, .date-picker-wrapper .date-picker-icon-alert span {
    display: inline-block
}

.date-picker-wrapper .date-picker-clear-btn, .date-picker-wrapper .date-picker-clear-btn:active, .date-picker-wrapper .date-picker-clear-btn:active:focus, .date-picker-wrapper .date-picker-clear-btn:active:hover, .date-picker-wrapper .date-picker-clear-btn:focus, .date-picker-wrapper .date-picker-clear-btn:hover, .date-picker-wrapper .date-picker-clear-btn span {
    height: 14px
}

.date-picker-wrapper .date-picker-clear-btn path {
    transition: fill .1s linear;
    fill: #909090
}

.date-picker-wrapper > .date-picker-clear-btn:focus path, .date-picker-wrapper > .date-picker-clear-btn:hover path {
    fill: #4e4e4e;
    path: #4e4e4e
}

.date-picker-wrapper > .date-picker-open-btn:disabled {
    cursor: default
}

.date-picker-wrapper > .date-picker-open-btn:focus:enabled .fill-color, .date-picker-wrapper > .date-picker-open-btn:hover:enabled .fill-color {
    fill: #4e4e4e
}

.date-picker-wrapper .date-picker-icon-alert path {
    fill: #ed3757
}

.date-picker-wrapper.show-clear-btn.show-error .date-picker-clear-btn, .date-picker-wrapper.show-clear-btn.show-error .date-picker-clear-btn:active, .date-picker-wrapper.show-clear-btn.show-error .date-picker-clear-btn:hover {
    right: 25px
}

.date-picker-wrapper.show-clear-btn.show-error .date-picker-input {
    padding-right: 50px
}

.bdl-DateMetadataField .date-picker-icon-holder, .bdl-DateMetadataField .date-picker-icon-holder .date-picker-input {
    width: 100%
}

.bdl-MultiSelectMetadataField {
    margin: 0 0 20px
}

.bdl-MultiSelectMetadataField .label {
    word-wrap: break-word
}

.bdl-MultiSelectMetadataField label .select-container {
    display: block
}

.bdl-MultiSelectMetadataField label .select-container .select-button {
    z-index: inherit
}

.bdl-MultiSelectMetadataField-desc {
    display: inline-block;
    margin-bottom: 5px;
    color: #767676;
    word-wrap: break-word
}

.bdl-ReadOnlyMetadataField {
    margin: 0 0 20px;
    word-wrap: break-word
}

.bdl-ReadOnlyMetadataField:last-child {
    margin-bottom: 0
}

.bdl-CustomMetadataField:not(:last-child) .bdl-ReadOnlyMetadataField {
    margin-bottom: 20px
}

.bdl-ReadOnlyMetadataField dt {
    color: #767676
}

.bdl-ReadOnlyMetadataField dd {
    line-height: 16px
}

.bdl-ReadOnlyMetadataField .bdl-ReadOnlyMetadataField-desc {
    color: #767676
}

.metadata-instance-editor-instance .attributes-hidden-message, .metadata-instance-editor-instance .no-attributes-message {
    font-style: italic;
    text-align: center
}

.metadata-instance-editor-instance .bdl-SelectField .bdl-SelectFieldDropdown {
    max-width: 100%
}

.custom-new-field {
    padding: 10px 25px 20px;
    background-color: #e8e8e8;
    border-radius: 3px
}

.custom-new-field .text-input-container {
    flex: 1;
    margin: 10px 0
}

.custom-new-field .text-input-container input[type=text] {
    width: 100%
}

.custom-new-field .custom-new-field-header {
    display: flex;
    align-items: center
}

.custom-new-field .custom-new-field-header h5 {
    margin: 8px 5px 8px 0;
    font-size: 14px
}

.custom-new-field .custom-new-field-header > div {
    display: flex
}

.custom-new-field-actions {
    margin: 20px 0 0;
    text-align: right
}

.custom-new-field-actions .ex-btn {
    margin: 0 0 0 10px
}

.bdl-CustomMetadataField {
    display: flex;
    align-items: center
}

.bdl-CustomMetadataField .text-input-container {
    flex: 1;
    overflow-x: hidden
}

.bdl-CustomMetadataField .text-input-container .bdl-Label, .bdl-CustomMetadataField .text-input-container .label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bdl-CustomMetadataField:last-child .bdl-ReadOnlyMetadataField {
    margin-bottom: 0
}

.bdl-CustomMetadataField-customActions {
    margin: 5px 0 0 10px
}

.bdl-CustomMetadataField-customActions .ex-btn {
    width: 32px;
    height: 32px;
    margin: 0 0 0 -1px;
    padding: 10px
}

.bdl-CustomMetadataField-customActions .ex-btn span {
    display: flex
}

.metadata-instance-confirm-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: inherit
}

.metadata-instance-confim-container {
    max-width: 350px;
    text-align: center
}

.metadata-instance-editor-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px
}

.metadata-instance-editor-footer .metadata-instance-editor-footer-delete .btn-plain {
    color: #0061d5
}

.metadata-instance-editor-footer .metadata-instance-editor-footer-save-cancel .ex-btn:last-child {
    margin-right: 0
}

.metadata-instance-editor .collapsible-card-content {
    position: relative
}

.metadata-instance-editor-instance-title {
    display: flex;
    align-items: center
}

.metadata-instance-editor-instance-title-text {
    margin: 0 10px;
    color: #000
}

.metadata-instance-editor-instance-title-text.metadata-instance-editor-instance-has-error {
    color: #ed3757
}

.btn-plain.metadata-instance-editor-instance-edit {
    position: absolute;
    top: 8px;
    right: 25px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 3px
}

.btn-plain.metadata-instance-editor-instance-edit:hover svg path {
    fill: #222
}

.btn-plain.metadata-instance-editor-instance-edit.metadata-instance-editor-instance-is-editing {
    background-color: #e8e8e8
}

.scroll-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden
}

.scroll-container .scroll-wrap-container {
    flex-grow: 1;
    overflow-y: auto
}

.scroll-container .scroll-wrap-container:after, .scroll-container .scroll-wrap-container:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 30px;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .1s ease-in-out;
    content: ""
}

.scroll-container .scroll-wrap-container:before {
    top: -30px;
    z-index: 20;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1)
}

.scroll-container .scroll-wrap-container:after {
    bottom: -30px;
    z-index: 30;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, .1)
}

.scroll-container.is-showing-bottom-shadow .scroll-wrap-container:after, .scroll-container.is-showing-top-shadow .scroll-wrap-container:before {
    opacity: 1
}

.SelectorDropdown {
    position: relative;
    z-index: 3
}

.SelectorDropdown .overlay, .SelectorDropdown .overlay-wrapper {
    display: block;
    width: 100%
}

.SelectorDropdown .overlay-wrapper .SelectorDropdown-divider, .SelectorDropdown .overlay .SelectorDropdown-divider {
    display: block;
    width: calc(100% - 30px);
    height: 1px;
    margin: 4px 16px;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    pointer-events: none
}

.SelectorDropdown .overlay-wrapper .SelectorDropdown-title, .SelectorDropdown .overlay .SelectorDropdown-title {
    margin: 16px 16px 8px;
    color: #767676;
    font-weight: 700;
    font-size: 11px;
    line-height: 1;
    letter-spacing: .6px;
    text-transform: uppercase;
    pointer-events: none
}

.SelectorDropdown .text-input-container {
    margin-bottom: 5px
}

.SelectorDropdown .link-group ul {
    list-style: none
}

.SelectorDropdown .link {
    color: #4e4e4e !important
}

.SelectorDropdown .link.is-selected {
    background-color: rgba(34, 34, 34, .05)
}

.flyout-overlay {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 13px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    z-index: 190;
    box-sizing: border-box
}

.flyout-overlay > div:not(.should-outline-focus):focus {
    outline: none
}

.flyout-overlay .overlay {
    padding: 15px;
    border-radius: 4px
}

.flyout-overlay.dropdown-menu-element-attached-center .overlay, .flyout-overlay.flyout-overlay-target-attached-left .overlay, .flyout-overlay.flyout-overlay-target-attached-right .overlay {
    animation: fade-in .15s cubic-bezier(0, 0, .6, 1)
}

.metadata-instance-editor-template-dropdown-flyout, .metadata-instance-editor-template-dropdown-flyout *, .metadata-instance-editor-template-dropdown-flyout :after, .metadata-instance-editor-template-dropdown-flyout :before {
    box-sizing: border-box !important
}

.metadata-instance-editor-template-dropdown-flyout:before {
    border: none
}

.metadata-instance-editor-template-dropdown-flyout div.overlay {
    width: 288px;
    padding: 0;
    overflow: hidden;
    border: 1px solid #bcbcbc;
    border-radius: 4px
}

.metadata-instance-editor-template-dropdown-flyout ul.overlay {
    border: none
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu {
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .overlay-wrapper {
    overflow: hidden;
    border-top: none;
    border-radius: 0 0 4px 4px
}

@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .overlay-wrapper {
        max-height: 300px
    }
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .overlay-wrapper ul.overlay {
    transform: none;
    animation: none
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .overlay {
    position: static;
    padding: 0;
    border-radius: 0
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .overlay .datalist-item {
    padding: 0
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .scroll-container {
    max-height: 300px
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .scroll-wrap-container {
    flex-grow: 1;
    overflow-x: hidden
}

@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .scroll-wrap-container {
        height: 300px
    }
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .scroll-wrap-container:before {
    width: 110%;
    margin: 0 -5%;
    border-radius: inherit;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, .25)
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .scroll-wrap-container:after {
    width: 110%;
    border-radius: inherit;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, .15)
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-input-container {
    margin: 0;
    padding: 10px 12px 12px;
    border-bottom: 1px solid #bcbcbc;
    border-radius: 4px 4px 0 0
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-input-container .action-button .clear-button:focus {
    border: 1px solid #0061d5
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-input-container input[type=text] {
    padding: 7px;
    color: #222;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
    transition: border-color .15s linear, box-shadow .1s linear;
    -webkit-font-smoothing: antialiased;
    width: 100%
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-input-container input[type=text]:focus {
    border: 1px solid #0061d5;
    outline: 0;
    box-shadow: none
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-input-container input[type=text]:hover {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15)
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-dropdown-menu .search-button {
    pointer-events: none
}

.metadata-instance-editor-template-dropdown-flyout .btn-plain.metadata-template-dropdown-select-template {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 35px 5px 15px;
    overflow: hidden;
    color: inherit;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    text-align: inherit;
    text-overflow: ellipsis
}

.metadata-instance-editor-template-dropdown-flyout .btn-plain.metadata-template-dropdown-select-template.metadata-template-dropdown-is-selected {
    color: #0061d5
}

.metadata-instance-editor-template-dropdown-flyout .btn-plain.metadata-template-dropdown-select-template .template-display-name {
    flex: 10;
    overflow: hidden;
    text-overflow: ellipsis
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-message {
    position: absolute;
    right: 0;
    width: 288px;
    padding: 16px 15px;
    color: #909090;
    background-color: #fff;
    border: 1px solid #bcbcbc;
    border-top-color: transparent;
    border-radius: 0 0 4px 4px
}

.metadata-instance-editor-template-dropdown-flyout .metadata-instance-editor-template-message.template-dropdown-loading-indicator {
    display: flex;
    justify-content: center
}

.bcs-metadata .bcs-scroll-content > .inline-alert {
    margin: 0 10px 10px 25px
}

.bcs-metadata .bcs-scroll-content > .crawler {
    padding-top: 20px
}

.bcs-metadata .metadata-instance-editor {
    padding: 0 10px 20px 25px
}

.bcs-metadata .metadata-instance-editor .collapsible-card {
    margin-top: 10px
}

.bcs-metadata .metadata-instance-editor-template-dropdown {
    z-index: 1
}

.bcs-metadata .metadata-instance-editor-header {
    display: none
}

.bcs-metadata .metadata-instance-editor-field-read-only dd {
    margin: 0
}

.bcs-metadata .metadata-instance-editor-instance-title-text {
    max-width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bcs-metadata .collapsible-card.is-open .metadata-instance-editor-instance-title-text {
    max-width: 190px
}

.bcs-ActivityCard {
    padding: 20px 25px
}

.bcs-ActivityTimestamp {
    color: #767676;
    font-size: 12px;
    line-height: 20px;
    cursor: default
}

.bcs-DeleteConfirmation {
    width: 212px
}

.bcs-DeleteConfirmation .overlay {
    margin-top: 4px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
    padding: 20px
}

.bcs-DeleteConfirmation-promptMessage {
    margin-bottom: 20px
}

.bcs-DeleteConfirmation-cancel, .bcs-DeleteConfirmation-delete {
    margin-left: 0
}

.bcs-DeleteConfirmation-cancel {
    margin-right: 10px
}

.bdl-Media {
    display: flex;
    align-items: flex-start
}

.bdl-Media-figure {
    flex: 0 0 auto;
    align-self: flex-start;
    justify-self: flex-start;
    margin: 0 10px 0 0;
    padding: 0
}

.bdl-Media-body {
    flex: 1 1 100%;
    min-width: 0;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word
}

.bdl-Media-menu {
    float: right
}

.bdl-Media-menu, .bdl-Media-menu.btn-plain, .bdl-Media-menu.btn-plain:active, .bdl-Media-menu.btn-plain:focus, .bdl-Media-menu.btn-plain:hover {
    margin-bottom: 5px;
    margin-left: 10px
}

.bcs-AppActivity {
    width: 100%
}

.bcs-AppActivity.bcs-is-pending {
    opacity: .4
}

.bcs-AppActivity-confirm {
    z-index: 190
}

.bcs-AppActivity-content {
    color: #4e4e4e
}

.bcs-AppActivity-icon {
    width: 32px;
    height: 32px
}

.bcs-AppActivity-link.link {
    color: #0061d5
}

.bcs-AppActivity-headline {
    color: #222;
    font-weight: 700
}

.bcs-ActivityError {
    padding: 14px 15px
}

.bcs-ActivityError-action, button.bcs-ActivityError-action {
    display: block;
    margin-top: 5px;
    color: #ed3757;
    text-decoration: underline
}

.bcs-UserLink, .bcs-UserLink.link {
    color: #222;
    font-weight: 700;
    white-space: normal
}

.bcs-ActivityMessage {
    white-space: pre-wrap
}

.bcs-ActivityMessage .link {
    color: #0061d5;
    white-space: normal
}

.bcs-ActivityMessage .link:hover {
    text-decoration: underline
}

.bcs-ActivityMessage-mention {
    overflow: initial;
    white-space: normal
}

.bcs-ActivityMessage-translate {
    display: block;
    color: #0061d5
}

.bcs-ActivityMessageLoading {
    margin-top: 3px
}

.bcs-AnnotationActivityLink {
    display: inline-block;
    overflow-x: hidden;
    color: #0061d5;
    font-weight: 700
}

.bcs-AnnotationActivityLink[aria-disabled=true] {
    color: #767676
}

.bcs-AnnotationActivityLink .bcs-AnnotationActivityLink-message {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
    height: inherit;
    text-align: initial
}

.public-DraftEditor-content[contenteditable=true] {
    -webkit-user-modify: read-write-plaintext-only
}

.DraftEditor-root {
    position: relative
}

.DraftEditor-editorContainer {
    background-color: hsla(0, 0%, 100%, 0);
    border-left: .1px solid transparent;
    position: relative;
    z-index: 1
}

.public-DraftEditor-block {
    position: relative
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
    text-align: left
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
    left: 0;
    text-align: left
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
    text-align: center
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
    margin: 0 auto;
    text-align: center;
    width: 100%
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
    text-align: right
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
    right: 0;
    text-align: right
}

.public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 1
}

.public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9
}

.DraftEditorPlaceholder-hidden {
    display: none
}

.public-DraftStyleDefault-block {
    position: relative;
    white-space: pre-wrap
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left
}

.public-DraftStyleDefault-rtl {
    direction: rtl;
    text-align: right
}

.public-DraftStyleDefault-listLTR {
    direction: ltr
}

.public-DraftStyleDefault-listRTL {
    direction: rtl
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
    margin: 16px 0;
    padding: 0
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 1.5em
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
    margin-right: 1.5em
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
    margin-left: 3em
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
    margin-right: 3em
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
    margin-left: 4.5em
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
    margin-right: 4.5em
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
    margin-left: 6em
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
    margin-right: 6em
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
    margin-left: 7.5em
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
    margin-right: 7.5em
}

.public-DraftStyleDefault-unorderedListItem {
    list-style-type: square;
    position: relative
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    list-style-type: disc
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style-type: circle
}

.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
    position: absolute;
    right: -36px;
    text-align: left;
    width: 30px
}

.public-DraftStyleDefault-orderedListItem:before {
    content: counter(ol0) ". ";
    counter-increment: ol0
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1) ". ";
    counter-increment: ol1
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
    content: counter(ol2) ". ";
    counter-increment: ol2
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
    content: counter(ol3) ". ";
    counter-increment: ol3
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
    content: counter(ol4) ". ";
    counter-increment: ol4
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4
}

.public-DraftEditorPlaceholder-root {
    margin: 8px 10px;
    color: #767676
}

.bdl-is-disabled .public-DraftStyleDefault-block, .is-disabled .public-DraftStyleDefault-block {
    width: 262px;
    padding: 8px 10px;
    border: 1px solid #d3d3d3;
    border-radius: 3px
}

.draft-js-editor {
    overflow: hidden
}

.mention-selector-wrapper {
    position: relative;
    margin: 0 0 20px
}

.mention-selector-wrapper .content-editable-container {
    margin: 0
}

.mention-selector-wrapper .mention-start-state {
    position: absolute;
    z-index: 90;
    width: 100%;
    padding: 8px;
    background: #fff;
    border: 1px solid #bcbcbc;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1)
}

.be .bcs-CommentForm {
    padding: 20px;
    border-top: 1px solid #e8e8e8
}

.be .bcs-CommentForm.bcs-is-open .bcs-CommentForm-input .public-DraftEditor-content {
    min-height: 68px;
    max-height: 140px;
    overflow: auto
}

.be .bcs-CommentForm-avatar {
    margin-right: 20px
}

.be .bcs-CommentForm-body {
    min-width: 0
}

.be .bcs-CommentForm-input .mention-selector-wrapper {
    margin: 0
}

.be .bcs-CommentForm-input .public-DraftEditor-content {
    width: auto;
    cursor: text
}

.be .bcs-CommentForm-tip {
    margin-top: 10px;
    color: #767676
}

.be .bcs-CommentFormControls {
    margin-top: 20px;
    text-align: right
}

.be .bcs-CommentFormControls .ex-btn {
    margin: 0
}

.be .bcs-CommentFormControls .ex-btn:last-child {
    margin-left: 10px
}

.bcs-SelectableActivityCard {
    outline: none;
    transition: background-color .3s ease-in-out 25ms
}

.bcs-SelectableActivityCard[aria-disabled=false] {
    cursor: pointer
}

.bcs-SelectableActivityCard[aria-disabled=false]:focus, .bcs-SelectableActivityCard[aria-disabled=false]:hover {
    background-color: #f2f7fd;
    box-shadow: 0 0 8px 0 #ededed
}

.bcs-AnnotationActivity {
    position: relative;
    z-index: 1
}

.bcs-AnnotationActivity-media {
    opacity: 1;
    transition: opacity .33s
}

.bcs-AnnotationActivity-media.bcs-is-pending {
    opacity: .4;
    pointer-events: none
}

.bcs-AnnotationActivity-headline {
    margin-right: 26px
}

@supports (-ms-ime-align:auto) {
    .bcs-AnnotationActivity-headline {
        word-break: break-all
    }
}

.bcs-AnnotationActivity-deleteConfirmationModal {
    z-index: 190
}

.bcs-AnnotationActivity-menu {
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 1
}

.bcs-AnnotationActivity-timestamp {
    display: flex;
    white-space: nowrap
}

.bcs-AnnotationActivity-timestamp .bcs-ActivityTimestamp:after {
    margin-right: 8px;
    margin-left: 8px;
    color: #909090;
    content: "\2022"
}

.be .bcs-AnnotationActivity-editor.bcs-CommentForm {
    margin-top: 20px;
    padding: 0;
    border-top: none
}

.be .bcs-AnnotationActivity-link {
    display: flex;
    font-weight: 700;
    font-size: 12px
}

.bcs-Comment {
    position: relative;
    z-index: 1
}

.bcs-Comment-media {
    opacity: 1;
    transition: opacity .33s
}

.bcs-Comment-media.bcs-is-pending {
    opacity: .4
}

@supports (-ms-ime-align:auto) {
    .bcs-Comment-headline {
        word-break: break-all
    }
}

.bcs-Comment-deleteConfirmationModal {
    z-index: 190
}

.be .bcs-Comment-editor.bcs-CommentForm {
    margin-top: 20px;
    padding: 0;
    border-top: none
}

button:first-of-type.bcs-TaskActions-button {
    margin-left: 0
}

.bcs-TaskCompletionRuleIcon-oneSize {
    margin-left: 0;
    font-weight: 400
}

.bcs-AvatarGroupAvatar {
    position: relative;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
    height: 32px
}

.bcs-AvatarGroupAvatar-avatar {
    width: 100%;
    height: 100%
}

.bcs-AvatarGroupAvatar-statusIcon {
    position: absolute;
    right: -2px;
    bottom: -2px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50%
}

.bcs-AssigneeDetails {
    overflow: hidden;
    line-height: 16px
}

.bcs-AssigneeDetails-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bcs-AssigneeDetails-status {
    color: #767676
}

.bcs-AssigneeList-list {
    margin: 0;
    padding: 0
}

.bcs-AssigneeList-listItem {
    display: flex;
    align-items: center;
    margin: 6px 0 0;
    padding: 3px 0
}

.bcs-AssigneeList-listItemAvatar {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    font-size: 9px
}

.bcs-AssigneeList-toggleBtn {
    margin-top: 10px;
    margin-left: 38px;
    color: #0061d5
}

.contact-data-list-item {
    display: flex;
    align-items: center;
    padding: 8px;
    line-height: 16px
}

.contact-avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 8px
}

.contact-name-container {
    overflow: hidden
}

.contact-name {
    color: #4e4e4e;
    font-size: 13px
}

.contact-sub-name {
    color: #767676;
    font-size: 13px
}

.contact-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.text-area-container {
    position: relative;
    margin: 0 0 20px
}

.text-area-container label {
    display: block
}

.text-area-container .text-area-description {
    color: #767676;
    word-wrap: break-word
}

.text-area-container textarea {
    margin-top: 5px;
    font-family: inherit
}

.text-area-container:not(.show-error) .caution {
    display: none
}

.text-area-container.show-error textarea {
    border-color: #ed3757
}

.checkbox-label {
    position: relative;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.checkbox-label > input[type=checkbox] {
    flex: none;
    width: 14px;
    height: 14px;
    margin: 3px 0 0;
    opacity: 0
}

.checkbox-label > input[type=checkbox] + span:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #909090;
    border-radius: 2px;
    content: "";
    pointer-events: none
}

.checkbox-label > input[type=checkbox] + span:after {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform;
    position: absolute;
    top: 6px;
    left: 5px;
    width: 4px;
    height: 7px;
    border-right: 2px solid #0061d5;
    border-bottom: 2px solid #0061d5;
    transform: rotate(45deg) scale(0) translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: "";
    pointer-events: none
}

.checkbox-label > input[type=checkbox]:checked + span:after {
    transform: rotate(45deg) scale(1) translateZ(0)
}

.checkbox-label > input[type=checkbox]:focus + span:before {
    border: 2px solid #0061d5
}

.checkbox-label > input[type=checkbox]:disabled + span:before {
    background-color: #fbfbfb;
    border: 1px solid #d3d3d3
}

.checkbox-label > input[type=checkbox]:disabled + span:after {
    border-color: #bcbcbc
}

.bdl-Checkbox-labelTooltipWrapper {
    margin-left: 10px
}

.checkbox-tooltip-wrapper {
    display: inline-flex;
    vertical-align: text-bottom
}

.checkbox-tooltip-wrapper > .info-tooltip {
    position: relative;
    display: inline-block;
    flex: none;
    width: 16px;
    height: 16px;
    margin-left: 5px
}

.checkbox-tooltip-wrapper > .info-tooltip > svg path {
    fill: #0061d5
}

.checkbox-container {
    margin: 0 0 20px
}

.checkbox-container.bdl-is-disabled .checkbox-label, .checkbox-container.is-disabled .checkbox-label {
    color: #767676
}

.checkbox-container > .bdl-Label, .checkbox-container > .label {
    margin: 8px 0
}

.checkbox-description, .checkbox-subsection {
    margin-top: 2px;
    margin-left: 24px
}

.checkbox-description {
    color: #767676
}

.bdl-PillSelector-input--hidden, .pill-selector-hidden-input {
    position: absolute;
    visibility: hidden
}

.bdl-PillSelectorDropdown, .pill-selector-wrapper {
    position: relative;
    margin: 0 0 20px
}

.bdl-PillSelectorDropdown .bdl-PillSelector, .bdl-PillSelectorDropdown .pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector, .pill-selector-wrapper .pill-selector-input-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: 262px;
    margin-top: 5px;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08);
    cursor: text;
    transition: border-color .15s linear, box-shadow .15s linear;
    -webkit-font-smoothing: antialiased
}

.bdl-PillSelectorDropdown .bdl-PillSelector:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-PillSelector:hover, .pill-selector-wrapper .pill-selector-input-wrapper:hover {
    border: 1px solid #004aa2
}

.bdl-PillSelectorDropdown .bdl-is-disabled.pill-selector-input-wrapper, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-is-disabled, .bdl-PillSelectorDropdown .bdl-PillSelector.is-disabled, .bdl-PillSelectorDropdown .is-disabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-is-disabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.bdl-is-disabled, .pill-selector-wrapper .bdl-PillSelector.is-disabled, .pill-selector-wrapper .is-disabled.pill-selector-input-wrapper {
    color: #bcbcbc;
    background-color: #fbfbfb;
    box-shadow: none
}

.bdl-PillSelectorDropdown .bdl-is-disabled.pill-selector-input-wrapper:hover, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-is-disabled:hover, .bdl-PillSelectorDropdown .bdl-PillSelector.is-disabled:hover, .bdl-PillSelectorDropdown .is-disabled.pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-is-disabled.pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-PillSelector.bdl-is-disabled:hover, .pill-selector-wrapper .bdl-PillSelector.is-disabled:hover, .pill-selector-wrapper .is-disabled.pill-selector-input-wrapper:hover {
    border-color: #bcbcbc
}

.bdl-PillSelectorDropdown .bdl-PillSelector.is-focused, .bdl-PillSelectorDropdown .is-focused.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.is-focused, .pill-selector-wrapper .is-focused.pill-selector-input-wrapper {
    border-color: #0061d5;
    outline: 0;
    box-shadow: none
}

.bdl-PillSelectorDropdown .bdl-PillSelector.show-error, .bdl-PillSelectorDropdown .show-error.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.show-error, .pill-selector-wrapper .show-error.pill-selector-input-wrapper {
    border-color: #ed3757
}

.bdl-PillSelectorDropdown .bdl-PillSelector--suggestionsEnabled.pill-selector-input-wrapper, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-PillSelector--suggestionsEnabled, .bdl-PillSelectorDropdown .bdl-PillSelector.pill-selector-suggestions-enabled, .bdl-PillSelectorDropdown .pill-selector-suggestions-enabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector--suggestionsEnabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.bdl-PillSelector--suggestionsEnabled, .pill-selector-wrapper .bdl-PillSelector.pill-selector-suggestions-enabled, .pill-selector-wrapper .pill-selector-suggestions-enabled.pill-selector-input-wrapper {
    min-height: 80px
}

.bdl-PillSelectorDropdown .bdl-PillSelector:not(.show-error) .icon-alert, .bdl-PillSelectorDropdown .pill-selector-input-wrapper:not(.show-error) .icon-alert, .pill-selector-wrapper .bdl-PillSelector:not(.show-error) .icon-alert, .pill-selector-wrapper .pill-selector-input-wrapper:not(.show-error) .icon-alert {
    display: none
}

.bdl-PillSelectorDropdown .bdl-PillSelector .icon-alert, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .icon-alert, .pill-selector-wrapper .bdl-PillSelector .icon-alert, .pill-selector-wrapper .pill-selector-input-wrapper .icon-alert {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 16px;
    height: 16px
}

.bdl-PillSelectorDropdown .bdl-PillSelector .icon-alert path, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .icon-alert path, .pill-selector-wrapper .bdl-PillSelector .icon-alert path, .pill-selector-wrapper .pill-selector-input-wrapper .icon-alert path {
    fill: #ed3757
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-Tooltip, .bdl-PillSelectorDropdown .bdl-PillSelector .tooltip, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-Tooltip, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .tooltip, .pill-selector-wrapper .bdl-PillSelector .bdl-Tooltip, .pill-selector-wrapper .bdl-PillSelector .tooltip, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-Tooltip, .pill-selector-wrapper .pill-selector-input-wrapper .tooltip {
    top: calc(100% - 5px) !important;
    right: 10px !important;
    margin-top: 0 !important
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillsList, .bdl-PillSelectorDropdown .bdl-PillSelector .pills-list, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillsList, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pills-list, .pill-selector-wrapper .bdl-PillSelector .bdl-PillsList, .pill-selector-wrapper .bdl-PillSelector .pills-list, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillsList, .pill-selector-wrapper .pill-selector-input-wrapper .pills-list {
    margin: 0
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillsList li, .bdl-PillSelectorDropdown .bdl-PillSelector .pills-list li, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillsList li, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pills-list li, .pill-selector-wrapper .bdl-PillSelector .bdl-PillsList li, .pill-selector-wrapper .bdl-PillSelector .pills-list li, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillsList li, .pill-selector-wrapper .pill-selector-input-wrapper .pills-list li {
    float: left
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input {
    flex: 1;
    box-sizing: content-box !important;
    min-width: 0;
    height: 18px;
    margin: 1px;
    padding: 3px;
    overflow: hidden;
    color: #4f4f4f;
    line-height: 18px;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    resize: none
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input:focus, .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input:hover, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input:focus, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input:focus, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input:focus, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input:hover, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input:focus, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input:hover, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input:focus, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input:hover, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input:focus, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input:hover, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:focus, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:hover {
    outline: none
}

.bdl-PillSelectorDropdown .bdl-PillSelector .bdl-RoundPill ~ .bdl-PillSelector-input, .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-RoundPill ~ .pill-selector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-RoundPill ~ .bdl-PillSelector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-RoundPill ~ .pill-selector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-RoundPill ~ .bdl-PillSelector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-RoundPill ~ .pill-selector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-RoundPill ~ .bdl-PillSelector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-RoundPill ~ .pill-selector-input {
    height: 28px;
    line-height: 28px
}

.bdl-PillSelector.show-error, .show-error.pill-selector-input-wrapper {
    border-color: #ed3757
}

.bdl-PillSelectorDropdown .overlay, .pill-selector-wrapper .overlay {
    margin-top: 4px;
    padding: 12px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1)
}

.bdl-PillSelectorDropdown .suggested, .pill-selector-wrapper .suggested {
    padding-top: 0
}

.be .bcs-task-input-container {
    display: flex
}

.be .bcs-task-input-container .bcs-task-input-controls {
    display: block;
    text-align: right
}

.be .bcs-task-input-container .bcs-task-input-controls .ex-btn {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 10px
}

.be .bcs-task-input-container .bcs-task-input-controls .ex-btn:last-child {
    margin-left: 10px
}

.be .bcs-task-input-container .bcs-task-input-form-container {
    flex: 1;
    min-width: 0
}

.be .bcs-task-input-container .bcs-task-input-form-container .date-picker-input, .be .bcs-task-input-container .bcs-task-input-form-container textarea {
    width: 100%
}

.be .bcs-task-input-container .bcs-task-input-form-container .checkbox-container {
    margin-top: -10px
}

.be .bcs-task-input-container .bcs-task-input-form-container .bcs-task-add-due-date-input {
    margin-right: 0;
    margin-left: 0
}

.be .bcs-task-input-container .bcs-task-input-form-container .bcs-task-add-due-date-input .date-picker-wrapper {
    width: 166px
}

.be .bcs-task-input-container .bcs-task-input-form-container .bcs-task-name-input {
    max-height: 140px;
    margin-top: 20px;
    margin-bottom: 15px
}

.be .bcs-task-input-container .bcs-task-input-form-container input.bdl-PillSelector-input--hidden {
    width: 1px
}

.be .bcs-task-input-container .bcs-task-input-form-container .bdl-PillSelectorDropdown {
    width: 100%;
    margin-left: 0
}

.be .bcs-task-input-container .bcs-task-input-form-container .bdl-PillSelectorDropdown .bdl-PillSelector {
    width: auto
}

.be .bcs-task-input-container .bcs-task-input-form-container .bdl-PillSelectorDropdown.scrollable .overlay-wrapper {
    position: absolute;
    height: 215px;
    overflow-y: scroll;
    border: 1px solid #bcbcbc
}

.be .bcs-task-input-container .bcs-task-input-form-container .bdl-PillSelectorDropdown.scrollable .overlay {
    border: none
}

.be .bcs-task-input-container .inline-alert {
    margin: 0 0 20px
}

.bcs-Task-media {
    opacity: 1;
    transition: opacity .33s
}

.bcs-Task-media.bcs-is-pending {
    opacity: .4
}

.bcs-Task-media .bdl-Media-body {
    overflow: hidden
}

.bcs-Task-avatar {
    position: relative;
    height: 100%
}

.bcs-Task-avatarBadge {
    position: absolute;
    right: -2px;
    bottom: -2px;
    background: #fff;
    border-radius: 50%;
    right: -4px;
    border: 2px solid #fff
}

@supports (-ms-ime-align:auto) {
    .bcs-Task-headline {
        word-break: break-all
    }
}

.bcs-Task-status {
    margin-top: 12px;
    margin-bottom: 8px
}

.bcs-Task-status > * {
    margin-right: 4px
}

.bcs-Task-dueDate {
    margin-bottom: 12px
}

.bcs-Task-assigneeListContainer {
    margin-top: 4px
}

.bcs-Task-actionsContainer {
    margin-top: 20px
}

.bcs-Task-deleteConfirmationModal {
    z-index: 190
}

.be .bcs-Version {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 25px;
    padding: 9px;
    color: #222;
    background-color: #f2f7fd;
    border-radius: 4px
}

.be .bcs-Version-actions {
    display: flex;
    justify-content: flex-end
}

.be .bcs-Version-info {
    flex: none;
    width: 16px;
    height: 16px
}

.be .bcs-Version-info path {
    fill: #0061d5
}

.be .bcs-Version-message {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis
}

.be .bcs-Version-range {
    white-space: nowrap
}

.bcs-EmptyState, .bcs-EmptyState-cta {
    display: flex;
    flex-direction: column;
    align-items: center
}

.bcs-EmptyState-cta-detail {
    font-weight: 700
}

.bcs-EmptyState-cta-message {
    width: 200px;
    margin-top: 6px;
    text-align: center
}

.bcs-EmptyState-illustration {
    margin-top: 60px;
    margin-bottom: 15px
}

@keyframes bcs-ActivityItem-callout {
    0%, to {
        background-color: transparent
    }
    30%, 70% {
        background-color: #f2f7fd
    }
}

.be .bcs-activity-feed {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 100%
}

.be .bcs-activity-feed ol, .be .bcs-activity-feed ul {
    list-style: none
}

.be .bcs-activity-feed li {
    line-height: 20px
}

.be .bcs-activity-feed .bcs-activity-feed-items-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto
}

.be .bcs-activity-feed .bcs-activity-feed-items-container > .inline-alert-error {
    margin: 9px 20px
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-ActivityItem {
    position: relative;
    box-shadow: 0 25px 0 -24px #e8e8e8;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: box-shadow .2s ease 25ms
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-ActivityItem.bcs-is-focused .bcs-ActivityCard {
    box-shadow: 0 0 16px #bcbcbc;
    animation: bcs-ActivityItem-callout 1s ease-in-out 25ms 0 normal none
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-ActivityItem.bcs-is-focused .bcs-ActivityCard:not(:focus-within) {
    animation-iteration-count: 1;
    animation-fill-mode: forwards
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-version-item {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-version-item, .be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-version-item + .bcs-ActivityItem {
    margin-top: 16px
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-activity-feed-annotation-activity .bcs-ActivityCard:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 4px;
    background-color: #f5b31b;
    transform: scaleY(0);
    transition: transform .2s ease 25ms;
    content: ""
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-activity-feed-annotation-activity.bcs-is-focused .bcs-ActivityCard:before {
    transform: scaleY(1)
}

.be .bcs-activity-feed .bcs-activity-feed-items-container .bcs-activity-feed-annotation-activity.bcs-is-focused .bcs-SelectableActivityCard {
    background-color: transparent
}

.be .bcs-activity-feed .bcs-activity-feed-active-state {
    padding-bottom: 16px
}

.be .bcs-activity-feed .bcs-activity-feed-loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px
}

.be .bcs-activity-feed .bcs-feedItemInlineError {
    margin: 20px 25px
}

.be .bcs-activity-feed .bcs-activity-feed-comment-input {
    flex: 0 0 auto;
    min-height: 93px;
    max-height: 500px;
    overflow: hidden;
    background-color: #fff
}

.be .bcs-activity-feed .bcs-activity-feed-comment-input.bcs-is-disabled {
    opacity: .4;
    pointer-events: none
}

.be .bcs-activity-feed .bcs-activity-feed-comment-input .selector-dropdown-wrapper {
    z-index: auto;
    width: 100%
}

.be .bcs-activity-feed .bcs-activity-feed-comment-input .overlay {
    max-height: 95px;
    overflow: auto
}

.bcs-AddTaskMenu {
    margin-top: 4px;
    padding: 12px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1)
}

.bcs-AddTaskMenu-menuItem {
    display: flex;
    max-width: 256px;
    min-height: 80px;
    white-space: normal;
    padding: 8px 48px 8px 8px;
    border-radius: 8px;
    cursor: pointer
}

.bcs-AddTaskMenu-menuItem:focus {
    outline: none !important
}

.bcs-AddTaskMenu-menuItem.is-active {
    background-color: rgba(34, 34, 34, .05)
}

.bcs-AddTaskMenu-title {
    font-weight: 700
}

.bcs-AddTaskMenu-description {
    color: #767676;
    font-size: 11px;
    line-height: 14px
}

.bcs-activity .bcs-scroll-content {
    width: 100%;
    height: 100%
}

.bcs-VersionsItemAction .fill-color {
    fill: #222
}

.bcs-VersionsItemAction .stroke-color {
    stroke: #222
}

.bcs-VersionsItemActions-toggle {
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    width: 32px;
    height: 24px;
    border-radius: 2px;
    outline: none;
    pointer-events: auto
}

.bcs-VersionsItemActions-toggle:focus, .bcs-VersionsItemActions-toggle:hover {
    background-color: #e8e8e8;
    fill: #222
}

.bcs-VersionsItemActions-toggle .fill-color {
    fill: #909090
}

.bcs-VersionsItemActions-toggle .icon-clock-past {
    margin-left: -2px
}

.bcs-VersionsItemButton {
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: #222;
    font-size: 100%;
    line-height: 20px;
    text-align: left;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
    transition: background-color 50ms ease-in, border 50ms ease-in
}

.bcs-VersionsItemButton, .bcs-VersionsItemButton:focus, .bcs-VersionsItemButton:hover {
    border: 1px solid transparent
}

.bcs-VersionsItemButton:focus, .bcs-VersionsItemButton:hover {
    border-color: #909090
}

.bcs-VersionsItemButton.bcs-is-disabled {
    border-color: transparent;
    cursor: default;
    pointer-events: none
}

.bcs-VersionsItemButton.bcs-is-disabled .bcs-VersionsItemBadge {
    background-color: #f4f4f4
}

.bcs-VersionsItemButton.bcs-is-disabled .bcs-VersionsItem-current, .bcs-VersionsItemButton.bcs-is-disabled .bcs-VersionsItemBadge {
    color: inherit
}

.bcs-VersionsItemButton.bcs-is-selected {
    background-color: #f6fafd;
    border-color: #0061d5
}

.bcs-VersionsItemButton.bcs-is-selected .bcs-VersionsItem-info {
    color: inherit
}

.bcs-VersionsItemBadge {
    padding: 10px 8px;
    color: #0061d5;
    font-size: 18px;
    text-align: center;
    background-color: #ecf4fc;
    border-radius: 4px
}

.bcs-VersionsItem {
    position: relative
}

.bcs-VersionsItem .bcs-VersionsItemButton {
    padding: 15px 10px
}

.bcs-VersionsItem .bcs-VersionsItemActions .aria-menu {
    padding-top: 8px;
    padding-bottom: 8px
}

.bcs-VersionsItem .bcs-VersionsItemActions-toggle {
    position: absolute;
    top: 15px;
    right: 10px
}

.bcs-VersionsItem-badge {
    flex: 1 0 auto;
    margin-right: 10px
}

.bcs-VersionsItem-current {
    color: #0061d5;
    font-weight: 700
}

.bcs-VersionsItem-details {
    flex: 1 1 100%;
    overflow: hidden
}

.bcs-VersionsItem-details > :not(.bcs-VersionsItem-info) {
    margin-right: 35px
}

.bcs-VersionsItem-log {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bcs-VersionsItem-info {
    display: flex;
    align-items: center;
    color: #767676;
    white-space: nowrap
}

.bcs-VersionsItem-retention {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: #767676
}

.bcs-VersionsItem-footer {
    color: #767676;
    font-size: 12px
}

.bcs-VersionsItem-size {
    overflow: hidden;
    text-overflow: ellipsis
}

.bcs-VersionsItem-size:before {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 8px;
    vertical-align: top;
    content: "â€¢"
}

.be .bcs-VersionsList {
    margin: 0
}

.bcs-VersionsList-item .bcs-VersionsItem {
    width: calc(100% + 20px);
    margin-right: -10px;
    margin-bottom: -1px;
    margin-left: -10px
}

.bcs-VersionsList-item .bcs-VersionsItem:focus, .bcs-VersionsList-item .bcs-VersionsItem:hover {
    z-index: 2
}

.bcs-VersionsList-item .bcs-VersionsItem.bcs-is-selected {
    z-index: 1
}

.bcs-VersionsList-item + .bcs-VersionsList-item {
    border-top: 1px solid #e8e8e8
}

.bcs-VersionsList-item + .bcs-VersionsList-item .bcs-VersionsItem {
    margin-top: -1px
}

.bcs-VersionsGroup-heading {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    color: #767676;
    font-size: 14px;
    line-height: 1
}

.bcs-VersionsMenu-item + .bcs-VersionsMenu-item {
    border-top: 1px solid #e8e8e8
}

.bcs-Versions .bcs-scroll-content {
    width: 100%
}

.bcs-Versions .bcs-section-content:first-child {
    padding-top: 0
}

.bcs-Versions .bdl-BackButton {
    margin-right: 5px
}

.bcs-Versions .crawler {
    display: flex;
    align-items: center;
    height: 50px
}

.bcs-Versions-content {
    height: 100%;
    padding-right: 25px;
    padding-left: 25px
}

.bcs-Versions-empty, .bcs-Versions-maxEntries {
    padding-top: 10px;
    text-align: center
}

.be .bcs-Versions-menu {
    padding-bottom: 20px
}

.be-modal .be-modal-btns {
    display: flex;
    justify-content: center;
    padding: 15px 0 0
}

.be-modal .be-modal-btns .ex-btn {
    margin-left: 8px
}

.be-modal .be-modal-error {
    color: #c82341
}

.be-modal-dialog-content, .be-modal-dialog-content-full-bleed, .be-modal-wrapper-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    outline: none
}

.be-modal-dialog-overlay, .be-modal-wrapper-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 51, 51, .4)
}

.be-modal-dialog-content {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 400px;
    margin-right: -50%;
    padding: 25px;
    border-radius: 4px;
    transform: translate(-50%, -50%)
}

.be-is-small .be-modal-dialog-content {
    width: 340px
}

.be-modal-dialog-overlay {
    position: absolute
}

.be.bcs {
    display: flex;
    width: auto;
    min-width: 60px;
    max-width: 400px;
    background-color: #fff
}

.be .bcs-loading {
    display: flex;
    flex: 1;
    align-items: center;
    width: 10px;
    margin: 0 auto;
    font-size: 0;
    white-space: nowrap
}

.be.bcs-is-open .bcs-loading {
    width: 400px
}

.be select.be-share-access-select {
    padding: 5px 25px 5px 10px;
    color: inherit;
    background: none;
    background-image: linear-gradient(45deg, transparent 50%, #767676 0), linear-gradient(135deg, #767676 50%, transparent 0);
    background-repeat: no-repeat;
    background-position: right 14px center, right 10px center;
    background-size: 4px 4px, 4px 4px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.be select.be-share-access-select:hover {
    border-color: #0061d5
}

.be select.be-share-access-select:active, .be select.be-share-access-select:focus {
    color: #0061d5;
    background-image: linear-gradient(45deg, transparent 50%, #0061d5 0), linear-gradient(135deg, #0061d5 50%, transparent 0);
    border-color: #0061d5;
    outline: none
}

.be select.be-share-access-select:disabled {
    color: #e8e8e8;
    background-image: linear-gradient(45deg, transparent 50%, #e8e8e8 0), linear-gradient(135deg, #e8e8e8 50%, transparent 0);
    border-color: #e8e8e8
}

.be select.be-share-access-select:-moz-focusring, .be select.be-share-access-select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #222 !important
}

.be select.be-share-access-select::-ms-expand {
    display: none !important
}

.be-modal.be-modal-share .bce-shared-access-select {
    height: 27px;
    line-height: 15px
}

.be-modal.be-modal-share .be-modal-content {
    position: relative
}

.be-modal.be-modal-share .be-modal-content input[type=text] {
    flex-grow: 1;
    max-width: 296px;
    text-overflow: ellipsis;
    border-right: 0;
    border-radius: 3px 0 0 3px
}

.be-is-small .be-modal.be-modal-share .be-modal-content input[type=text] {
    width: 237px
}

.be-modal.be-modal-share .be-modal-input-group {
    display: flex;
    justify-content: space-between
}

.be-modal.be-modal-share .be-modal-button-copy {
    min-width: 70px;
    height: 36px;
    margin: 0 0 0 -5px;
    padding: 0 10px;
    font-weight: 700;
    font-size: 10px;
    line-height: 34px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0 3px 3px 0
}

.be-modal.be-modal-share .be-modal-btns {
    align-items: center;
    justify-content: space-between
}

.be-modal.be-modal-share .be-modal-btns .ex-btn {
    margin: 0
}

.be-empty {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center
}

.be-progress-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 190;
    width: 100%;
    transition: opacity .4s
}

.be-progress-container .be-progress {
    max-width: 100%;
    height: 2px;
    background: #0061d5;
    box-shadow: 0 1px 5px 0 #e4f4ff;
    transition: width .4s
}

.bdl-GridViewSlot {
    flex: 0 1 auto;
    padding: 5px;
    overflow: hidden;
    white-space: normal
}

.bdl-GridViewSlot-content {
    height: 100%;
    border: 1px solid #e8e8e8;
    box-shadow: 0 1px 5px rgba(50, 50, 50, .1)
}

.bdl-GridViewSlot-content.bdl-GridViewSlot-content--selected, .bdl-GridViewSlot-content:active, .bdl-GridViewSlot-content:focus, .bdl-GridViewSlot-content:hover {
    border-color: #7fb0ea
}

.bdl-GridView .ReactVirtualized__Table__rowColumn:first-child {
    margin: 0
}

.bdl-GridView .bce-more-options {
    align-self: flex-start
}

.bdl-GridView .bce-btn-more-options {
    margin-top: 0;
    margin-right: 0;
    padding: 5px 10px
}

.bdl-GridView .bce-btn-more-options .ex-btn-content {
    display: flex;
    align-items: center;
    justify-content: center
}

.bdl-GridView-body {
    padding: 5px;
    outline: none
}

.bdl-GridView-row {
    display: flex;
    justify-content: flex-start
}

.bdl-GridView-tableRow {
    width: 100% !important;
    padding-right: 0 !important;
    border: none
}

.be-item-icon {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: center
}

.be-is-small .be-item-icon {
    flex: 0 0 30px
}

.bce-ItemGridThumbnail {
    position: relative;
    margin-bottom: 2px;
    padding-bottom: 65%;
    background-color: #fbfbfb
}

.bce-ItemGridThumbnail-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.bce-ItemGridThumbnail-item svg {
    width: 60%;
    height: 60%
}

.ex-btn.bce-btn-more-options {
    letter-spacing: 1.5px
}

.be-item-label {
    width: 100%;
    text-align: left;
    outline: none
}

.bdl-GridView .bdl-ItemSubDetails-modifiedBy, .bdl-GridView .bdl-ItemSubDetails-size, .be-item-label {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.bce-item-row .bdl-ItemSubDetails-size:before, .bcp-item-row .bdl-ItemSubDetails-size:before {
    padding-right: 3px;
    padding-left: 3px;
    font-size: 8px;
    vertical-align: middle;
    content: "â€¢"
}

.be-item-name .be-item-details {
    color: #767676;
    font-size: 11px
}

.be-item-name {
    overflow: hidden;
    line-height: 15px;
    white-space: normal;
    text-align: left;
    text-overflow: ellipsis
}

.bce-ItemGridCell {
    margin: 0
}

.bce-ItemGridCell .be-inline-breadcrumbs {
    margin-bottom: 15px
}

.bce-ItemGridCell-figcaption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px
}

.bce .ReactVirtualized__Table__headerColumn .icon-chevron-down {
    position: relative;
    top: -2px
}

.bce-item-grid {
    flex: 1
}

.bce-item-grid .ReactVirtualized__Table__Grid {
    outline: none
}

.bce-item-row {
    border-top: 1px solid #e8e8e8;
    border-left: 2px solid transparent;
    outline: none
}

.bce-item-row .bce-item-coloumn {
    color: #767676
}

.bce-item-row .bce-more-options {
    opacity: 0
}

.bce-item-row .bce-more-options .ex-btn {
    margin-left: 8px;
    background-color: transparent
}

.be-is-touch .bce-item-row .bce-more-options {
    opacity: 1
}

.bce-item-row:active, .bce-item-row:focus, .bce-item-row:hover {
    background-color: rgba(228, 244, 255, .4);
    border-left-color: #1a5976
}

.bce-item-row:active .bce-more-options, .bce-item-row:focus .bce-more-options, .bce-item-row:hover .bce-more-options {
    opacity: 1
}

.bce-item-row:active .bce-item-coloumn, .bce-item-row:focus .bce-item-coloumn, .bce-item-row:hover .bce-item-coloumn, .be .bce-item-row:active .btn-plain, .be .bce-item-row:focus .btn-plain, .be .bce-item-row:hover .btn-plain {
    color: #1a5976;
    outline: none
}

.bce-item-row.bce-item-row-selected {
    background-color: #e4f4ff;
    border-top-color: #bed4de
}

.bce-item-row.bce-item-row-selected .bce-more-options {
    opacity: 1
}

.bce-item-row.bce-item-row-selected .bce-item-coloumn, .be .bce-item-row.bce-item-row-selected .btn-plain {
    color: #1a5976
}

.bce-item-row:first-child {
    border-top-color: transparent !important
}

.bce-item-row:last-child {
    border-bottom: 1px solid #e8e8e8
}

.bce-item-row:last-child.bce-item-row-selected {
    border-bottom-color: #bed4de
}

.bce-item-row.bce-item-row-selected + .bce-item-row {
    border-top-color: #bed4de
}

.bce-item-header-row {
    border-bottom: 1px solid #e8e8e8;
    box-shadow: 0 4px 6px -2px rgba(51, 51, 51, .04)
}

.bce-item-header-row .ReactVirtualized__Table__headerColumn {
    color: #767676;
    font-weight: 400;
    text-transform: none
}

.bce-item-header-row .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer
}

.bce-item-header-row .ReactVirtualized__Table__sortableHeaderColumn:active, .bce-item-header-row .ReactVirtualized__Table__sortableHeaderColumn:focus, .bce-item-header-row .ReactVirtualized__Table__sortableHeaderColumn:hover {
    color: #1a5976;
    outline: none
}

.bdl-MetadataBasedItemList .ReactVirtualized__Grid {
    outline: none
}

.bdl-MetadataBasedItemList .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important
}

.bdl-MetadataBasedItemList-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #767676;
    border-bottom: 1px solid #e8e8e8
}

.bdl-MetadataBasedItemList-cell .icon-pencil {
    margin-left: 10px
}

.bdl-MetadataBasedItemList-cell .bdl-ReadOnlyMetadataField {
    margin-bottom: 0
}

.bdl-MetadataBasedItemList-cell--edit {
    display: flex;
    height: 32px;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 3px
}

.bdl-MetadataBasedItemList-cell--edit .bdl-MetadataBasedItemList-cell--cancelIcon, .bdl-MetadataBasedItemList-cell--edit .bdl-MetadataBasedItemList-cell--saveIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border: 0;
    border-left: 1px solid #d3d3d3;
    border-radius: 0
}

.bdl-MetadataBasedItemList-cell--edit .select-container .select-button, .bdl-MetadataBasedItemList-cell--edit .text-input-container input[type=text] {
    width: 165px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    box-shadow: none
}

.bdl-MetadataBasedItemList-cell--edit .date-picker-icon-holder .date-picker-input {
    padding-right: 40px;
    width: 165px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    box-shadow: none
}

.bdl-MetadataBasedItemList-cell--edit .date-picker-icon-holder .date-picker-input:hover {
    box-shadow: none
}

.bdl-MetadataBasedItemList-cell--filename {
    color: #222
}

.bdl-MetadataBasedItemList-cell--filename .btn-plain {
    text-align: left;
    outline: none
}

.bdl-MetadataBasedItemList-cell--hover {
    background-color: #e9f2fe
}

.bdl-MetadataBasedItemList-cell--fileIcon {
    border-left: 2px solid transparent
}

.bdl-MetadataBasedItemList-cell--fileIcon.bdl-MetadataBasedItemList-cell--hover {
    border-left-color: #003c84
}

.bdl-MetadataBasedItemList-cell--fileIcon svg {
    margin: 0 10px
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledRight, .bdl-MetadataBasedItemList-topRightGrid.is-scrolledRight {
    box-shadow: inset 3px 0 3px -2px #e8e8e8
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledLeft, .bdl-MetadataBasedItemList-topRightGrid.is-scrolledLeft {
    box-shadow: inset -4px 0 2px -2px #e8e8e8
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledMiddle, .bdl-MetadataBasedItemList-topRightGrid.is-scrolledMiddle {
    box-shadow: inset -4px 0 2px -2px #e8e8e8, inset 3px 0 3px -2px #e8e8e8
}

.bce-content {
    position: relative;
    display: flex;
    flex: 1 0 230px;
    flex-direction: column
}

.be.bce {
    min-width: 300px
}

.messages_chat_history #table_container-tabpane-first {
    padding: 40px 0 0 20px;
}
.messages_chat_history {
    padding-right:0!important;
}

.editor_content p {
    color: inherit!important;
    font-family: inherit!important;;
    font-size: inherit!important;;
}

.leads_details_edit .close_b {
    position: absolute;
    top: 7px;
    right: 0;
}
.overflow-box {
    background: #F5F5F5;
    padding: 15px;
    width: 150px;
    height: auto;
}
.overflow-box p {
    font-size: 12px;
}
.overflow-box h3 {
    font-size: 14px;
}


/*!*----------------------------------------New Video*!*/


/*form {*/
/*    max-width: 300px;*/
/*    margin: 0 auto;*/
/*}*/

/*form > div {*/
/*    width: 100%;*/
/*    margin-bottom: 1em;*/
/*}*/
/*form > div > label {*/
/*    display: block;*/
/*    margin-bottom: 0.3em;*/
/*}*/
/*form > div > input {*/
/*    display: block;*/
/*    width: 100%;*/
/*    font-size: 16px;*/
/*    padding: 0.4em;*/
/*    border-radius: 6px;*/
/*    border: 1px solid #333e5a;*/
/*}*/


/*.room {*/
/*    position: relative;*/
/*}*/
/*.room button {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 20px;*/
/*}*/
/*.room > h3 {*/
/*    text-align: center;*/
/*    font-weight: 300;*/
/*    margin-bottom: 1em;*/
/*}*/

/*.local-participant {*/
/*    text-align: center;*/
/*    margin-bottom: 2em;*/
/*}*/
/*.remote-participants {*/
/*    display: flex;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: space-between;*/
/*    padding: 0 2em 2em;*/
/*}*/
/*.participant {*/
/*    background: #333e5a;*/
/*    padding: 10px;*/
/*    border-radius: 6px;*/
/*    display: inline-block;*/
/*    margin-right: 10px;*/
/*}*/
/*.participant:last-child {*/
/*    margin-right: 0;*/
/*}*/
/*.participant h3 {*/
/*    text-align: center;*/
/*    padding-bottom: 0.5em;*/
/*    color: #fff;*/
/*}*/

/*video {*/
/*    width: 100%;*/
/*    max-width: 600px;*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*    border-radius: 6px;*/
/*}*/

