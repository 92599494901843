
.login_header {
    background-image: url("../img/login_back.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.img {
    margin-bottom: 60px;
    text-align: center;
}np

.login_content p {
    color: #00345D;
    font-size: 18px;
    margin-bottom: 60px;
    font-family: 'openSansRegular';
    text-align: center;
}

.login_inputs input {
    width: 100%;
    height: 44px;
    border: 1px solid #99B7CF;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 8px 24px;
    font-size: 13px;
    font-family: openSansRegular;
    max-width: initial;

}


.login_inputs input:focus {
    outline: none;
}

.login_inputs input::placeholder {
    color: #99B7CF;

}

.login_remember {
    margin-left: 13px;
}

.login_forgot {
    float: right;
    margin-right: 13px;
}


.login_content label, a {
    color: #00559A;
    font-size: 14px;
    font-family: openSansSemiBold;
}

.login_content input[type = 'checkbox'] {
    color: #00559A;
}


.login_content input[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;

}

.login_content input[type="checkbox"]:checked + label,
.login_content input[type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 15px;
    display: inline-block;
    color: #00559A;
}

.login_content input[type="checkbox"]:checked + label:before,
.login_content input[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 15px;
    border: 1px solid #00559A;
    border-radius: 2px;
    background: #fff;
}

.login_content input[type="checkbox"]:checked + label:after,
.login_content input[type="checkbox"]:not(:checked) + label:after {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 3px;
    left: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.login_content input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.login_content input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.rules {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5px;
    text-align: center;
}

.rules ul {
    padding: 0;

}

.rules li {
    display: inline-block;
}


.rules a {
    text-decoration: none;
    border-right: 1px solid #00345D;
    padding: 0 10px;
    font-size: 13px;
    font-family: openSansRegular;
}


.rules .last {
    border: none;
}


.remember_email_content {
    margin: 0 auto;
}

.remember_email {
    margin-bottom: 64px;
    text-align: center;
    color: #00345D;
    font-size: 18px;
    font-family: openSansRegular;
}

.succes_message p {
    text-align: center;
    margin-bottom: 73px;
}



.button{
    padding: 9px 50px;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    background-color: #FFC10E;
    font-family: OpenSansSemiBold;
}


.button:hover{
    color:#FFC10E;
    background-color: #fff;
    border: 1px solid #FFC10E;
}

.button:focus{
    outline: none;
}






