.email_template h5{
    font-size: 13px;
    font-family: OpenSansBold;
    color: #1B75BE;
    margin: 15px;
}

.email_template_modal .modal-body{
    padding: 12px 2px;
}

.email_template_editor .ck-content p {
    color: var(--app_default);
    font-size: 13px;
    font-family: openSansRegular;
}



.email_template .ck-toolbar_grouping{
    background: #F1F5F9!important;
    color: red !important;
}

.email_template .ck-editor   button{
    color:var(--app_default);
}


.email_table  table tr:hover {
background: none !important;
}

.email_table td ,.email_table th{
    text-align: left !important;
}
.email_table{
    padding: 5px 50px;
}

.modal_width .modal-dialog{
    max-width: 700px !important;
}

.modal_width .tox-tinymce {border: none !important;}


.modal_width .tox-toolbar__primary {
    border-color: #1B75BE !important;
}

.modal_width .tox-mbtn{
    color:#1B75BE !important;
}

.modal_width .tox-icon svg{
    fill:#1B75BE !important;
}

.modal_width .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){
    border-right: 1px solid #1B75BE !important;
}
