.image-item {
    display: inline-block;
    margin: 6px;
    vertical-align: middle !important;
}
.image-item img {
    width: 70px;
    height: 60px;
    object-fit: cover;
}

.upload__image-wrapper{
    height: 10px;
}

.image_box_all{
    background: #f7f7f7;
    padding: 5px;
    border-radius: 7px;
    position: relative;
    top: -102px;
    left: 35px;
    max-width: 340px;
    overflow-x: auto;
    white-space: nowrap;
}

.remove_btn_img {
    position: relative;
    right: 9px;
    top: -68px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #00559a;
    color: white;
    cursor: pointer;
}

.rem_b {
    position: relative;
    top: -4px;
    left: 5px;
    font-size: 13px;
}

.chat_image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.chat_image{
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.file_box {
    position: absolute;
    top: 0;
    left: -120px;
    background: #e0e0e0;
    padding: 8px;
    border-radius: 10px;
}

.file_box_all{
    width: 150px;
    height: 60px;
    background: white;
    border-radius: 10px;
    padding: 5px;
    display: flex;
}

.chat_file{
    padding: 6px;
    display: flex;
    align-items: center;
}