@font-face {
  font-family: openSansRegular;
  src: url('../fonts/openSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: openSansSemiBold;
  src: url('../fonts/openSans/OpenSans-Semibold.ttf');
}

@font-face {
  font-family: openSansBold;
  src: url('../fonts/openSans/OpenSans-Bold.ttf');
}


$apa_fill: #00559A;
$apa_default: #00345D;


.add_lead_basic {
  background-color: #fff;
  padding: 15px 38px;
  margin-bottom: 12px;

}


.save_draft_button{
  margin-right: 18px;
}


.add_lead_slider_add {
  width: 100%;
  text-align: end;
  margin-left: -8px
}

.add_lead_basic input[type="text"], .add_lead_basic input[type="date"], .add_lead_basic input[type="number"], .add_lead_basic input[type="email"],.add_lead_basic input[type="tel"] {
  outline: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid #99B7CF;
  width: 100%;
  color: #00559A;
  font-size: 13px;
}

.add_lead_basic {
  box-shadow: 1px 1px 11px 4px #f5f5f5;
  height: 100%;

}
#table_container-tabpane-fourth .add_lead_basic{
height: initial;
}
.add_lead_basic [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  font-size: 13px;
  font-family: openSansRegular;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #00559A;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 3px;
  width: 14px;
  height: 14px;
  border: 1px solid #00559A;
  border-radius: 100%;
  background: #fff;
}

.discover_modal_content .modal-body .peril_options_add_more {
  border: none;
  background: none;
  color: #1B75BE;
  font-size: 13px;
  font-family: openSansBold;
  float: initial;
}

.discover_modal_content .modal-body .peril_options_add_more i {
  font-size: 24px;
}

.modal-header .close {
  padding: 5px !important;
  margin: 5px;
}

.discover_modal_content .modal-body .peril_options_add_more span {
  margin-left: 10px;
  vertical-align: super;
  font-size: 13px;
  font-family: openSansBold;
  color: #1B75BE;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #00559A;
  position: absolute;
  top: 6px;
  left: 13px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.add_lead_basic h3 {
  font-size: 24px;
  font-family: openSansBold;
  color: #00345D;
  margin-bottom: 40px;
}

.add_lead_basic p {
  font-size: 13px;
  font-family: openSansSemiBold;
  color: #1B75BE;
  padding-top: 10px;
}

.add_lead_basic select {
  outline: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid #99B7CF;
  width: 100%;
  border-radius: 0;
  height: 25px;
  z-index: 2;
  color: $apa_fill !important;
}


.search_select div ,.search_select   span{
  border: 0 !important
}


.search_select svg,.search_select span{
  display: none;
}

.search_select {
  border-bottom: 1px solid #99B7CF;
  margin-bottom: 20px;
}




.search_select:after{
  content: "\e82f";
  font-family: "apa";
  color: #00559A;
  font-size: 8px;
  right: 4px;
  top: 13px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
}

.add_lead_basic .selectdiv:after , .property_content .selectdiv:after {
  content: "\e82f";
  font-family: "apa";
  color: #00559A;
  font-size: 8px;
  right: 21px;
  top: 8px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
}
.property_content .selectdiv:after {
  right: 105px !important;
}



.react-datepicker-popper {
  z-index: 100 !important;
}

.input_date {
  cursor: pointer;
}

.add_disabled input {

}

.react-datepicker-wrapper:after {
  content: "\e830";
  font-family: "apa";
  color: #00559A;
  font-size: 16px;
  right: 19px;
  top: 0;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
}

.selectdiv select, .selectdiv input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  float: right;
  padding: 0px 24px;
  font-size: 13px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

select {
  padding: 10px 40px 10px 10px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #bbb;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  cursor: pointer;
  z-index: 4;

}


.react-datepicker-wrapper {
  width: 100%;

}

.add_lead_basic input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  padding: 0.2em;
}

.add_lead_basic input[type=checkbox] {
  display: none;
}

.add_lead_basic input[type=checkbox] + label:before {
  content: "\2714";
  border: 0.1em solid #1B75BE;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

.add_lead_basic input[type=checkbox] + label:active:before {
  transform: scale(0);
}

.add_lead_basic input[type=checkbox]:checked + label:before {
  background-color: #1B75BE;
  border-color: #1B75BE;

}

.add_lead_basic input[type=checkbox]:disabled + label:before {
  transform: scale(1);

}

.add_lead_basic input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);

}

.add_lead_title h1 {
  font-size: 24px;
  font-family: openSansBold;
  color: #00345D;
}



.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: darken($apa_fill, 33%);
  height: 40px;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 14px;
    font-family: OpenSansSemiBold;
    color: #99B7CF;
    vertical-align: top;


    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: #ffffff;
      border: 2px solid $apa_fill;
      color: $apa_fill;
      width: 6px;
      height: 6px;
      text-align: center;
      margin-bottom: -40px;
      line-height: 1.9rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 1rem;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: $apa_fill;
      width: 100%;
      height: 1px;
      top: 2px;
      left: 50%;
    }

    &:last-child:after {
      display: none;
    }

    &.is-complete {
      color: #6D6875;

      &:before {
        content: '\2713';
        color: $apa_fill;
        background: #fef0e2;
        border: 2px solid $apa_fill;
        cursor: pointer;
      }

      &:after {
        background: $apa_fill;
      }
    }

    &.is-active {
      font-size: 14px;
      font-family: OpenSansSemiBold;
      color: #00559A;

      &:before {
        color: #FFF;
        border: 1px solid $apa_fill;
        background: $apa_fill;
        width: 12px;
        height: 12px;
        margin-top: -3px;

      }
    }
  }
}


.steps {
  margin-top: 20px;
}

.steps li{
  cursor: pointer;
}


.add_lead_next_button {
  background-color: #FFC10E;
  border-radius: 30px;
  padding: 7px 50px;
  border: 1px solid;
  font-size: 16px;
  font-family: OpenSansSemiBold;
  color: #ffffff;
}

.add_lead_next_button:hover, .add_lead_next_button:active, .add_lead_next_button:focus {
  background-color: #FFC10E;
  color: #ffffff;
  outline: none;
}


.add_lead_prev_button {
  background-color: rgb(249, 249, 249);
  border-radius: 30px;
  border: 1px solid $apa_fill;
  padding: 7px 50px;
  color: $apa_fill;
  font-size: 16px;
  font-family: OpenSansSemiBold;
}

.add_lead_slider_buttons {
  margin-top: 40px;
}

.add_lead_prev_button:hover, .add_lead_prev_button:focus, .add_lead_prev_button:focus {

  color: $apa_fill;
}


.lead_add_more {
  font-size: 13px;
  font-family: OpenSansBold;
  color: #1B75BE;
  margin-bottom: 10px;
  border: none;
  background: none;
}


.lead_add_more span {
  color: #99B7CF;
  font-size: 18px;
  margin-left: 8px;
}

.add_lead_slider .slick-active :focus {
  outline: none;
}

.add_disabled {
  opacity: 0.6;

}

.discover_modal_content {
  background: rgba(0,0,0,0.7);
  border: none;
}


.discover_modal .modal-title {
  margin-left: 9px;
  font-size: 24px;
  font-family: openSansBold;
  color: #00345D;
  padding: 5px;
  margin-top: 5px;
}

.discover_modal .modal-body input {
  outline: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid #99B7CF;
  width: 100%;
  max-width: initial;
  color: #00559A;
  font-size: 13px

}


.discover_modal .modal-body {
  padding: 12px 41px;
}

.discover_modal .close {
  color: #00559A;
}


.discover_modal .modal-footer button {
  background-color: #FFC10E;
  border-radius: 30px;
  padding: 7px 50px;
  border: none;
  font-size: 16px;
  font-family: OpenSansSemiBold;
  color: #ffffff;
}

.discover_modal .modal-footer button:focus {
  background-color: #FFC10E !important;
}

.modal-header, .modal-footer {
  border: none;
}

.peril_modal .modal-body button {
  float: right;
  border: none;
  background: none;
  color: #1B75BE;
  font-size: 13px;
  font-family: openSansBold;
}


.add_lead_button {
  border: none;
  background: none;
  color: #1B75BE;
  font-size: 13px;
  font-family: openSansBold;
}


.add_lead_button span {
  margin-left: 10px;
  vertical-align: super;
}

.add_lead_button i {
  font-size: 24px
}

.peril_modal .modal-body button svg {
  margin-left: 10px;
}

.peril_modal .modal-body input {
  margin-bottom: 20px;
  border-bottom: 2px solid #99B7CF;
}


.peril_modal .modal-body button span {
  color: #99B7CF;
  font-size: 24px;
  margin-left: 10px;
  vertical-align: -4px;
}


.peril_modal .modal-footer .peril_edit {
  background: none;
  color: #1B75BE;
  font-size: 13px;
  font-family: openSansBold;
  padding-top: 10px;
  position: absolute;
  left: 0;
}

.peril_modal .modal-footer .peril_edit span {
  color: $apa_fill;
  font-size: 24px;
}

.peril_modal .modal-footer .peril_edit:focus {
  background: initial !important;
}

.peril_list_edit {
  top: 25px;
  position: absolute;
  right: 48px;
  color: #00559A;
  font-size: 19px;
}


.peril_list_edit i{
  cursor: pointer;
}


.peril_list .modal-body p {
  color: $apa_default;
  font-size: 13px;
  font-family: openSansRegular;
}


.panir p:last-child {
  border-bottom: 1px solid #99B7CF;
  //padding-bottom: 20px;
  padding: 12px 4px;
  margin: 0;
}

.manually_added_peril_content {
  position: relative;
}

.panir {
  position: relative;
}

.delete_icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  color: $apa_fill;
  font-size: 24px;
}

.custom_row .col-4:not(:first-of-type):not(:nth-of-type(3)) p, .custom_row .col-8:not(:first-of-type):not(:nth-of-type(3)) input {
  opacity: 0.4;
}


.peril_edit_save {
  position: absolute;
  right: 58px;
  margin-top: 15px;
  font-size: 14px;
  font-family: openSansSemiBold;
  color: $apa_fill;
  cursor: pointer;
}


p.loss_address:nth-child(n+1):nth-child(-n+5) {
  opacity: 0.2
}


.fullScreen_modal {
  padding-left: 0 !important;
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    background: #F9F9F9;
    border-radius: 0;
  }

  .modal-header i {
    color: $apa_fill;
    font-size: 30px;
    cursor: pointer;
  }

  .modal-body {
    display: flex;
  }



  button.close {
    float: right;
  }
}

.middle_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.steps_text_area{
  width: 100%;
  min-height: 200px;
  border: 1px solid #99B7CF;
  color: #00345D;
}


.steps_text_area:focus{
  outline: none;
  border-bottom: 1px solid #99B7CF;
}


#noter-text-area{
  color: $apa_default;
}



.call_button { color: #000; text-decoration: none; }
.call_button {

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: fixed;
  padding: 8px;
  right: 28px;
  bottom: 20px;
  border: 1px solid transparent ;
  border-radius: 50px;
  background: #FABD0E;
  transition: .2s;
  .call {
    color: #fff;
    transition: .2s;
  }

  .label-hidden {
    max-width: 0;
    opacity: 0;
    color: #fff;
    white-space: nowrap;
    transition: .2s;

  }

  &:hover {
    .label-hidden {
      max-width: 200px;
      margin-left: 8px;
      opacity: 1;
    }
  }
}


.remove_peril{
  position: relative;
}

.remove_peril i {
  top: -14px;
}

.slick-prev{
  color: #1B75BE;
}

.slick-next{
  color: #1B75BE;
}

.slick-prev:before {
  color: #1B75BE;
  font-size: 35px;
  position: absolute;
  left: -26px;
}

.slick-next:before {
  color: #1B75BE;
  font-size: 35px;
  position: absolute;
  left: 10px;
}

.territory_date .react-daterange-picker__calendar-button {
  top: -4px !important;
}
