@font-face {
  font-family: 'apa-icons';
  src: url('../fonts/icons/fontello.eot?72941107');
  src: url('../fonts/icons/fontello.eot?72941107#iefix') format('embedded-opentype'),
       url('../fonts/icons/fontello.woff2?72941107') format('woff2'),
       url('../fonts/icons/fontello.woff?72941107') format('woff'),
       url('../fonts/icons/fontello.ttf?72941107') format('truetype'),
       url('../fonts/icons/fontello.svg?72941107#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?72941107#fontello') format('svg');
  }
}
*/
 
 [class^="apa-"]:before, [class*=" apa-"]:before {
  font-family: "apa-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.apa-icon-export:before { content: '\e800'; } /* '' */
.apa-icon-home:before { content: '\e801'; } /* '' */
.apa-icon-leads:before { content: '\e802'; } /* '' */
.apa-icon-listen:before { content: '\e803'; } /* '' */
.apa-icon-logout:before { content: '\e804'; } /* '' */
.apa-icon-messaging:before { content: '\e805'; } /* '' */
.apa-icon-microphone:before { content: '\e806'; } /* '' */
.apa-icon-notes:before { content: '\e807'; } /* '' */
.apa-icon-notification:before { content: '\e808'; } /* '' */
.apa-icon-notification-active:before { content: '\e809'; } /* '' */
.apa-icon-pause:before { content: '\e80a'; } /* '' */
.apa-icon-phone:before { content: '\e80b'; } /* '' */
.apa-icon-play:before { content: '\e80c'; } /* '' */
.apa-icon-property:before { content: '\e80d'; } /* '' */
.apa-icon-ready:before { content: '\e80e'; } /* '' */
.apa-icon-reports:before { content: '\e80f'; } /* '' */
.apa-icon-search:before { content: '\e810'; } /* '' */
.apa-icon-settings:before { content: '\e811'; } /* '' */
.apa-icon-sign:before { content: '\e812'; } /* '' */
.apa-icon-teams:before { content: '\e813'; } /* '' */
.apa-icon-text-edit-attach:before { content: '\e814'; } /* '' */
.apa-icon-text-edit-bold:before { content: '\e815'; } /* '' */
.apa-icon-text-edit-bullet-points:before { content: '\e816'; } /* '' */
.apa-icon-text-edit-center-align:before { content: '\e817'; } /* '' */
.apa-icon-text-edit-hyperlink:before { content: '\e818'; } /* '' */
.apa-icon-text-edit-indent:before { content: '\e819'; } /* '' */
.apa-icon-text-edit-italic:before { content: '\e81a'; } /* '' */
.apa-icon-text-edit-justify:before { content: '\e81b'; } /* '' */
.apa-icon-text-edit-justify-1:before { content: '\e81c'; } /* '' */
.apa-icon-text-edit-left-align:before { content: '\e81d'; } /* '' */
.apa-icon-text-edit-list:before { content: '\e81e'; } /* '' */
.apa-icon-text-edit-outdent:before { content: '\e81f'; } /* '' */
.apa-icon-user:before { content: '\e820'; } /* '' */
.apa-icon-view:before { content: '\e821'; } /* '' */
.apa-pencil:before { content: '\e822'; } /* '' */
.apa-back:before { content: '\e823'; } /* '' */
.apa-send:before { content: '\e824'; } /* '' */
.apa-support:before { content: '\e825'; } /* '' */
.apa-add-user:before { content: '\e826'; } /* '' */
.apa-article:before { content: '\e827'; } /* '' */
.apa-gallery:before { content: '\e828'; } /* '' */
.apa-icon-settlement:before { content: '\e829'; } /* '' */
.apa-icon-account:before { content: '\e82a'; } /* '' */
.apa-icon-accounting:before { content: '\e82b'; } /* '' */
.apa-icon-add:before { content: '\e82c'; } /* '' */
.apa-icon-address:before { content: '\e82d'; } /* '' */
.apa-icon-archive:before { content: '\e82e'; } /* '' */
.apa-icon-arrow-down:before { content: '\e82f'; } /* '' */
.apa-icon-calendar:before { content: '\e830'; } /* '' */
.apa-icon-claims:before { content: '\e831'; } /* '' */
.apa-icon-clients:before { content: '\e832'; } /* '' */
.apa-icon-close:before { content: '\e833'; } /* '' */
.apa-icon-deactivate:before { content: '\e834'; } /* '' */
.apa-icon-delete:before { content: '\e835'; } /* '' */
.apa-icon-documents:before { content: '\e836'; } /* '' */
.apa-icon-email-templates:before { content: '\e837'; } /* '' */